<template>
    <div class="sidebar">
        <div class="system-info">
            <img :src="systemIcon" class="system-icon" v-show="!!systemIcon" />
            <span class="system-text">{{ systemName }}</span>
        </div>
        <div class="menu-container">
            <Menu
                theme="dark"
                ref="menus"
                :active-name="activeMenu"
                :open-names="openNames"
                @on-select="selectItem"
                width="100%"
            >
                <div v-for="(list, index) in menuList" :key="index">
                    <Submenu :name="list.name" v-if="!list.isHide && list.menu.length">
                        <template slot="title">
                            <span class="iconfont" :class="[list.icon]"></span>
                            {{ list.text }}
                        </template>
                        <MenuItem
                            :name="menu.name"
                            :key="menuIndex"
                            v-show="!menu.isHide"
                            v-for="(menu, menuIndex) in list.menu"
                        >{{ menu.text }}</MenuItem>
                    </Submenu>
                    <MenuItem v-if="!list.menu.length" :name="list.name" v-show="!list.isHide">
                        <span class="iconfont" :class="[list.icon]"></span>
                        {{ list.text }}
                    </MenuItem>
                </div>
            </Menu>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { pageConfig } from "../../config/route";
import { USER_OWN_MENU, SYSTEM_SETTING } from "../../config/api";
export default {
    data() {
        return {
            //菜单列表
            menuList: [],
            //菜单展开列表
            openNames: [],
            //高亮菜单
            activeMenu: "",
        };
    },
    created() {
        let path = this.$route.path;
        this.updateMenuConfig(path);
        if (!!this.currentScene) {
            this.requestMenu();
            this.requestSystem();
        }
    },
    methods: {
        //请求系统数据
        requestSystem() {
            this.$get(`${SYSTEM_SETTING}/${this.currentScene}`)
                .then((res) => {
                    let sysLogo = !res.sysLogo
                        ? res.sysLogo
                        : this.$fileSrc(res.sysLogo);
                    this.$store.commit("common/UPDATE_SYSTEM_ICON", sysLogo);
                    this.$store.commit(
                        "common/UPDATE_SYSTEM_NAME",
                        res.sysName
                    );
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, SYSTEM_SETTING);
                });
        },
        //请求菜单信息
        requestMenu(status) {
            this.$get(`${USER_OWN_MENU}/${this.currentScene}`)
                .then((res) => {
                    let menuList = [];
                    res.forEach((arr) => {
                        let menuChild = [];
                        if (arr.menu) {
                            arr.menu.forEach((item) => {
                                menuChild.push({
                                    text: item.text,
                                    name: item.name,
                                    isHide: arr.isHide,
                                });
                            });
                        }
                        let menuObj = {
                            text: arr.text,
                            name: arr.name,
                            icon: arr.icon,
                            isHide: arr.isHide,
                            menu: menuChild,
                        };
                        menuList.push(menuObj);
                    });
                    this.$store.commit("common/UPDATE_MENU_LIST", menuList);
                    this.menuList = menuList;
                    if (status === "update") {
                        this.activeMenu = "";
                        this.openNames = [];
                        this.$router.push("/page");
                    }
                    this.$nextTick(() => {
                        this.$refs.menus.updateOpened();
                        this.$refs.menus.updateActiveName();
                    });
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, USER_OWN_MENU);
                });
        },
        //更新菜单配置
        updateMenuConfig(path) {
            let pathArr = path.split("/").slice(1);
            if (pathArr.length > 2) {
                if (this.openNames.includes(pathArr[1])) {
                    for (let i = 0; i < this.openNames.length; i++) {
                        if (this.openNames[i] === pathArr[1]) {
                            this.openNames.slice(i, 1);
                        }
                    }
                } else {
                    this.openNames.push(pathArr[1]);
                }
                this.activeMenu = pathArr[2].includes("-")
                    ? pathArr[2].split("-")[0]
                    : pathArr[2];
            } else {
                this.activeMenu = "";
            }
        },
        //跳转页面路径
        selectItem(val) {
            let parentPath = pageConfig[val].parent;
            let rootPath = pageConfig[val].root;
            let routePath =
                parentPath === rootPath
                    ? `/${rootPath}/${val}`
                    : `/${rootPath}/${parentPath}/${val}`;
            this.$router.push({
                path: routePath,
                query: { timeStr: Date.now() },
            });
        },
    },
    computed: {
        ...mapState({
            currentScene: (state) => state.common.currentScene,
            systemIcon: (state) => state.common.systemIcon,
            systemName: (state) => state.common.systemName,
        }),
    },
    watch: {
        $route: function (val) {
            this.updateMenuConfig(val.path);
        },
        openNames: function (val) {
            //在$nextTick中手动更新当前下拉项选择项
            this.$nextTick(() => {
                this.$refs.menus.updateOpened();
                this.$refs.menus.updateActiveName();
            });
        },
        currentScene: function (val) {
            if (!!val) {
                this.requestMenu("update");
                this.requestSystem();
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "./index";
</style>
