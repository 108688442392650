<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">关联设备 ({{ name }})</span>
      <div class="title-right">
        <Button type="primary" @click="saveLocalDevice">保存</Button>
      </div>
    </div>
    <div class="locale-device-view">
      <div class="select-locale" >
        <div class="select-title">已关联设备</div>
        <div class="select-table">
          <div class="table-title">
            <div class="title-item">
              <span class="item-label">设备名称/编号</span>
              <Input placeholder="请输入" v-model="selectedDevice" />
            </div>
            <div class="title-item">
              <span class="item-label label-center">楼层</span>
              <Select v-model="floorId" placeholder="请选择楼层">
                <Option :value="-1">全部</Option>
                <Option v-for="item in mapFloor" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </div>
            <div class="title-item">
              <span class="item-label">设备类型</span>
              <Select v-model="type" placeholder="请选择设备类型">
                <Option v-for="item in typeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </div>
            <div class="title-btn">
              <Button type="primary" ghost @click="searchSelected">查询</Button>
            </div>
          </div>
          <Table stripe ref="selection" height="400" :columns="selectedTitle"
                 @on-selection-change="selectChange" :data="selectedSearch"></Table>
          <div class="table-total">总计{{ selectedNum }}条</div>
        </div>
      </div>
      <div class="local-icon">
            <span class="iconfont iconxiangzuo" @click="renderSelected('unselected')"
                  :class="{'icon-false': !unselectedChecked.length}"></span>
        <span class="iconfont iconxiangyou" @click="renderSelected('selected')"
              :class="{'icon-false': !selectedChecked.length}"></span>
      </div>
      <div class="select-locale">
        <div class="select-title">未关联设备</div>
        <div class="select-table">
          <div class="table-title">
            <div class="title-item">
              <span class="item-label">设备名称/编号</span>
              <Input placeholder="请输入"  v-model="unselectedDevice" />
            </div>
            <div class="title-item">
              <span class="item-label label-center">楼层</span>
              <Select v-model="unSelectedFloorId" placeholder="请选择楼层">
                <Option :value="-1">全部</Option>
                <Option v-for="item in mapFloor" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </div>
            <div class="title-item">
              <span class="item-label">设备类型</span>
              <Select v-model="unSelectedType" placeholder="请选择设备类型">
                <Option v-for="item in typeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </div>
            <div class="title-btn">
              <Button type="primary" ghost @click="searchUnselected">查询</Button>
            </div>
          </div>
          <Table stripe ref="selection" height="400" :columns="unselectedTitle"
                 @on-selection-change="unselectedChange" :data="unselectedSearch"></Table>
          <div class="table-total">总计{{ unselectedNum }}条</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import { LOCALE_RELATION_DEVICE, DEVICE_LIST_NOPAGE,
    LOCALE_RELATION_DEVICE_EDIT, DEVICE_TYPE_NOPAGE } from '../../../config/api'
  import {conversionFrom, copyObj} from '../../../utils/helper'
  export default {
    data() {
      return {
        //巡检点名称
        name: '',
        //巡检点id
        id: null,
        //楼层id
        floorId: -1,
        unSelectedFloorId: -1,
        //设备类型
        type: -1,
        unSelectedType: -1,
        typeList: [],
        //设备列表
        deviceList: [],
        //已选设备列表
        selectedIds: [],
        //关联设备搜索
        selectedDevice: '',
        //关联设备搜索列表
        selectedSearch: [],
        //已选设备数量
        selectedNum: 0,
        //已关联设备列表
        selectedTitle: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '序号',
            key: 'index',
            width: 70,
          },
          {
            title: '设备名称',
            key: 'name'
          },
          {
            title: '设备编号',
            key: 'number'
          },
          {
            title: '楼层',
            key: 'floorName',
          },
          {
            title: '设备类型',
            key: 'type'
          },
          {
            title: '位置',
            key: 'location'
          },
        ],
        selectedList: [],
        //未选巡检点列表checked数据ids
        selectedChecked: [],
        //未关联设备搜索
        unselectedDevice: '',
        //未选择设备id
        unselectedIds: [],
        //已选设备数量
        unselectedNum: 0,
        //未关联设备标题
        unselectedTitle: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '序号',
            key: 'index',
            width: 70,
          },
          {
            title: '设备名称',
            key: 'name'
          },
          {
            title: '设备编号',
            key: 'number'
          },
          {
            title: '楼层',
            key: 'floorName',
            width: 80,
          },
          {
            title: '设备类型',
            width: 80,
            key: 'type'
          },
          {
            title: '位置',
            key: 'location'
          },
        ],
        unselectedList: [],
        //未选巡检点列表checked数据ids
        unselectedChecked: [],
        //关联设备搜索列表
        unselectedSearch: [],
      }
    },
    created() {
      this.id = this.$route.query.id;
      this.name = this.$route.query.name;
      this.requestDeviceAll()
    },
    methods: {
      //请求设备类型
      requestType() {
        return new Promise((resolve, reject) => {
          this.$get(`${DEVICE_TYPE_NOPAGE}/${this.currentScene}`, null, true).then(res => {
            let typeList = [{label: '全部', value: -1}];
            res.forEach(arr => {
              typeList.push({label: arr.name, value: arr.id})
            });
            this.typeList = typeList;
            resolve(3)
          }).catch(err => {
            reject(3)
            //异常情况
            this.$httpErr(err, DEVICE_TYPE_NOPAGE)
          })
        })
      },
      //设备列表和已选设备都成功返回后
      requestDeviceAll() {
        this.$store.commit('common/UPDATE_LOADING', true)
        Promise.all([this.requestDevice(), this.requestRelateDetail(), this.requestType()]).then(res => {
          this.$store.commit('common/UPDATE_LOADING', false)
          this.updateRouteLocal()
        }).catch(err => {
          this.$store.commit('common/UPDATE_LOADING', false)
          console.log(err)
        })
      },
      //请求设备列表
      requestDevice() {
        return new Promise((resolve, reject) => {
          this.$get(`${DEVICE_LIST_NOPAGE}/${this.currentScene}`, null, true).then(res => {
            let deviceList = [];
            res.forEach((arr, index) => {
              arr.index = index;
              arr.type = arr.deviceType.name;
              deviceList.push(arr)
            });
            this.deviceList = deviceList;
            resolve(1)
          }).catch(err => {
            //异常情况
            this.$httpErr(err, LOCALE_RELATION_DEVICE)
            reject(1)
          })
        })
      },
      //请求关联详情
      requestRelateDetail() {
        return new Promise((resolve, reject) => {
          this.$get(`${LOCALE_RELATION_DEVICE}/${this.currentScene}/${this.id}`, null, true).then(res => {
            let selectedIds = [];
            res.forEach(arr => {
              selectedIds.push(arr.id)
            });
            this.selectedIds = selectedIds;
            resolve(2)
          }).catch(err => {
            reject(2)
            //异常情况
            this.$httpErr(err, LOCALE_RELATION_DEVICE)
          })
        })
      },
      //更新巡检路线巡检点
      updateRouteLocal() {
        let selectedList = [];
        let selectedSearch = [];
        let unselectedSearch = [];
        let unselectedList = [];
        this.deviceList.forEach(arr => {
          if (this.selectedIds.includes(arr.id)) {
            let newObj = copyObj(arr);
            if (this.selectedChecked.includes(arr.id)) {
              newObj._checked = true;
            }
            selectedList.push(copyObj(arr))
            selectedSearch.push(newObj)
          } else {
            let newObj = copyObj(arr);
            if (this.unselectedChecked.includes(arr.id)) {
              newObj._checked = true;
            }
            unselectedSearch.push(copyObj(arr))
            unselectedList.push(newObj)
          }
        });
        this.selectedList = this.decorateList(selectedList);
        this.selectedSearch = this.decorateList(selectedSearch);
        this.unselectedList = this.decorateList(unselectedList);
        this.unselectedSearch = this.decorateList(unselectedSearch);
        this.selectedNum = this.selectedSearch.length;
        this.unselectedNum = this.unselectedSearch.length;
      },
      //装饰数据
      decorateList(data) {
        data.map((arr, index) => {
          arr.index = index + 1;
          return arr
        });
        return data;
      },
      //搜索已选择设备
      searchSelected() {
        let selectedSearch = [];
        this.selectedChecked = [];
        this.selectedList.forEach(arr => {
          let searchText = arr.name.includes(this.selectedDevice) || arr.location.includes(this.selectedDevice);
          let floorText = this.floorId === -1 ? true : (arr.floorId === this.floorId);
          let typeText = this.type === -1 ? true : (arr.deviceTypeId === this.type)
          if (searchText && floorText && typeText) {
            selectedSearch.push(copyObj(arr))
          }
        });
        this.selectedSearch = this.decorateList(selectedSearch);
      },
      //搜索未选择设备
      searchUnselected() {
        let unselectedSearch = [];
        this.unselectedChecked = [];
        this.unselectedList.forEach(arr => {
          let searchText = arr.name.includes(this.unselectedDevice) || arr.location.includes(this.unselectedDevice);
          let floorText = this.unSelectedFloorId === -1 ? true : (arr.floorId === this.unSelectedFloorId);
          let typeText = this.unSelectedType === -1 ? true : (arr.deviceTypeId === this.unSelectedType)
          if (searchText && floorText && typeText) {
            unselectedSearch.push(copyObj(arr))
          }
        });
        this.unselectedSearch = this.decorateList(unselectedSearch);
      },
      //已选列表改变
      selectChange(selection) {
        let ids = [];
        selection.forEach(arr => {
          ids.push(arr.id)
        });
        this.selectedChecked = ids;
      },
      //为选列表改变
      unselectedChange(selection) {
        let ids = [];
        selection.forEach(arr => {
          ids.push(arr.id)
        });
        this.unselectedChecked = ids;
      },
      //渲染选择巡检点
      renderSelected(val) {
        if (val === 'selected' && this.selectedChecked.length !== 0) {
          //已选巡检点删除
          let newSelectedIds = [];
          this.selectedIds.forEach(arr => {
            if (!this.selectedChecked.includes(arr)) {
              newSelectedIds.push(arr)
            }
          });
          this.selectedIds = newSelectedIds;
          this.selectedChecked = [];
          this.updateRouteLocal()
        } else if (val === 'unselected' && this.unselectedChecked.length !== 0) {
          //已选巡检点添加
          this.selectedIds =  this.selectedIds.concat(this.unselectedChecked)
          this.unselectedChecked = [];
          this.updateRouteLocal()
        }
      },
      //保存关联设备
      saveLocalDevice() {
        let data = {
          deviceIds: this.selectedIds
        };
        this.$post(`${LOCALE_RELATION_DEVICE_EDIT}/${this.currentScene}/${this.id}`, conversionFrom(data)).then(res => {
          this.$Message.success({
            background: true,
            content: '修改关联设备成功'
          });
          history.go(-1)
        }).catch(err => {
          //异常情况
          this.$httpErr(err, LOCALE_RELATION_DEVICE_EDIT)
        })
      },
    },
    computed: {
      ...mapGetters([
        'currentScene',
        'mapFloor'
      ]),
    },
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
