<template>
    <div class="page-view page-deploy">
        <div class="page-header">
            <span class="header-text">部署巡检点</span>
        </div>
        <div class="deploy-view">
            <div id="fengMap"></div>
        </div>
        <local-edit-modal
            :localId="localId"
            :localData="localData"
            type="deploy"
            @localEvent="localEvent"
            ref="local"
        ></local-edit-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LocalEditModal from "../../../components/LocalEditModal";
import { LOCALE_LIST_NOPAGE, baseUrl } from "../../../config/api";
//地图对象
let _map = null;

export default {
    data() {
        return {
            //请求状态
            requestStatus: false,
            //巡检点对象
            localObj: {},
            //楼层对象
            floorObj: {},
            //巡检点Id
            localId: null,
            //楼层id
            groupId: null,
            //巡检点信息
            localData: {},
        };
    },
    components: {
        LocalEditModal,
    },
    mounted() {
        if (!!this.fmapID) {
            this.requestAllLocals();
        }
    },
    methods: {
        //请求所有巡检点
        requestAllLocals(status) {
            if (this._isBeingDestroyed) {
                return;
            }
            this.$get(`${LOCALE_LIST_NOPAGE}/${this.currentScene}`)
                .then((res) => {
                    this.mapFloor.forEach((arr) => {
                        this.floorObj[arr.value] = arr.number;
                        this.localObj[arr.number] = [];
                    });
                    res.forEach((arr) => {
                        let floorNumber = this.floorObj[arr.floorId];
                        this.localObj[floorNumber].push(arr);
                    });
                    if (!_map) {
                        this.renderMap();
                    }
                    if (status === "update") {
                        this.addMarks(_map, this.groupId);
                    }
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, LOCALE_LIST_NOPAGE);
                });
        },
        //渲染地图
        renderMap() {
            if (this._isBeingDestroyed) {
                return;
            }
            let _this = this;
            const mapOptions = {
                container: document.getElementById("fengMap"), //渲染dom
                //地图数据
                mapServerURL: `${baseUrl}${_this.mapServerUrl}`,
                //地图主题
                mapThemeURL: `${baseUrl}${_this.mapThemeUrl}`,
                //默认主题
                defaultThemeName: _this.mapThemeStyle,
                //开发者应用名称
                appName: _this.appName,
                //开发者申请应用下web服务的key
                key: _this.key,
                //显示瓦片数据
                // tile:true
            };
            _map = new fengmap.FMMap(mapOptions);
            _map.openMapById(this.fmapID, (error) => {
                _this.$Message.error({
                    background: true,
                    content: "地图生成异常",
                });
            });
            // 地图加载完成事件
            _map.on("loadComplete", function () {
                //显示楼层控件
                _this.loadDimensionCtrl(_map);
                //显示维度控件
                _this.loadScrollFloorCtrl(_map);
            });
            //点击事件监听
            _map.on("mapClickNode", function (event) {
                if (_this.$refs.local.modalStatus) {
                    return;
                }
                let clickedObj = event.mapCoord;
                let clickedTarget = event.target;
                if (!clickedObj) {
                    return;
                }
                if (clickedTarget.markername) {
                    //点击标注展示标注详情(可更新)
                    _this.showLocal(clickedTarget.markername);
                    return;
                }
                //地图内部点击效果可以部署设备
                _this.deployLocal(clickedObj, clickedTarget.groupID);
            });
            //页面销毁，移除监听器
            this.$once("hook:beforeDestroy", function () {
                _map.off("mapClickNode", () => {});
                _map.off("loadComplete", () => {});
                _map = null;
            });
        },
        // 加载滚动型楼层切换控件
        loadScrollFloorCtrl(map) {
            /**
             * 渲染楼层切换控件
             * map.groupIDs 获取当前模型的所有楼层ID集合
             * * */
            const scrollFloorCtlOpt = {
                position: fengmap.FMControlPosition.RIGHT_TOP,
                showBtnCount: 6,
                allLayer: false,
                needAllLayerBtn: true,
                offset: {
                    x: -20,
                    y: 120,
                },
                imgURL: "./map_images/",
            };
            const scrollFloorControl = new fengmap.FMScrollGroupsControl(
                map,
                scrollFloorCtlOpt
            );
            this.groupId = map._currentFocusGroup;
            this.addMarks(map, map._currentFocusGroup);
            // 楼层切换
            scrollFloorControl.onChange((groups, allLayer) => {
                this.groupId = groups[0];
                this.addMarks(map, groups[0]);
            });
        },
        //添加地图标注
        addMarks(map, groupId) {
            let _this = this;
            let groupLayer = map.getFMGroup(groupId);
            _this.localObj[groupId].forEach((arr) => {
                //添加图片标注
                let imgLayer = new fengmap.FMImageMarkerLayer();
                let img = new fengmap.FMImageMarker({
                    name: arr.id,
                    id: arr.id,
                    x: arr.x,
                    y: arr.y,
                    url: "./map_images/local_icon.png",
                    size: 40,
                    height: 1,
                    avoid: true,
                });
                imgLayer.addMarker(img);
                img.alwaysShow();
                groupLayer.addLayer(imgLayer);
            });
        },
        //加载2D/3D切换
        loadDimensionCtrl(map) {
            //2D、3D控件配置
            const toolControl = new fengmap.toolControl(map, {
                //控件显示位置
                position: fengmap.FMControlPosition.RIGHT_TOP,
                offset: {
                    x: -20,
                    y: 0,
                },
                //初始化2D模式
                init2D: true,
                //设置为false表示只显示2D,3D切换按钮
                groupsButtonNeeded: false,
                // 配置图片资源的路径地址
                imgURL: "./map_images/",
                //点击按钮的回调方法,返回type表示按钮类型,value表示对应的功能值
                clickCallBack: function (type, value) {
                    console.log(type, value);
                },
            });
        },
        //展示巡检点详情
        showLocal(id) {
            this.localId = id;
            let obj = {};
            let groupLocals = this.localObj[this.groupId];
            for (let i = 0; i < groupLocals.length; i++) {
                if (groupLocals[i].id === id) {
                    obj = JSON.stringify(groupLocals[i]);
                    break;
                }
            }
            this.localData = JSON.parse(obj);
            this.$refs.local.modalStatus = true;
        },
        //巡检点部署
        deployLocal(data, groupID) {
            this.localData = {
                x: data.x,
                y: data.y,
                groupID: groupID,
            };
            this.$refs.local.modalStatus = true;
        },
        //部署巡检点事件
        localEvent(content) {
            if (content.type === "new") {
                this.$Message.success({
                    background: true,
                    content: "新增巡检点成功",
                });
                // let newLocal = {
                //   id: Date.now(),
                //   ...content.data
                // };
                // this.localObj[this.groupId].push(newLocal)
                // this.addMarks(_map, this.groupId)
                this.requestAllLocals("update");
            } else if (content.type === "update") {
                this.$Message.success({
                    background: true,
                    content: "编辑巡检点成功",
                });
                this.localId = null;
                // history.go(-1)
                this.requestAllLocals();
            } else if (content.type === "close") {
                this.localId = null;
            }
        },
    },
    computed: {
        ...mapGetters([
            "systemIcon",
            "currentScene",
            "mapServerUrl",
            "mapThemeStyle",
            "mapThemeUrl",
            "fmapID",
            "appName",
            "key",
            "mapFloor",
        ]),
    },
    watch: {
        fmapID: function (val) {
            this.requestAllLocals();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
