<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">数据同步管理</span>
    </div>
    <div class="data-view">
      <div class="form-item">
        <label class="item-label"><span class="label-vital">*</span>数据获取url</label>
        <div class="input-box">
          <Input v-model="dataUrl" placeholder="请输入" style="width: 50%" @on-blur="blurInput('dataUrl')" />
        </div>
        <div class="form-err" v-show="errForm.has('dataUrl')">请输入数据url</div>
      </div>
      <div class="form-item">
        <label class="item-label"><span class="label-vital">*</span>同步数据范围</label>
        <div class="input-box">
          <RadioGroup v-model="dataRange" @on-change="changeRadio">
            <Radio :label="1">
              <span>指定单个场景</span>
            </Radio>
            <!--<Radio :label="-1">-->
              <!--<span>全部场景</span>-->
            <!--</Radio>-->
          </RadioGroup>
        </div>
      </div>
      <div class="form-item">
        <label class="item-label"><span class="label-vital">*</span>场景编号</label>
        <div class="input-box">
          <Input v-model="sceneNumber" :disabled="dataRange === -1" @on-blur="blurInput('sceneNumber')"
                 placeholder="请输入" style="width: 50%" />
        </div>
        <div class="form-err" v-show="errForm.has('sceneNumber')">请输入场景编号</div>
      </div>
      <div class="form-item">
        <label class="item-label"></label>
        <Button type="primary" @click="synchronizeData">开始同步</Button>
      </div>
    </div>
  </div>
</template>

<script>
  import {DATA_SYNC} from '../../../config/api'
  import {conversionFrom} from '../../../utils/helper'
  export default {
    data() {
      return {
        //数据获取url
        dataUrl: '',
        //数据范围
        dataRange: 1,
        //场景编号
        sceneNumber: '',
        //错误表单
        errForm: new Set()
      }
    },
    methods: {
      //输入框失去焦点
      blurInput(name) {
        if (this[name] === '') {
          this.errForm.add(name);
        } else {
          this.errForm.delete(name);
        }
        this.$forceUpdate()
      },
      //radio选择框改变
      changeRadio(val) {
        if (val === -1) {
          this.errForm.delete('sceneNumber');
        }
      },
      //验证表单数据
      validateData() {
        this.blurInput('dataUrl')
        if (this.dataRange === 1) {
          this.blurInput('sceneNumber')
        }
        return (this.errForm.size === 0)
      },
      //同步数据
      synchronizeData() {
        if (this.validateData()) {
          this.submitData()
        }
      },
      //提交数据
      submitData() {
        let buildingId = this.dataRange === -1 ? this.dataRange : this.sceneNumber;
        let data = {
          buildingId: buildingId,
          url: this.dataUrl
        };
        this.$post(DATA_SYNC, conversionFrom(data)).then(res => {
          this.$Message.success({
            background: true,
            content: '数据同步成功'
          });
        }).catch(err => {
          //异常情况
          this.$httpErr(err)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
