<template>
    <div class="manager-page">
        <div class="page-view">
            <div class="home-view">
                <div class="data-table">
                    <div
                        v-for="(item, index) in summaryList"
                        :key="index"
                        :style="'flex:' + item.flex"
                    >
                        <home-data-summary-vue
                            :summary="item"
                            @changeSummaryDate="changeSummaryDate"
                        ></home-data-summary-vue>
                    </div>
                </div>
            </div>
            <div class="home-view">
                <div class="container-title">
                    <div class="title-left">巡检情况统计</div>
                    <div class="title-right">
                        <DatePicker
                            :value="inspectDate"
                            type="datetimerange"
                            placement="bottom-end"
                            :editable="false"
                            format="yyyy-MM-dd HH:mm"
                            :clearable="false"
                            @on-change="updateInspectDate"
                            split-panels
                            placeholder="请选择日期范围"
                            style="width: 280px"
                        ></DatePicker>
                        <Button type="primary" @click="requestStatisticData">查询</Button>
                    </div>
                </div>
                <div class="chart-box">
                    <div class="chart-cake">
                        <div id="cake-echart"></div>
                    </div>
                    <div class="chart-column">
                        <div id="column-echart"></div>
                    </div>
                </div>
            </div>
            <div class="home-view">
                <div class="container-title">
                    <div class="title-left">报修处理情况统计：</div>
                    <div class="title-right">
                        <DatePicker
                            :value="repairData"
                            type="datetimerange"
                            placement="bottom-end"
                            :editable="false"
                            format="yyyy-MM-dd HH:mm"
                            :clearable="false"
                            @on-change="updateRepairDate"
                            split-panels
                            placeholder="请选择日期范围"
                            style="width: 280px"
                        ></DatePicker>
                        <Button type="primary" @click="requestRepairData">查询</Button>
                    </div>
                </div>
                <div class="chart-box">
                    <div class="chart-cake">
                        <div id="cake-echart2"></div>
                    </div>
                    <div class="chart-column">
                        <div id="column-echart2"></div>
                    </div>
                </div>
            </div>
            <div class="home-view">
                <div class="container-title">
                    <div class="title-left">巡检任务</div>
                    <div class="title-right">
                        <div class="right-item">
                            <span class="item-label">状态</span>
                            <Select v-model="taskStatus" clearable style="width: 120px">
                                <Option
                                    v-for="item in statusList"
                                    :value="item.value"
                                    :key="item.value"
                                >{{ item.label }}</Option>
                            </Select>
                        </div>
                        <DatePicker
                            :value="taskDate"
                            format="yyyy-MM-dd HH:mm"
                            :editable="false"
                            type="datetimerange"
                            :clearable="false"
                            @on-change="updateTaskDate"
                            placement="bottom-end"
                            split-panels
                            placeholder="请选择日期范围"
                            style="width: 280px"
                        ></DatePicker>
                        <Button type="primary" @click="searchTaskStatistic">查询</Button>
                    </div>
                </div>
                <Table stripe :columns="tableTitle" :data="tableList"></Table>
                <div class="paging-box">
                    <Page
                        :current="pageNo"
                        :page-size="pageSize"
                        :total="total"
                        @on-change="changePage"
                        @on-page-size-change="changeSize"
                        show-total
                        show-elevator
                        show-sizer
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    HISTORY_INSPECT_STATISTICS,
    ABNORMAL_DEVICE_STATISTICS,
    INSPECT_TASK_STATISTICS,
    STATISTICS_STATUS,
    STATISTICS_DEVICE,
} from "../../config/api";
import {
  formDate3,
  formDate4,
  formDate5,
  childTaskStatus,
  currentTimeArr,
  currentWeekTimeArr,
  timeSlotChange, currentIOSTimeArr,
} from "../../utils/helper";
import homeDataSummaryVue from "@/components/base/homeDataSummary.vue";
export default {
    data() {
        return {
            //巡检日期范围
            inspectDate: [],
            // 报修日期范围
            repairData: [],
            //任务日期范围
            taskDate: [],
            //任务状态
            taskStatus: "",
            statusList: [],
            //表单数据
            tableTitle: [
                {
                    title: "序号",
                    key: "index",
                    width: 70,
                },
                {
                    title: "任务名称",
                    key: "name",
                },
                {
                    title: "巡检时间",
                    key: "time",
                },
                {
                    title: "执行人",
                    key: "person",
                },
                {
                    title: "巡检周期",
                    key: "cycle",
                },
                {
                    title: "巡检状态",
                    key: "status",
                    width: 100,
                },
            ],
            tableList: [],
            //分页数据
            pageNo: 1,
            pageSize: 10,
            total: 0,
            summaryList: [
                {
                    index: 0,
                    flex: 3,
                    title: "今日巡检任务",
                    switch: "day",
                    data: [
                        {
                            count: 0,
                            title: "待巡检",
                        },
                        {
                            count: 0,
                            title: "巡检中",
                        },
                        {
                            count: 0,
                            title: "已完成",
                        },
                        {
                            count: 0,
                            title: "总数",
                        },
                    ],
                },
                {
                    index: 1,
                    flex: 3,
                    title: "今日报修工单",
                    switch: "day",
                    data: [
                        {
                            count: 0,
                            title: "待派单",
                        },
                        {
                            count: 0,
                            title: "进行中",
                        },
                        {
                            count: 0,
                            title: "已完成",
                        },
                        {
                            count: 0,
                            title: "总数",
                        },
                    ],
                },
                {
                    index: 2,
                    flex: 2,
                    title: "累计巡检任务",
                    data: [
                        {
                            count: 0,
                            title: "已巡检/总数",
                        },
                    ],
                },
                {
                    index: 3,
                    flex: 2,
                    title: "累计报修工单",
                    data: [
                        {
                            count: 0,
                            title: "已完成/总数",
                        },
                    ],
                },
            ],
        };
    },
    components: { homeDataSummaryVue },
    created() {
        for (let val in childTaskStatus) {
            this.statusList.push({ label: childTaskStatus[val], value: val });
        }
        //初始化日期数据
        this.inspectDate = currentWeekTimeArr();
        this.repairData = currentWeekTimeArr();
        this.taskDate = currentTimeArr();
    },
    mounted() {
        if (this.currentScene) {
            this.requestStatisticData();
            this.requestTaskStatistic();
            this.requestIndexBanner();
            this.requestInspect();
            this.requestRepairData();
        }
    },
    methods: {
        //更新巡检统计时间
        updateInspectDate(val) {
            this.inspectDate = val[0] === "" ? currentTimeArr() : val;
        },
        // 更新报修统计时间
        updateRepairDate(val) {
            this.repairData = val[0] === "" ? currentTimeArr() : val;
        },
        //更新任务时间
        updateTaskDate(val) {
            this.taskDate = val[0] === "" ? currentTimeArr() : val;
        },
        // 切换顶部表格数据统计时间
        changeSummaryDate(index, val) {
            switch (index) {
                case 0:
                    this.requestInspect(val);
                    break;
                case 1:
                    this.requestIndexBanner(val);
                    break;
                default:
                    break;
            }
        },
        // 查询头部表格巡检数据
        requestInspect(val = "day") {
            let timeArr =
                val === "day" ? currentIOSTimeArr() : timeSlotChange("本周");
            let params = {
                "start-time": timeArr[0],
                "end-time": timeArr[1],
            };
            this.$get(
                `${HISTORY_INSPECT_STATISTICS}/${this.currentScene}`,
                params
            ).then((res) => {
                this.summaryList[0].data[0].count = res.todo || 0;
                this.summaryList[0].data[1].count = res.doing || 0;
                this.summaryList[0].data[2].count = res.done || 0;
                this.summaryList[0].data[3].count =
                    res.todo + res.doing + res.done;
                this.summaryList[2].data[0].count = `${res.doneTotal || 0}/${
                    res.allTotal || 0
                }`;
            });
        },
        // 查询头部表格报修数据
        requestIndexBanner(val = "day") {
            let timeArr =
                val === "day" ? currentTimeArr() : timeSlotChange("本周");
            let params = {
                startDate: formDate5(timeArr[0]),
                endDate: formDate5(timeArr[1]),
            };
            this.$get(`${STATISTICS_STATUS}/${this.currentScene}`, params).then(
                (res) => {
                    this.summaryList[1].data[0].count = res.initTotal;
                    this.summaryList[1].data[1].count =
                        res.receiveTotal + res.progressTotal;
                    this.summaryList[1].data[2].count = res.doneTotal;
                    this.summaryList[1].data[3].count =
                        res.initTotal +
                        res.progressTotal +
                        res.receiveTotal +
                        res.doneTotal;
                    this.summaryList[3].data[0].count = `${
                        res.doneSumTotal || 0
                    }/${res.repairSumTotal || 0}`;
                }
            );
        },
        //请求统计图数据
        requestStatisticData() {
            let data = {};
            if (this.inspectDate.length !== 0) {
                data["start-time"] =
                    this.inspectDate[0] === ""
                        ? null
                        : formDate3(this.inspectDate[0]);
                data["end-time"] =
                    this.inspectDate[1] === ""
                        ? null
                        : formDate3(this.inspectDate[1]);
            }
            this.searchInspectStatistic(data);
            this.searchAbnormalStatistic(data);
        },
        // 请求报修统计图数据
        requestRepairData() {
            let params = {
                startDate: this.repairData.length
                    ? this.repairData[0].slice(0, 10).replace(/\-/g, "")
                    : "",
                endDate: this.repairData.length
                    ? this.repairData[1].slice(0, 10).replace(/\-/g, "")
                    : "",
            };
            this.requestRepairCakeData(params);
            this.requestRepairColoumData(params);
        },
        // 请求报修饼图数据
        requestRepairCakeData(params) {
            this.$get(`${STATISTICS_STATUS}/${this.currentScene}`, params).then(
                (res) => {
                    let cakeList = [];
                    let total =
                        res.initTotal +
                        res.progressTotal +
                        res.receiveTotal +
                        res.confirmTotal +
                        res.doneTotal;
                    let initRate = ((res.initTotal / total) * 100).toFixed(2);
                    cakeList.push({
                        value: initRate,
                        name: `待派单${initRate}%`,
                    });
                    let progressRate = (
                        ((res.progressTotal+res.receiveTotal) / total) *
                        100
                    ).toFixed(2);
                    cakeList.push({
                        value: progressRate,
                        name: `进行中${progressRate}%`,
                    });
                    let confirmTotal = (
                        (res.confirmTotal / total) *
                        100
                    ).toFixed(2);
                    cakeList.push({
                        value: confirmTotal,
                        name: `待确认${confirmTotal}%`,
                    });
                    let doneRate = ((res.doneTotal / total) * 100).toFixed(2);
                    cakeList.push({
                        value: doneRate,
                        name: `已完成${doneRate}%`,
                    });
                    this.renderRepairCake(cakeList);
                }
            );
        },
        // 请求报修柱状图数据
        requestRepairColoumData(params) {
            this.$get(`${STATISTICS_DEVICE}/${this.currentScene}`, params).then(
                (res) => {
                    let XList = [],
                        sourceList = [];
                    // XList = ['灭火设备', '照明设备', '通风设备', '消防设备', '电气设备'];
                    // sourceList = [33, 18, 23, 41, 28];
                    res.forEach((arr) => {
                        XList.push(arr.deviceTypeName);
                        sourceList.push(arr.deviceRepairTotal);
                    });
                    this.renderRepairColoum(XList, sourceList);
                }
            );
        },
        // 渲染报修柱状图
        renderRepairColoum(XList, sourceList) {
            if (this._isBeingDestroyed) {
                return;
            }
            const entryChart = this.$echarts.init(
                document.getElementById("column-echart2")
            );
            const entryOption = {
                color: ["#2d8cf0"],
                grid: {
                    x: 40,
                    y: 30,
                    x2: 20,
                    y2: 10,
                    containLabel: true,
                },
                tooltip: {
                    trigger: "axis",
                },
                xAxis: {
                    type: "category",
                    data: XList,
                    axisLabel: {
                        color: "#999999",
                        interval: 0,
                        rotate: 60,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#ddd",
                        },
                    },
                },
                yAxis: {
                    minInterval: 1,
                    type: "value",
                    name: "报修数(个)",
                    max(value) {
                        return Math.ceil(value.max * 1.2);
                    },
                    nameTextStyle: {
                        color: "#999999",
                        padding: [0, 0, 0, -20],
                    },
                    axisLabel: {
                        color: "#999999",
                        fontSize: "12",
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#ddd",
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            // 使用深浅的间隔色
                            color: ["#f0f0f0"],
                        },
                    },
                },
                series: [
                    {
                        barCategoryGap: "20%",
                        type: "bar",
                        data: sourceList,
                        barWidth: 30,
                        itemStyle: {
                            barBorderRadius: [4, 4, 0, 0],
                        },
                    },
                ],
            };
            entryChart.setOption(entryOption);
            window.addEventListener("resize", function () {
                entryChart.resize();
            });
        },
        // 渲染报修饼图
        renderRepairCake(list) {
            if (this._isBeingDestroyed) {
                return;
            }
            const cakeChart = this.$echarts.init(
                document.getElementById("cake-echart2")
            );
            const sourceOption = {
                series: [
                    {
                        type: "pie",
                        selectedMode: "single",
                        radius: [0, "60%"],
                        label: {
                            position: "outside",
                            textStyle: {
                                //文本样式
                                fontSize: "14",
                                fontWeight: "600",
                                color: "#999999",
                            },
                        },
                        data: list,
                    },
                ],
                // 图例
                legend: {
                    orient: "horizontal",
                    x: "center",
                    y: "bottom",
                    padding: 0,
                    itemGap: 20,
                    itemWidth: 20, // 图例图形宽度
                    itemHeight: 14, // 图例图形高度
                    textStyle: {
                        color: "#333", // 图例文字颜色
                    },
                },
            };
            cakeChart.setOption(sourceOption);
            window.addEventListener("resize", function () {
                cakeChart.resize();
            });
        },
        //查询巡检任务统计
        searchInspectStatistic(data) {
            this.$get(
                `${HISTORY_INSPECT_STATISTICS}/${this.currentScene}`,
                data
            )
                .then((res) => {
                    let total = res.todo + res.doing + res.done + res.expire;
                    let todoRate, doingRate, doneRate, expireRate;
                    let todoText, doingText, doneText, expireText;
                    if (total !== 0) {
                        todoRate = ((res.todo / total) * 100).toFixed(2);
                        doingRate = ((res.doing / total) * 100).toFixed(2);
                        doneRate = ((res.done / total) * 100).toFixed(2);
                        expireRate = (
                            100 -
                            todoRate -
                            doingRate -
                            doneRate
                        ).toFixed(2);
                        todoText = `待巡检: ${todoRate}%`;
                        doingText = `巡检中: ${doingRate}%`;
                        doneText = `已完成: ${doneRate}%`;
                        expireText = `已过期: ${expireRate}%`;
                    } else {
                        todoRate = doingRate = doneRate = expireRate = 0;
                        todoText = `待巡检: 0`;
                        doingText = `巡检中: 0`;
                        doneText = `已完成: 0`;
                        expireText = `已过期: 0`;
                    }

                    let cakeList = [
                        { value: res.todo, name: todoText },
                        { value: res.doing, name: doingText },
                        { value: res.done, name: doneText },
                        { value: res.expire, name: expireText },
                    ];
                    this.renderInspectColumn(cakeList);
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, HISTORY_INSPECT_STATISTICS);
                });
        },
        //查询异常数量历史统计
        searchAbnormalStatistic(data) {
            this.$get(
                `${ABNORMAL_DEVICE_STATISTICS}/${this.currentScene}`,
                data
            )
                .then((res) => {
                    let XList = [],
                        sourceList = [];
                    // XList = ['灭火设备', '照明设备', '通风设备', '消防设备', '电气设备'];
                    // sourceList = [33, 18, 23, 41, 28];
                    res.forEach((arr) => {
                        XList.push(arr.dtName);
                        sourceList.push(arr.expCount);
                    });
                    this.renderInspectCake(XList, sourceList);
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, ABNORMAL_DEVICE_STATISTICS);
                });
        },
        // 渲染巡检柱状图
        renderInspectCake(XList, sourceList) {
            console.log("renderInspectCake=========", XList, sourceList);
            if (this._isBeingDestroyed) {
                return;
            }
            const entryChart = this.$echarts.init(
                document.getElementById("column-echart")
            );
            const entryOption = {
                color: ["#2d8cf0"],
                grid: {
                    x: 40,
                    y: 30,
                    x2: 20,
                    y2: 10,
                    containLabel: true,
                },
                tooltip: {
                    trigger: "axis",
                },
                xAxis: {
                    type: "category",
                    data: XList,
                    axisLabel: {
                        color: "#999999",
                        interval: 0,
                        rotate: 60,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#ddd",
                        },
                    },
                },
                yAxis: {
                    minInterval: 1,
                    type: "value",
                    name: "异常数(个)",
                    max(value) {
                        return Math.ceil(value.max * 1.2);
                    },
                    nameTextStyle: {
                        color: "#999999",
                        padding: [0, 0, 0, -20],
                    },
                    axisLabel: {
                        color: "#999999",
                        fontSize: "12",
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#ddd",
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            // 使用深浅的间隔色
                            color: ["#f0f0f0"],
                        },
                    },
                },
                series: [
                    {
                        barCategoryGap: "20%",
                        type: "bar",
                        data: sourceList,
                        barWidth: 30,
                        itemStyle: {
                            barBorderRadius: [4, 4, 0, 0],
                        },
                    },
                ],
            };
            entryChart.setOption(entryOption);
            window.addEventListener("resize", function () {
                entryChart.resize();
            });
        },
        //渲染巡检饼图
        renderInspectColumn(list) {
            console.log("list------", list);
            if (this._isBeingDestroyed) {
                return;
            }
            const cakeChart = this.$echarts.init(
                document.getElementById("cake-echart")
            );
            const sourceOption = {
                series: [
                    {
                        type: "pie",
                        selectedMode: "single",
                        radius: [0, "60%"],
                        label: {
                            position: "outside",
                            textStyle: {
                                //文本样式
                                fontSize: "14",
                                fontWeight: "600",
                                color: "#999999",
                            },
                        },
                        data: list,
                    },
                ],
                // 图例
                legend: {
                    orient: "horizontal",
                    x: "center",
                    y: "bottom",
                    padding: 0,
                    itemGap: 20,
                    itemWidth: 20, // 图例图形宽度
                    itemHeight: 14, // 图例图形高度
                    textStyle: {
                        color: "#333", // 图例文字颜色
                    },
                },
            };
            cakeChart.setOption(sourceOption);
            window.addEventListener("resize", function () {
                cakeChart.resize();
            });
        },
        //请求任务统计数据
        searchTaskStatistic() {
            this.pageNo = 1;
            this.requestTaskStatistic();
        },
        //请求任务统计
        requestTaskStatistic() {
            let data = {
                current: this.pageNo,
                size: this.pageSize,
                status: this.taskStatus,
            };
            if (this.taskDate.length !== 0) {
                data["start-time"] =
                    this.taskDate[0] === ""
                        ? null
                        : formDate3(this.taskDate[0]);
                data["end-time"] =
                    this.taskDate[1] === ""
                        ? null
                        : formDate3(this.taskDate[1]);
            }
            this.$get(`${INSPECT_TASK_STATISTICS}/${this.currentScene}`, data)
                .then((res) => {
                    this.total = res.total;
                    let tableList = [];
                    res.records.forEach((arr, index) => {
                        arr.index = index + 1;
                        arr.time = `${formDate4(
                            arr.iptStartTime
                        )} - ${formDate4(arr.iptEndTime)}`;
                        arr.person = "";
                        arr.doerUsers.forEach((item) => {
                            arr.person +=
                                arr.person === ""
                                    ? item.nickname
                                    : `,${item.nickname}`;
                        });
                        arr.status = childTaskStatus[arr.status];
                        tableList.push(arr);
                    });
                    this.tableList = tableList;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, INSPECT_TASK_STATISTICS);
                });
        },
        //切换页码
        changePage(val) {
            this.pageNo = val;
            this.requestTaskStatistic();
        },
        //切换每页条数
        changeSize(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.requestTaskStatistic();
        },
    },
    computed: {
        ...mapGetters(["currentScene"]),
    },
    watch: {
        currentScene: function (val) {
            this.requestStatisticData();
            this.requestTaskStatistic();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "index";
</style>
