import {removeToken, removeScene} from "./auth";
import { baseUrl } from '../config/api'

export default function httpInit (
  Vue
) {
  //登出处理
  Vue.prototype.$logout = function() {
    removeToken();
    removeScene();
    this.$store.commit("common/UPDATE_LOGIN", false);
    window.location.reload()
  };
  //文件地址
  Vue.prototype.$fileSrc = function(path) {
    return `${baseUrl}${path}`
    // if (process.env.NODE_ENV === 'development') {
    //   return `http://52.130.89.100:31808${path}`;
    // } else {
    //   return `/api${path}`;
    // }
  };
}