<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">巡检任务</span>
            <div class="btn-group">
                <Button type="primary" @click="addTask">新增巡检任务</Button>
            </div>
        </div>
        <div class="inspect-task-view">
            <div class="view-title">
                <div class="title-left">
                    <div class="left-item">
                        <span class="left-title">创建时间</span>
                        <DatePicker
                            type="datetimerange"
                            v-model="createTime"
                            split-panels
                            placeholder="请选择日期范围"
                            style="width: 320px"
                        ></DatePicker>
                    </div>
                    <div class="left-item">
                        <span class="left-title">任务名称/巡检人</span>
                        <Input v-model="taskName" placeholder="请输入" style="width: 300px" />
                    </div>
                    <Button type="primary" @click="searchTask">查询</Button>
                </div>
            </div>
            <Table :columns="tableTitle" :data="tableList">
                <template slot-scope="{ row, index }" slot="status">
                    <i-switch v-model="row.isEnable" disabled></i-switch>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <span class="iconfont iconxiugai" @click="editTask(row)"></span>
                    <span class="iconfont iconxiangqing" @click="showTask(row)"></span>
                    <span class="iconfont iconsahnchufuben" @click="delTask(row)"></span>
                </template>
            </Table>
            <div class="paging-box">
                <Page
                    :current="pageNo"
                    :page-size="pageSize"
                    :total="total"
                    @on-change="changePage"
                    @on-page-size-change="changeSize"
                    show-total
                    show-elevator
                    show-sizer
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TASK_LIST_PAGE, TASK_ITEM_EDIT } from "../../../config/api";
import { formatDate, dateConversion, weekendDay } from "../../../utils/helper";
export default {
    data() {
        return {
            //创建时间
            createTime: [],
            //任务名称
            taskName: "",
            //表格头
            tableTitle: [
                {
                    title: "序号",
                    key: "index",
                    width: 70,
                },
                {
                    title: "任务名称",
                    key: "name",
                },
                {
                    title: "执行人",
                    key: "person",
                },
                {
                    title: "巡检周期",
                    key: "period",
                },
                {
                    title: "创建时间",
                    key: "createTime",
                },
                {
                    title: "状态",
                    slot: "status",
                    align: "center",
                    width: 100,
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 120,
                    align: "center",
                },
            ],
            tableList: [],
            //分页数据
            pageNo: 1,
            pageSize: 10,
            total: 0,
        };
    },
    created() {
        this.requestTask();
    },
    methods: {
        //请求任务列表
        requestTask() {
            let data = {
                current: this.pageNo,
                size: this.pageSize,
                search: this.taskName,
                "start-time": null,
                "end-time": null,
            };
            if (this.createTime.length !== 0) {
                data["start-time"] =
                    this.createTime[0] === ""
                        ? null
                        : formatDate(this.createTime[0]);
                data["end-time"] =
                    this.createTime[1] === ""
                        ? null
                        : formatDate(this.createTime[1]);
            }
            this.$get(`${TASK_LIST_PAGE}/${this.currentScene}`, data)
                .then((res) => {
                    let tableList = [];
                    this.total = res.total;
                    res.records.forEach((arr, index) => {
                        arr.index = index + 1;
                        arr.createTime = dateConversion(arr.createTime);
                        arr.person = "";
                        arr.doerUsers.forEach((item) => {
                            arr.person +=
                                arr.person === ""
                                    ? item.nickname
                                    : `, ${item.nickname}`;
                        });
                        switch (arr.cycle) {
                            case "每日":
                                arr.period = arr.cycle;
                                break;
                            case "每周":
                                let weekStr = "";
                                arr.weekDays.forEach((day) => {
                                    weekStr +=
                                        weekStr === ""
                                            ? weekendDay[day]
                                            : `,${weekendDay[day]}`;
                                });
                                arr.period = `${arr.cycle}(${weekStr})`;
                                break;
                            case "临时":
                                arr.period = `${
                                    arr.cycle
                                }(${arr.tempDates.toString()})`;
                                break;
                            case "自定义":
                                arr.period = arr.cycle;
                                break;

                            default:
                                break;
                        }
                        tableList.push(arr);
                    });
                    this.tableList = tableList;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, TASK_LIST_PAGE);
                });
        },
        //添加任务
        addTask() {
            this.$router.push("task-edit/0");
        },
        //编辑任务
        editTask(data) {
            this.$router.push({
                path: `task-edit/${data.id}`,
                query: { mes: JSON.stringify(data) },
            });
        },
        //查询
        searchTask() {
            this.pageNo = 1;
            this.requestTask();
        },
        //批量删除
        delTask(data) {
            this.$Modal.confirm({
                title: "提示",
                content: "<p>是否删除，删除后数据不可恢复</p>",
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                    //TODO
                    this.delRequest(data.id);
                },
            });
        },
        //删除任务请求
        delRequest(id) {
            this.$delete(`${TASK_ITEM_EDIT}/${this.currentScene}/${id}`)
                .then((res) => {
                    this.$Message.success({
                        background: true,
                        content: "删除任务成功",
                    });
                    this.requestTask();
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, TASK_ITEM_EDIT);
                });
        },
        //展示列表
        showTask(data) {
            this.$router.push({
                path: `task-detail`,
                query: { id: data.id, mes: JSON.stringify(data) },
            });
        },
        //切换页码
        changePage(val) {
            this.pageNo = val;
            this.requestTask();
        },
        //切换每页条数
        changeSize(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.requestTask();
        },
    },
    computed: {
        ...mapGetters(["currentScene"]),
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
