<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">巡检报告</span>
    </div>
    <div class="inspect-report-view">
      <div class="view-title">
        <div class="title-left">
          <div class="left-item">
            <span class="left-title">巡检时间</span>
            <DatePicker :value="inspectTime" format="yyyy-MM-dd HH:mm" :editable="false"
                        type="datetimerange" :clearable="false" @on-change="updateReportDate"
                        placement="bottom-end" split-panels placeholder="请选择日期范围" style="width: 280px"></DatePicker>
          </div>
          <div class="left-item">
            <span class="left-title">报告名称</span>
            <Input v-model="reportName" placeholder="请输入" style="width: 300px"/>
          </div>
          <Button type="primary" ghost @click="searchReport">查询</Button>
        </div>
        <Button type="primary" :disabled="!exportIds.length" @click="exportReport">导出报告</Button>
      </div>
      <Table stripe ref="selection" :columns="tableTitle" :data="tableList" @on-selection-change="selectChange">
        <template slot-scope="{ row, index }" slot="title">
          <span class="icon-text" @click="showReport(row)">{{ row.name }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="err">
          <span :class="{'icon-text': (row.expCount !== 0)}"
                @click="showErr(row)">{{ `异常项${(row.expCount === 0) ? '' : `(${row.expCount})`}` }}</span>
        </template>
      </Table>
      <div class="paging-box">
        <Page :current="pageNo"
              :page-size="pageSize"
              :total="total"
              @on-change="changePage"
              @on-page-size-change="changeSize"
              show-total show-elevator show-sizer/>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {REPORT_LIST_PAGE, REPORT_ITEM_EXPORT} from '../../../config/api'
  import {formDate3, formDate4, currentTimeArr, dateConversion, downloadFile} from '../../../utils/helper'

  export default {
    data() {
      return {
        //巡检时间
        inspectTime: [],
        //报告名称
        reportName: '',
        //表格标题列表
        tableTitle: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '序号',
            key: 'index',
            width: 80,
          },
          {
            title: '报告名称',
            slot: 'title',
            key: 'name'
          },
          {
            title: '巡检时间',
            key: 'time'
          },
          {
            title: '执行人',
            key: 'person'
          },
          {
            title: '异常项',
            slot: 'err'
          },
        ],
        tableList: [],
        //导出报告id
        exportIds: [],
        //分页数据
        pageNo: 1,
        pageSize: 10,
        total: 0,
      }
    },
    created() {
      //初始化日期数据
      this.inspectTime = currentTimeArr();
      this.requestReport()
    },
    methods: {
      //更新报告时间
      updateReportDate(val) {
        this.inspectTime = val[0] === '' ? currentTimeArr() : val;
      },
      //请求报告列表
      requestReport() {
        let data = {
          current: this.pageNo,
          size: this.pageSize,
          search: this.reportName
        };
        if (this.inspectTime.length !== 0) {
          data['start-time'] = this.inspectTime[0] === '' ? null : formDate3(this.inspectTime[0]);
          data['end-time'] = this.inspectTime[1] === '' ? null : formDate3(this.inspectTime[1]);
        }
        this.$get(`${REPORT_LIST_PAGE}/${this.currentScene}`, data).then(res => {
          this.total = res.total;
          let tableList = [];
          res.records.forEach((arr, index) => {
            arr.index = index + 1;
            arr.realStartTime = dateConversion(arr.realStartTime);
            arr.time = `${formDate4(arr.iptStartTime)} - ${formDate4(arr.iptEndTime)}`;
            let person = '';
            arr.realDoerUsers.forEach(user => {
              person += (person === '' ? user.nickname : `,${user.nickname}`);
            });
            arr.person = person;
            arr.expCount = arr.statsTotal.expCount;
            tableList.push(arr)
          });
          this.tableList = tableList;
        }).catch(err => {
          //异常情况
          this.$httpErr(err, REPORT_LIST_PAGE)
        })
      },
      //查询报告
      searchReport() {
        this.pageNo = 1;
        this.requestReport()
      },
      //展示报告详情
      showReport(data) {
        this.$router.push({path: `report-detail`, query: {id: data.id, name: data.name}})
      },
      //跳转异常项
      showErr(data) {
        if (data.expCount !== 0) {
          this.$router.push({path: `report-abnormal`, query: {id: data.id, name: data.name}})
        }
      },
      //列表选择改变
      selectChange(list) {
        let exportIds = [];
        list.forEach(item => {
          exportIds.push(item.id)
        });
        this.exportIds = exportIds;
      },
      //导出报告
      exportReport() {
        let data = {
          reportIds: (this.exportIds).toString()
        };
        this.$getFile(`${REPORT_ITEM_EXPORT}/${this.currentScene}`, data).then(res => {
          let type = this.exportIds.length === 1 ?
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' :
            'application/zip';
          let blob = new Blob([res], {type: type});
          let name = this.exportIds.length === 1 ? '巡检报告.xlsx' : '巡检报告.zip';
          downloadFile(blob, name)
        }).catch(err => {
          //异常情况
          this.$httpErr(err, REPORT_ITEM_EXPORT)
        })
      },
      //切换页码
      changePage(val) {
        this.pageNo = val;
        this.requestReport()
      },
      //切换每页条数
      changeSize(val) {
        this.pageNo = 1;
        this.pageSize = val;
        this.requestReport()
      },
    },
    computed: {
      ...mapGetters([
        'currentScene'
      ]),
    },
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
