<template>
    <div class="page-view">
        <!-- <div class="page-header">
            <span class="header-text">巡检场景有效期</span>
        </div>-->
        <div class="inspect-report-view">
            <div class="view-title">
                <div class="title-left">
                    <div class="left-item">
                        <span class="left-title">时间</span>
                        <DatePicker
                            :value="inspectTime"
                            format="yyyy-MM-dd HH:mm"
                            :editable="false"
                            type="datetimerange"
                            :clearable="false"
                            @on-change="updateReportDate"
                            placement="bottom-end"
                            split-panels
                            placeholder="请选择日期范围"
                            style="width: 280px"
                        ></DatePicker>
                    </div>
                    <div class="left-item">
                        <!-- <span class="left-title">场景有效期名称</span> -->
                        <Input
                            v-model="reportName"
                            placeholder="场景id、场景英文、中文名称"
                            style="width: 300px"
                        />
                    </div>
                    <Button type="primary" ghost @click="searchReport">查询</Button>
                </div>
            </div>
            <Table
                stripe
                ref="selection"
                :columns="tableTitle"
                :data="tableList"
                @on-selection-change="selectChange"
            >
                <template slot-scope="{ row, index }" slot="err">
                    <span :class="{'icon-text': (row.expCount !== 0)}" @click="putExpires(row)">续期</span>
                </template>
            </Table>
            <div class="paging-box">
                <Page
                    :current="pageNo"
                    :page-size="pageSize"
                    :total="total"
                    @on-change="changePage"
                    @on-page-size-change="changeSize"
                    show-total
                    show-elevator
                    show-sizer
                />
            </div>
            <Modal
                width="720"
                v-model="modelShow"
                title="续期"
                @on-ok="submitExpires"
                @on-cancel="modelShow = false"
            >
                <Row>
                    <Col span="4">续期时长：</Col>
                    <Col span="14">
                        <InputNumber :min="1" v-model="modelRow.day"></InputNumber>&nbsp;天
                    </Col>
                </Row>
            </Modal>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { API_EXPIRES, PUT_EXPIRE } from "../../../config/api";
import {
    formDate3,
    formDate4,
    currentTimeArr,
    dateConversion,
} from "../../../utils/helper";

export default {
    data() {
        return {
            //巡检时间
            inspectTime: [],
            //场景有效期名称
            reportName: "",
            //表格标题列表
            tableTitle: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "序号",
                    key: "index",
                    width: 80,
                },
                {
                    title: "id",
                    key: "buildingId",
                    width: 80,
                },
                {
                    title: "场景英文名",
                    key: "buildingNameEn",
                },
                {
                    title: "场景中文名",
                    key: "buildingNameCn",
                },
                {
                    title: "创建时间",
                    key: "createTime",
                },
                {
                    title: "到期时间",
                    key: "expireTime",
                },
                {
                    title: "倒计时",
                    key: "_remainDay",
                },
                {
                    title: "操作",
                    slot: "err",
                },
            ],
            tableList: [],
            //导出场景有效期id
            exportIds: [],
            //分页数据
            pageNo: 1,
            pageSize: 10,
            total: 0,
            modelRow: {
                day: "",
            },
            modelShow: false,
        };
    },
    created() {
        //初始化日期数据
        this.inspectTime = currentTimeArr();
        this.requestReport();
    },
    methods: {
        //更新场景有效期时间
        updateReportDate(val) {
            this.inspectTime = val[0] === "" ? currentTimeArr() : val;
        },
        //请求场景有效期列表
        requestReport() {
            let data = {
                current: this.pageNo,
                size: this.pageSize,
                search: this.reportName,
            };
            if (this.inspectTime.length !== 0) {
                data["start-time"] =
                    this.inspectTime[0] === ""
                        ? null
                        : formDate3(this.inspectTime[0]);
                data["end-time"] =
                    this.inspectTime[1] === ""
                        ? null
                        : formDate3(this.inspectTime[1]);
            }
            this.$get(`${API_EXPIRES}`, data)
                .then((res) => {
                    this.total = res.total;
                    let tableList = [];
                    res.records.forEach((arr, index) => {
                        arr.index = index + 1;
                        arr._remainDay =
                            arr.remainDay >= 0
                                ? `${arr.remainDay}天`
                                : "已到期";
                        arr.cellClassName = {
                            _remainDay: arr.remainDay >= 0 ? "" : "danger",
                        };
                        tableList.push(arr);
                    });
                    this.tableList = tableList;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, API_EXPIRES);
                });
        },
        //查询场景有效期
        searchReport() {
            this.pageNo = 1;
            this.requestReport();
        },
        // 续期
        putExpires(row) {
            console.log(row);
            this.modelRow = {
                ...row,
                day: "",
            };
            this.modelShow = true;
        },
        submitExpires() {
            if (!this.modelRow.day) {
                this.$Message.warning("请输入续期天数！");
            }
            let formData = new FormData();
            formData.append("buildingId", this.modelRow.buildingId);
            formData.append("day", this.modelRow.day);
            formData.append("token", this.token);
            this.$put(`${PUT_EXPIRE}/${this.currentScene}`, formData).then(
                (res) => {
                    this.$Message.success("续期成功！");
                    this.requestReport();
                }
            );
        },
        //列表选择改变
        selectChange(list) {
            let exportIds = [];
            list.forEach((item) => {
                exportIds.push(item.id);
            });
            this.exportIds = exportIds;
        },
        //切换页码
        changePage(val) {
            this.pageNo = val;
            this.requestReport();
        },
        //切换每页条数
        changeSize(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.requestReport();
        },
    },
    computed: {
        ...mapGetters(["currentScene", "token"]),
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
