import Vue from 'vue'
import VueRouter from 'vue-router'
//初始渲染页(根页面)
import Home from '../views/home/index'
//登录页
import Login from '../views/login/index'
//中心内容页
import Page from '../views/page/index'
//巡检管理首页
import ManagerHome from '../views/managerHome/index'
/*************
 * 巡检管理模块 *
 **************/
//巡检管理路由跳转页面
import InspectManager from '../views/inspectManager/index'
//设备管理
import Device from '../views/inspectManager/device/index'
//部署设备
import DeviceDeploy from '../views/inspectManager/deviceDeploy/index'
//设备类别
import DeviceCategory from '../views/inspectManager/deviceCategory/index'
//巡检点管理
import Locale from '../views/inspectManager/locale/index'
//巡检点部署
import LocaleDeploy from '../views/inspectManager/localeDeploy/index'
//巡检点关联设备
import LocaleRelateDevice from '../views/inspectManager/localeRelateDevice/index'
//巡检路线管理
import Way from '../views/inspectManager/way/index'
//编辑巡检路线
import WayEdit from '../views/inspectManager/wayEdit/index'
//查看巡检路线
import WayShow from '../views/inspectManager/wayShow/index'
//巡检任务管理
import Task from '../views/inspectManager/task/index'
//编辑巡检任务
import TaskEdit from '../views/inspectManager/taskEdit/index'
//巡检任务闲情
import TaskDetail from '../views/inspectManager/taskDetail/index'
//巡检报告管理
import Report from '../views/inspectManager/report/index'
//巡检报告详情
import ReportDetail from '../views/inspectManager/reportDetail/index'
//巡检报告异常项
import ReportAbnormal from '../views/inspectManager/reportAbnormal/index'

/*************
 * 报修管理模块 *
 **************/
//异常设备路由跳转页面
import RepairManager from '../views/repairManager/index'
import RepairOrder from '../views/repairManager/repairOrder/index'

/*************
 * 异常设备模块 *
 **************/
//异常设备路由跳转页面
import AbnormalDeviceManager from '../views/abnormalDeviceManager/index'
import AbnormalDevice from '../views/abnormalDeviceManager/abnormalDevice/index'

/*************
 * 消息通知模块 *
 **************/
//消息通知路由跳转页面
import MesNoticeManager from '../views/mesNoticeManager/index'
//消息通知
import MesNotice from '../views/mesNoticeManager/mesNotice/index'
//发布消息
import MesNoticePublish from '../views/mesNoticeManager/mesNoticePublish/index'

/*************
 * 知识库模块 *
 **************/
//知识库路由跳转页面
import KnowledgeBaseManager from '../views/knowledgeBaseManager/index'
//知识库列表
import KnowledgeBase from '../views/knowledgeBaseManager/knowledgeBase/index'
//知识库新增/编辑
import KnowledgeBaseEdit from '../views/knowledgeBaseManager/knowledgeBaseEdit/index'

/*************
 * 基础配置模块 *
 **************/
// 基础配置由跳转页面
import BasicConfigManager from '../views/basicConfigManager/index'
// 组织架构管理
import Framework from '../views/basicConfigManager/framework/index'
// 人员管理
import Member from '../views/basicConfigManager/member/index'
// 人员编辑/新增
import MemberEdit from '../views/basicConfigManager/memberEdit/index'
// 系统设置
import SystemSetting from '../views/basicConfigManager/systemSetting/index'
// 巡检公众号
import WechatOfficial from '../views/basicConfigManager/wechatOfficial/index'

/*************
 * 平台管理模块 *
 **************/
//平台管理由跳转页面
import PlatformManager from '../views/platformManager/index'
//角色管理
import Role from '../views/platformManager/role/index'
//角色编辑
import RoleEdit from '../views/platformManager/roleEdit/index'
//人员查看权限管理
import Authority from '../views/platformManager/authority/index'
//权限管理 => 隐藏人员编辑
import AuthorityEdit from '../views/platformManager/authorityEdit/index'
//数据同步管理
import Data from '../views/platformManager/data/index'
//菜单管理
import Menu from '../views/platformManager/menu/index'
// 场景有效期管理
import SceneValiditySetting from '../views/platformManager/sceneValiditySetting/index'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		children: [
			{
				path: '/login',
				name: 'Login',
				component: Login,
			},
			{
				path: '/page',
				name: 'Page',
				component: Page,
				children: [
					{
						path: 'managerhome',
						name: 'ManagerHome',
						component: ManagerHome,
					},
					{
						path: 'inspectmanager',
						name: 'InspectManager',
						component: InspectManager,
						children: [
							{
								path: 'device',
								name: 'Device',
								component: Device,
							},
							{
								path: 'device-deploy',
								name: 'DeployDevice',
								component: DeviceDeploy,
							},
							{
								path: 'device-category',
								name: 'DeviceCategory',
								component: DeviceCategory,
							},
							{
								path: 'locale',
								name: 'Locale',
								component: Locale,
							},
							{
								path: 'locale-deploy',
								name: 'LocaleDeploy',
								component: LocaleDeploy,
							},
							{
								path: 'locale-relatedevice',
								name: 'LocaleRelateDevice',
								component: LocaleRelateDevice,
							},
							{
								path: 'way',
								name: 'Way',
								component: Way,
							},
							{
								path: 'way-edit/:id',
								name: 'WayEdit',
								component: WayEdit,
							},
							{
								path: 'way-show',
								name: 'WayShow',
								component: WayShow,
							},
							{
								path: 'task',
								name: 'Task',
								component: Task,
							},
							{
								path: 'task-edit/:id',
								name: 'TaskEdit',
								component: TaskEdit,
							},
							{
								path: 'task-detail',
								name: 'TaskDetail',
								component: TaskDetail,
							},
							{
								path: 'report',
								name: 'Report',
								component: Report,
							},
							{
								path: 'report-detail',
								name: 'ReportDetail',
								component: ReportDetail,
							},
							{
								path: 'report-abnormal',
								name: 'ReportAbnormal',
								component: ReportAbnormal,
							},
						]
					},
					{
						path: 'repairManager',
						name: 'RepairManager',
						component: RepairManager,
						children: [
							{
								path: 'repairOrder',
								name: 'repairOrder',
								component: RepairOrder
							}
						]
					},
					{
						path: 'abnormaldevicemanager',
						name: 'AbnormalDeviceManager',
						component: AbnormalDeviceManager,
						children: [
							{
								path: 'abnormaldevice',
								name: 'AbnormalDevice',
								component: AbnormalDevice,
							},
						]
					},
					{
						path: 'mesnoticemanager',
						name: 'MesNoticeManager',
						component: MesNoticeManager,
						children: [
							{
								path: 'mesnotice',
								name: 'MesNotice',
								component: MesNotice,
							},
							{
								path: 'mesnotice-publish',
								name: 'MesNoticePublish',
								component: MesNoticePublish,
							},
						]
					},
					{
						path: 'knowledgebasemanager',
						name: 'knowledgeBaseManager',
						component: KnowledgeBaseManager,
						children: [
							{
								path: 'knowledgebase',
								name: 'KnowledgeBase',
								component: KnowledgeBase,
							},
							{
								path: 'knowledgebase-edit',
								name: 'KnowledgeBaseEdit',
								component: KnowledgeBaseEdit,
							},
						]
					},
					{
						path: 'basicconfigmanager',
						name: 'BasicConfigManager',
						component: BasicConfigManager,
						children: [
							{
								path: 'framework',
								name: 'Framework',
								component: Framework,
							},
							{
								path: 'member',
								name: 'Member',
								component: Member,
							},
							{
								path: 'member-edit/:id',
								name: 'MemberEdit',
								component: MemberEdit,
							},
							{
								path: 'systemsetting',
								name: 'SystemSetting',
								component: SystemSetting,
							},
							{
								path: 'wechatOfficial',
								name: 'wechatOfficial',
								component: WechatOfficial
							}
						]
					},
					{
						path: 'platformmanager',
						name: 'PlatformManager',
						component: PlatformManager,
						children: [
							{
								path: 'role',
								name: 'Role',
								component: Role,
							},
							{
								path: 'role-edit/:id',
								name: 'RoleEdit',
								component: RoleEdit,
							},
							{
								path: 'authority',
								name: 'Authority',
								component: Authority,
							},
							{
								path: 'authority-edit/:id',
								name: 'AuthorityEdit',
								component: AuthorityEdit,
							},
							{
								path: 'data',
								name: 'Data',
								component: Data,
							},
							{
								path: 'menu',
								name: 'Menu',
								component: Menu,
							},
							{
								path: 'sceneValiditySetting',
								name: 'sceneValiditySetting',
								component: SceneValiditySetting,
							}
						]
					},
				]
			},
		]
	},
];

const router = new VueRouter({
	routes
});

export default router
