<template>
    <div class="container">
        <Tabs v-model="tabActive" @on-click="tabChange">
            <TabPane label="待派单" name="undispatch"></TabPane>
            <TabPane label="进行中" name="doing"></TabPane>
            <TabPane label="待确认" name="unconfirm"></TabPane>
            <TabPane label="已完成" name="done"></TabPane>
        </Tabs>
        <div class="repair-manager-view">
            <div class="view-title">
                <div class="title-left">
                    <div class="left-item">
                        <span class="left-title">报修时间</span>
                        <DatePicker
                            type="daterange"
                            :value="reportTime"
                            format="yyyy-MM-dd HH:mm"
                            placement="bottom-end"
                            placeholder="Select date"
                            style="width: 280px"
                            @on-change="updateReportDate"
                        ></DatePicker>
                    </div>
                    <div class="left-item">
                        <Input v-model="deviceName" placeholder="模糊搜索设备名称" style="width: 300px" />
                    </div>
                    <Button type="primary" ghost @click="searchReport">查询</Button>
                    <Button
                        v-show="['undispatch', 'unconfirm'].includes(tabActive)"
                        type="primary"
                        :disabled="!exportIds.length"
                        @click="orderListProcess"
                    >批量处理</Button>
                </div>
                <Button type="primary" @click="exportReport" :disabled="!total">导出</Button>
            </div>
        </div>
        <Table
            stripe
            ref="selection"
            :columns="tableTitle"
            :data="tableList"
            @on-selection-change="selectChange"
        >
            <template slot-scope="{ row }" slot="action">
                <Button
                    type="primary"
                    v-show="['undispatch', 'unconfirm'].includes(tabActive)"
                    @click="orderProcess(row)"
                >处理</Button>
            </template>
        </Table>
        <div class="paging-box">
            <Page
                :total="total"
                :current="pageNo"
                :page-size="pageSize"
                @on-change="changePage"
                @on-page-size-change="changeSize"
                show-total
                show-elevator
                show-sizer
            />
        </div>
        <Modal v-model="processModal" title="处理" @on-ok="submitProcess" @on-cancel="cancelProcess">
            <Form :model="processForm" :label-width="140">
                <FormItem label="选择操作内容：">
                    <Select v-model="processForm.type">
                        <Option
                            v-for="(item, index) in typeList"
                            :key="index"
                            :value="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem v-show="['01', '05'].includes(processForm.type)" label="维修人员：">
                    <Select v-model="processForm.doerUserId">
                        <Option
                            v-for="(item, index) in staffList"
                            :key="index"
                            :value="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem v-show="['01', '05'].includes(processForm.type)" label="选择操作内容：">
                    <Select v-model="processForm.priority">
                        <Option
                            v-for="(item, index) in stateList"
                            :key="index"
                            :value="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="备注：">
                    <Input v-model="processForm.remarks" placeholder></Input>
                </FormItem>
            </Form>
        </Modal>
        <Modal v-model="repairDetailShow" title="报修工单详情" footer-hide :width="80">
            <Form
                ref="repairDetailForm"
                :model="repairDetail"
                :label-width="120"
                class="repair-detail-modal"
            >
                <FormItem style="margin-bottom: 0px">
                    <template slot="label">
                        <span style="font-weight: 700">报修详情</span>
                    </template>
                </FormItem>
                <FormItem label="设备名称：" style="margin-bottom: 0px">
                    <span>{{ repairDetail.name }}</span>
                </FormItem>
                <FormItem label="设备编号：" style="margin-bottom: 0px">
                    <span>{{ repairDetail.deviceId || '手动报修' }}</span>
                </FormItem>
                <FormItem label="设备位置：" style="margin-bottom: 0px">
                    <span>{{ repairDetail.location }}</span>
                </FormItem>
                <FormItem label="异常描述：" style="margin-bottom: 0px">
                    <span>{{ repairDetail.comment }}</span>
                </FormItem>
                <FormItem label="报修图片：">
                    <img
                        class="repair-img"
                        v-for="(item, index) in repairDetail.imgList"
                        :key="index"
                        :src="item"
                        @click="imgPreview(item)"
                    />
                </FormItem>
                <FormItem style="margin-bottom: 0px">
                    <template slot="label">
                        <span style="font-weight: 700">工单追踪</span>
                    </template>
                </FormItem>
                <FormItem>
                    <div
                        :class="
                            item.repairStatus === repairDetail.repairStatus
                                ? 'step active'
                                : 'step'
                        "
                        v-for="(item, index) in repairDetail.oprlogs"
                        :key="index"
                    >
                        <div class="title">{{ item.status }}</div>
                        <div class="content">
                            <div class="desc">
                                <span>{{ item.updateTime }}</span>
                                <span>操作人：{{ item.optUserName }}</span>
                                <span>{{ item.desc }}</span>
                            </div>
                            <div class="desc">{{item.comment}}</div>
                            <div class="imgs">
                                <img
                                    class="repair-img"
                                    v-for="(tip, index) in item.imgList"
                                    :key="index"
                                    :src="tip"
                                    alt
                                />
                            </div>
                            <div class="point"></div>
                        </div>
                    </div>
                </FormItem>
            </Form>
        </Modal>
        <Modal v-model="imgPreviewShow" width="83vw" :closable="false" footer-hide>
            <img
                class="previre-img"
                :src="imgPreviewUrl"
                alt
                @click="() => {imgPreviewShow = false}"
            />
        </Modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    baseUrl,
    REPAIR_USER,
    REPAIR_DETAIL,
    REPAIR_LIST_PAGE,
    REPAIR_DISPATCH,
    EXPORT_REPAIR_LIST,
} from "@/config/api";
import { formDate3, downloadFile, currentWeekTimeArr } from "@/utils/helper";
export default {
    data() {
        let that = this;
        return {
            tabActive: "undispatch",
            deviceName: "", // 设备名称
            //报告时间
            reportTime: "",
            //表格标题
            tableTitle: [
                {
                    type: "selection",
                    width: 65,
                    align: "center",
                },
                {
                    type: "index",
                    width: 80,
                    align: "center",
                    title: "序号",
                },
                {
                    title: "设备名称",
                    key: "name",
                },
                {
                    title: "设备编号",
                    key: "number",
                },
                {
                    title: "设备类型",
                    key: "deviceTypeName",
                },
                {
                    title: "报修单详情",
                    key: "comment",
                    width: 140,
                    ellipsis: true,
                    render: (h, { row, column, index }) => {
                        return h(
                            "a",
                            {
                                props: {
                                    value: row.comment,
                                },
                                style: {
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: () => {
                                        that.showRepairDetail(row);
                                    },
                                },
                            },
                            row.comment
                        );
                    },
                },
                {
                    title: "上报人",
                    key: "reportUser",
                },
                {
                    title: "上报时间",
                    key: "repairTime",
                    width: 170,
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 100,
                    align: "center",
                },
            ],
            //表格列表
            tableList: [],
            //分页数据
            pageNo: 1,
            pageSize: 10,
            total: 0,
            exportIds: [],
            processModal: false, // 处理弹窗显示
            // 处理表单数据
            processForm: {
                type: "",
                doerUserId: 0,
                priority: "",
                remarks: "",
                repairUuid: "",
            },
            // 处理类型列表
            typeList: [],
            typeListObj: {
                undispatch: [
                    {
                        value: "01",
                        label: "派单",
                    },
                    {
                        value: "02",
                        label: "完修",
                    },
                    {
                        value: "03",
                        label: "误报修完修",
                    },
                ],
                unconfirm: [
                    {
                        value: "02",
                        label: "确认完修",
                    },
                    {
                        value: "01",
                        label: "重新派单",
                    },
                ],
            },
            // 维修人员列表
            staffList: [
                {
                    value: 1,
                    label: "1",
                },
            ],
            // 紧急程度列表
            stateList: [
                {
                    value: "01",
                    label: "紧急",
                },
                {
                    value: "02",
                    label: "一般",
                },
            ],
            statusList: {
                "01": "报修",
                "02": "派单",
                "03": "接单",
                "04": "维修完成",
                "05": "确认完成",
            }, // 工单状态
            repairDetailShow: false, // 报修单弹窗显示
            // 报修单详情
            repairDetail: {},
            // 显示报修单详情大图
            imgPreviewShow: false,
            imgPreviewUrl: "",
        };
    },
    created() {
        //初始化日期数据
        // this.reportTime = currentTimeArr();
        this.reportTime = currentWeekTimeArr();
        // this.reportTime = ["2022-02-07T00:00:00", "2022-02-24T00:00:00"];
        this.requestRepairList();
        this.requestBaseUser();
    },
    computed: {
        ...mapGetters(["currentScene", "token"]),
    },
    methods: {
        // 切换tab
        tabChange(e) {
            this.requestRepairList();
        },
        //更新报告时间
        updateReportDate(val) {
            this.reportTime = val[0] === "" ? currentWeekTimeArr() : val;
        },
        //查询报告
        searchReport() {
            this.pageNo = 1;
            this.requestRepairList();
        },
        //切换页码
        changePage(val) {
            this.pageNo = val;
            this.requestRepairList();
        },
        //切换每页条数
        changeSize(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.requestRepairList();
        },
        // 获取人员列表
        requestBaseUser() {
            this.$get(`${REPAIR_USER}/${this.currentScene}`).then((res) => {
                if (!res || !res.length) {
                    this.staffList = [];
                    return;
                }
                this.staffList = res.map((item) => {
                    item.value = item.id;
                    item.label = item.nickname;
                    return item;
                });
            });
        },
        getListParams() {
            let params = {
                current: this.pageNo,
                size: this.pageSize,
                search: this.deviceName,
                ["start-time"]: "",
                ["end-time"]: "",
            };
            if (this.reportTime.length !== 0) {
                params["start-time"] =
                    this.reportTime[0] === ""
                        ? null
                        : formDate3(this.reportTime[0]);
                params["end-time"] =
                    this.reportTime[1] === ""
                        ? null
                        : formDate3(this.reportTime[1]);
            }
            return params;
        },
        // 获取报修列表
        requestRepairList() {
            let params = this.getListParams();
            this.$get(
                `${REPAIR_LIST_PAGE}/${this.tabActive}/${this.currentScene}`,
                params
            ).then((res) => {
                this.total = res.total;
                let tableList = [];
                if (res.records) {
                    res.records.forEach((arr, index) => {
                        arr.index = index + 1;
                        arr.deviceTypeName = arr.deviceType.name || "手动报修";
                        arr.number = arr.number || "手动报修";
                        tableList.push(arr);
                    });
                }
                this.tableList = tableList;
            });
        },
        // 导出列表
        exportReport() {
            let params = this.getListParams();
            delete params.current;
            delete params.size;
            window.open(
                `${baseUrl}${EXPORT_REPAIR_LIST}/${this.tabActive}/${this.currentScene}?token=${this.token}&search=${params.search}&start-time=${params["start-time"]}&end-time=${params["end-time"]}`
            );
            // this.$getFile(
            //     `${EXPORT_REPAIR_LIST}/${this.currentScene}`,
            //     params
            // ).then((res) => {
            //     let type =
            //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
            //     let blob = new Blob([res], { type: type });
            //     let name = "报修工单导出.xlsx";
            //     downloadFile(blob, name);
            // });
        },
        //列表选择改变
        selectChange(list) {
            let exportIds = [];
            list.forEach((item) => {
                exportIds.push(item.repairUuid);
            });
            this.exportIds = exportIds;
        },
        // 订单处理
        orderProcess(row) {
            this.typeList = this.typeListObj[this.tabActive];
            this.processModal = true;
            this.processForm.repairUuid = row.repairUuid;
            this.processForm = {
                ...this.processForm,
                repairUuid: row.repairUuid,
                // doerUserId: row.reportUser,
                doerUserId: 1,
                priority: row.priority,
            };
        },
        // 订单批量处理
        orderListProcess() {
            this.orderProcess({
                repairUuid: this.exportIds.join(","),
                priority: this.tableList[0].priority,
            });
        },
        // 提交处理结果
        submitProcess() {
            let { type, doerUserId, priority, remarks, repairUuid } =
                this.processForm;
            let formData = new FormData();
            formData.append("oprType", type === "03" ? "02" : type);
            formData.append("comment", remarks);
            if (type === "01") {
                formData.append("doerUserId", doerUserId);
                formData.append("priority", priority);
            }
            this.$post(
                `${REPAIR_DISPATCH}/${this.currentScene}/${repairUuid}`,
                formData
            ).then((res) => {
                this.exportIds = [];
                this.requestRepairList();
            });
        },
        // 关闭弹窗
        cancelProcess() {
            this.processModal = false;
            this.processForm = {
                type: "",
                doerUserId: 0,
                priority: "",
                remarks: "",
                repairUuid: "",
            };
        },
        // 显示报修单详情
        showRepairDetail(row) {
            this.$get(
                `${REPAIR_DETAIL}/${this.currentScene}/${row.repairUuid}`
            ).then((res) => {
                this.repairDetailShow = true;
                this.repairDetail = {
                    ...res,
                    name: row.name,
                    deviceTypeName: row.deviceTypeName,
                    imgList: res.images
                        ? res.images.split(",").map((url) => {
                              return `${baseUrl}${url}`;
                          })
                        : [],
                    oprlogs: res.oprlogs.map((log) => {
                        log.imgList = log.images
                            ? log.images.split(",").map((url) => {
                                  return `${baseUrl}${url}`;
                              })
                            : "";
                        log.status = this.statusList[log.repairStatus] || "";
                        if (log.repairStatus === "01" && log.lastRepairStatus=="02") {
                            let lastRepairStatusObj = {
                                "01": "派单",
                                "02": "已拒单",
                                "03": "转管理员",
                            };
                            log.status =
                                lastRepairStatusObj[log.lastRepairStatus] ||
                                log.status;
                        }
                        log.desc = this.setRepLogDesc(log, res);
                        return log;
                    }),
                };
                console.log("this.repairDetail", this.repairDetail);
            });
        },
        // 获取工单描述
        setRepLogDesc(log, order) {
            let desc = "";
            switch (log.repairStatus) {
                case "01":
                    desc = order.repairStatus === "01" ? `已报修，待派单` : "";
                    break;
                case "02":
                    desc = `已派单，待【${order.maintainNickName}】接单`;
                    break;
                case "03":
                    desc = `已接单，待上门维修`;
                    break;
                case "04":
                    desc = `已维修，待管理员确认`;
                    break;
                case "05":
                    desc = `已确认`;
                    break;
                default:
                    break;
            }
            return desc;
        },
        // 显示报修单详情大图
        imgPreview(item) {
            this.imgPreviewUrl = item;
            this.imgPreviewShow = true;
        },
    },
};
</script>


<style lang="scss" scoped>
@import "./index";
.container {
    margin: 30px;
    background-color: #fff;
}
</style>