<template>
    <div class="page-view page-deploy">
        <div class="page-header">
            <span class="header-text">{{ wayName }}</span>
        </div>
        <div class="route-show-view">
            <div id="fengMap"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ROUTE_RELATION_POINT, baseUrl } from "../../../config/api";
//地图对象
let _map = null;
//路线规划对象
let _naviAnalyser = null;

export default {
    data() {
        return {
            //巡检路线Id
            wayId: null,
            //巡检路线名称
            wayName: "",
            //巡检点列表
            locals: [],
            //楼层id
            groupId: null,
            //楼层对象
            floorObj: {},
            //巡检点对象
            localObj: {},
        };
    },
    created() {
        this.wayId = this.$route.query.id;
        this.wayName = this.$route.query.name;
    },
    mounted() {
        if (!!this.fmapID) {
            this.requestRelatedLocals();
        }
    },
    methods: {
        //请求巡检路线已关联巡检点
        requestRelatedLocals() {
            this.$get(
                `${ROUTE_RELATION_POINT}/${this.currentScene}/${this.wayId}`
            )
                .then((res) => {
                    this.floorObj = {};
                    this.localObj = {};
                    this.mapFloor.forEach((arr) => {
                        this.floorObj[arr.value] = arr.number;
                        this.localObj[arr.number] = [];
                    });
                    if (res != null) {
                        res.forEach((arr) => {
                            let floorNumber = this.floorObj[arr.floorId];
                            this.localObj[floorNumber].push(arr);
                        });
                        this.locals = res;
                    }
                    this.renderMap();
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, ROUTE_RELATION_POINT);
                });
        },
        //渲染地图加载
        renderMap() {
            if (this._isBeingDestroyed) {
                return;
            }
            let _this = this;
            const mapOptions = {
                container: document.getElementById("fengMap"), //渲染dom
                //地图数据
                mapServerURL: `${baseUrl}${_this.mapServerUrl}`,
                //地图主题
                mapThemeURL: `${baseUrl}${_this.mapThemeUrl}`,
                //默认主题
                defaultThemeName: _this.mapThemeStyle,
                //开发者应用名称
                appName: _this.appName,
                //开发者申请应用下web服务的key
                key: _this.key,
                //显示瓦片数据
                // tile:true
            };
            _map = new fengmap.FMMap(mapOptions);
            _map.openMapById(_this.fmapID, (error) => {
                _this.$Message.error({
                    background: true,
                    content: "地图生成异常",
                });
            });
            // 地图加载完成事件
            _map.on("loadComplete", function () {
                //显示楼层控件
                _this.loadDimensionCtrl(_map);
                //显示维度控件
                _this.loadScrollFloorCtrl(_map);
                //初始化导航路线
                _this.drawGroupLine(_map);
            });
            // map.mapScaleLevel = 20;
            //页面销毁，移除监听器
            _this.$once("hook:beforeDestroy", function () {
                _map.off("mapClickNode", () => {});
                _map.off("loadComplete", () => {});
                _map = null;
                _naviAnalyser = null;
            });
        },
        // 加载滚动型楼层切换控件
        loadScrollFloorCtrl(map) {
            /**
             * 渲染楼层切换控件
             * map.groupIDs 获取当前模型的所有楼层ID集合
             * * */
            const scrollFloorCtlOpt = {
                position: fengmap.FMControlPosition.RIGHT_TOP,
                showBtnCount: 6,
                allLayer: false,
                needAllLayerBtn: true,
                offset: {
                    x: -20,
                    y: 120,
                },
                imgURL: "./map_images/",
            };
            const scrollFloorControl = new fengmap.FMScrollGroupsControl(
                map,
                scrollFloorCtlOpt
            );
            let _this = this;
            _this.groupId = map._currentFocusGroup;
            _this.addMarks(map, map._currentFocusGroup);
            // 楼层切换
            scrollFloorControl.onChange((groups, allLayer) => {
                _this.groupId = groups[0];
                _this.addMarks(map, groups[0]);
            });
        },
        //添加地图标注
        addMarks(map, groupId) {
            let _this = this;
            let groupLayer = map.getFMGroup(groupId);
            _this.localObj[groupId].forEach((arr) => {
                //添加图片标注
                let imgLayer = new fengmap.FMImageMarkerLayer();
                let img = new fengmap.FMImageMarker({
                    name: arr.id,
                    x: arr.x,
                    y: arr.y,
                    url: "./map_images/route_icon.png",
                    size: 30,
                    height: 1,
                    avoid: true,
                });
                imgLayer.addMarker(img);
                img.alwaysShow();
                groupLayer.addLayer(imgLayer);
            });
        },
        //加载2D/3D切换
        loadDimensionCtrl(map) {
            //2D、3D控件配置
            const toolControl = new fengmap.toolControl(map, {
                //控件显示位置
                position: fengmap.FMControlPosition.RIGHT_TOP,
                offset: {
                    x: -20,
                    y: -20,
                },
                //初始化2D模式
                init2D: true,
                //设置为false表示只显示2D,3D切换按钮
                groupsButtonNeeded: false,
                // 配置图片资源的路径地址
                imgURL: "./map_images/",
                //点击按钮的回调方法,返回type表示按钮类型,value表示对应的功能值
                clickCallBack: function (type, value) {
                    console.log(type, value);
                },
            });
        },
        //绘制分段导航线
        drawGroupLine(map) {
            let coords = [];
            this.locals.forEach((arr) => {
                coords.push({
                    x: arr.x,
                    y: arr.y,
                    groupID: this.floorObj[arr.floorId],
                });
            });
            if (coords.length < 2) {
                return;
            }
            _naviAnalyser = new fengmap.FMNaviAnalyser(map);
            for (let i = 0; i < coords.length - 1; i++) {
                this.drawNaviLine(map, coords[i], coords[i + 1], _naviAnalyser);
            }
        },
        //画导航线
        drawNaviLine(map, start, end, naviAnalyser) {
            let analyzeNaviResult = naviAnalyser.analyzeNavi(
                start.groupID,
                start,
                end.groupID,
                end,
                fengmap.FMNaviMode.MODULE_BEST
            );
            if (
                fengmap.FMRouteCalcuResult.ROUTE_SUCCESS !== analyzeNaviResult
            ) {
                return;
            }
            //获取路径分析结果对象，所有路线集合
            let results = naviAnalyser.getNaviResults();
            //初始化线图层
            let line = new fengmap.FMLineMarker();
            for (let i = 0; i < results.length; i++) {
                let result = results[i];
                //楼层id
                let gid = result.groupId;
                //路径线点集合
                let points = result.getPointList();
                let points3d = [];
                points.forEach((point) => {
                    points3d.push({
                        //x坐标点
                        x: point.x,
                        //y坐标点
                        y: point.y,
                        //线标注高度
                        z: 1,
                    });
                });
                let seg = new fengmap.FMSegment();
                seg.groupId = gid;
                seg.points = points3d;
                line.addSegment(seg);
            }
            //配置线型、线宽、透明度等
            let lineStyle = {
                //设置线的宽度
                lineWidth: 6,
                //设置线的透明度
                alpha: 0.8,
                //设置线的类型为导航线
                lineType: fengmap.FMLineType.FMARROW,
                //设置线动画,false为动画
                noAnimate: false,
            };
            //画线
            map.drawLineMark(line, lineStyle);
        },
    },
    computed: {
        ...mapGetters([
            "systemIcon",
            "currentScene",
            "mapServerUrl",
            "mapThemeStyle",
            "mapThemeUrl",
            "fmapID",
            "appName",
            "key",
            "mapFloor",
        ]),
    },
    watch: {
        fmapID: function (val) {
            this.requestRelatedLocals();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
