<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">角色管理</span>
      <div class="btn-group">
        <Button type="primary" @click="addRole">新增角色</Button>
      </div>
    </div>
    <div class="role-view">
      <div class="view-title">
        <div class="title-left">
          <div class="left-item">
            <span class="left-title">角色名称</span>
            <Input v-model="roleName" placeholder="请输入" style="width: 300px" />
          </div>
          <Button type="primary" ghost @click="searchRole">查询</Button>
        </div>
      </div>
      <Table stripe ref="selection" :columns="tableTitle" :data="tableList">
        <template slot-scope="{ row, index }" slot="action">
          <span class="iconfont iconxiugai" @click="operateRole(row, 'edit', index)"></span>
          <span class="iconfont iconsahnchufuben" @click="operateRole(row, 'del', index)"></span>
        </template>
      </Table>
      <div class="paging-box">
        <Page :current="pageNo"
              :page-size="pageSize"
              :total="total"
              @on-change="changePage"
              @on-page-size-change="changeSize"
              show-total show-elevator show-sizer />
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {ROLE_LIST_PAGE, ROLE_ITEM_EDIT} from '../../../config/api'
  import {dateConversion} from '../../../utils/helper'
  export default {
    data() {
      return {
        //角色名称
        roleName: '',
        //表格标题
        tableTitle: [
          {
            title: '角色名称',
            key: 'name',
          },
          {
            title: '备注',
            key: 'comment'
          },
          {
            title: '创建时间',
            key: 'createTime'
          },
          {
            title: '创建人',
            key: 'createUserNickName'
          },
          {
            title: '操作',
            slot: 'action',
            width: 150,
            align: 'center'
          },
        ],
        //表格列表
        tableList: [],
        //分页数据
        pageNo: 1,
        pageSize: 10,
        total: 0
      }
    },
    created() {
      this.requestRoleList()
    },
    methods: {
      //查询角色列表
      requestRoleList() {
        let data = {
          current: this.pageNo,
          size: this.pageSize,
          search: this.roleName
        };
        this.$get(`${ROLE_LIST_PAGE}/${this.currentScene}`, data).then(res => {
          this.total = res.total;
          let tableList = [];
          res.records.forEach(arr => {
            arr.createTime = dateConversion(arr.createTime);
            tableList.push(arr)
          });
          this.tableList = tableList;
        }).catch(err => {
          //异常情况
          this.$httpErr(err, ROLE_LIST_PAGE)
        })
      },
      //查询角色
      searchRole() {
        this.pageNo = 1;
        this.requestRoleList()
      },
      //新增角色
      addRole() {
        this.$router.push({path: `role-edit/0`})
      },
      //操作表单角色
      operateRole(data, type, index) {
        if (type === 'edit') {
          this.$router.push({path: `role-edit/${data.id}`, query: {mes: JSON.stringify(data)}})
        } else if (type === 'del') {
          this.$Modal.confirm({
            title: '提示',
            content: '<p>确定删除此角色?</p>',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              this.delRole(data.id, index)
            }
          });
        }
      },
      //删除角色
      delRole(id, index) {
        this.$delete(`${ROLE_ITEM_EDIT}/${this.currentScene}/${id}`).then(res => {
          this.$Message.success({
            background: true,
            content: '删除角色成功'
          });
          this.tableList.splice(index, 1);
        }).catch(err => {
          //异常情况
          this.$httpErr(err, ROLE_ITEM_EDIT)
        })
      },
      //切换页码
      changePage(val) {
        this.pageNo = val;
        this.requestRoleList()
      },
      //切换每页条数
      changeSize(val) {
        this.pageNo = 1;
        this.pageSize = val;
        this.requestRoleList()
      }
    },
    computed: {
      ...mapState({
        currentScene: state => state.common.currentScene
      }),
    }
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
