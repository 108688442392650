<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">系统设置</span>
            <div class="btn-group">
                <Button type="primary" @click="saveSystem">保存</Button>
            </div>
        </div>
        <div class="system-view">
            <div class="form-item">
                <label class="item-label">
                    <span class="label-vital">*</span>公司名称
                </label>
                <div class="input-box">
                    <Input
                        v-model="sysName"
                        placeholder="请输入"
                        style="width: 50%"
                        @on-blur="blurInput('sysName')"
                    />
                </div>
                <div class="form-err" v-show="errForm.has('sysName')">请输入名称</div>
            </div>
            <div class="form-item">
                <label class="item-label">
                    <span class="label-vital">*</span>公司logo
                </label>
                <div class="input-box">
                    <upload-image
                        width="100"
                        height="100"
                        text="上传logo"
                        :file="logoPath"
                        @uploadEvent="uploadLogo"
                    ></upload-image>
                    <span class="note-mes">(推荐分辨率150*150)</span>
                </div>
                <div class="form-err" v-show="errForm.has('sysLogo')">请上传logo</div>
            </div>
            <div class="form-item">
                <label class="item-label">
                    <span class="label-vital">*</span>APP轮询频率
                </label>
                <div class="select-box">
                    <span>每隔</span>
                    <Select
                        v-model="pollingTime"
                        placeholder="请选择"
                        style="margin: 0 10px;width: 100px;"
                    >
                        <Option
                            v-for="(item, index) in pollingTimeList"
                            :key="index"
                            :value="item.value"
                        >{{item.label}}</Option>
                    </Select>
                    <span>秒，轮询一次</span>
                </div>
            </div>
            <el-image-upload :file="logoPath" @uploadEvent="uploadLogo"></el-image-upload>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import UploadImage from "../../../components/UploadImage";
import { SYSTEM_SETTING } from "../../../config/api";
export default {
    data() {
        return {
            //公司名称
            sysName: "",
            //公司logo
            sysLogo: "",
            //logo路径
            logoPath: "",
            pollingTime: 10,
            //错误表单
            errForm: new Set(),
            pollingTimeList: [
                {
                    label: "10秒",
                    value: 10,
                },
                {
                    label: "20秒",
                    value: 20,
                },
                {
                    label: "30秒",
                    value: 30,
                },
                {
                    label: "40秒",
                    value: 40,
                },
                {
                    label: "50秒",
                    value: 50,
                },
                {
                    label: "1分钟",
                    value: 60,
                },
                {
                    label: "1分10秒",
                    value: 70,
                },
                {
                    label: "1分20秒",
                    value: 80,
                },
                {
                    label: "1分30秒",
                    value: 90,
                },
                {
                    label: "1分40秒",
                    value: 100,
                },
                {
                    label: "1分50秒",
                    value: 110,
                },
                {
                    label: "2分钟",
                    value: 120,
                },
            ],
        };
    },
    components: {
        UploadImage,
    },
    created() {
        this.requestSystem();
    },
    methods: {
        //失去焦点
        blurInput(name) {
            let validateText = this[name] === "";
            this.updateFormErr(validateText, name);
        },
        //上传验证
        uploadValidate() {
            let validateText = !this.sysLogo;
            this.updateFormErr(validateText, "sysLogo");
        },
        //更新表单错误信息
        updateFormErr(val, name) {
            if (val) {
                this.errForm.add(name);
            } else {
                this.errForm.delete(name);
            }
            this.$forceUpdate();
        },
        //请求系统数据
        requestSystem() {
            this.$get(`${SYSTEM_SETTING}/${this.currentScene}`)
                .then((res) => {
                    this.sysName = res.sysName;
                    this.sysLogo = res.sysLogo;
                    this.logoPath = res.sysLogo;
                    this.pollingTime = res.pollingTime;
                    let sysIcon = !res.sysLogo
                        ? res.sysLogo
                        : this.$fileSrc(res.sysLogo);
                    this.$store.commit("common/UPDATE_SYSTEM_ICON", sysIcon);
                    this.$store.commit(
                        "common/UPDATE_SYSTEM_NAME",
                        this.sysName
                    );
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err);
                });
        },
        //验证提交表单
        validateForm() {
            this.blurInput("sysName");
            this.uploadValidate();
            return this.errForm.size === 0;
        },
        //保存系统设置
        saveSystem() {
            if (!this.validateForm()) {
                return;
            }
            let formData = new FormData();
            formData.append("sysName", this.sysName);
            formData.append("sysLogo", this.sysLogo);
            formData.append("pollingTime", this.pollingTime);
            this.$post(`${SYSTEM_SETTING}/${this.currentScene}`, formData)
                .then((res) => {
                    this.$Message.success({
                        background: true,
                        content: "保存成功",
                    });
                    this.requestSystem();
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err);
                });
        },
        //上传图片
        uploadLogo(val) {
            this.sysLogo = val;
            this.logoPath = "";
            this.uploadValidate();
        },
    },
    computed: {
        ...mapState({
            currentScene: (state) => state.common.currentScene,
        }),
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
