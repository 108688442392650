<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">异常设备</span>
    </div>
    <div class="abnormal--device-view">
      <div class="view-title">
        <div class="title-left">
          <div class="left-item">
            <span class="left-title">设备名称</span>
            <Input v-model="deviceName" placeholder="请输入" style="width: 200px" />
          </div>
          <div class="left-item">
            <span class="left-title">上报时间</span>
            <DatePicker :value="reportTime" type="date" placeholder="Select date" style="width: 200px"></DatePicker>
          </div>
          <Button type="primary" ghost @click="searchDevice">查询</Button>
        </div>
        <div class="title-right">
          <Button type="primary" @click="delGroup">批量处理</Button>
          <Button type="primary" @click="exportDevice">导出</Button>
        </div>
      </div>
      <Table stripe ref="selection" :columns="tableTitle" :data="tableList">
        <template slot-scope="{ row, index }" slot="action">
          <Button type="primary" v-show="row.dealStatus === 0" size="small" ghost @click="dealDevice">处理</Button>
          <span class="deal-btn" v-show="row.dealStatus === 1" @click="detailModal = true">已处理</span>
        </template>
      </Table>
      <div class="paging-box">
        <Page :total="100" show-total show-elevator show-sizer />
      </div>
    </div>
    <Modal
        v-model="dealModal"
        title="异常设备处理"
        @on-ok="ok"
        @on-cancel="cancel">
      <div class="deal-item">
        <label class="item-label">处理进度</label>
        <Select v-model="dealProcess" style="width: calc(100% - 84px)">
          <Option v-for="item in processList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
      <div class="deal-item">
        <label class="item-label">备注</label>
        <Input v-model="remark" maxlength="200" show-word-limit type="textarea"
               placeholder=""  style="width: calc(100% - 84px)" />
      </div>
    </Modal>
    <Modal
        footer-hide
        width="720px;"
        v-model="detailModal"
        title="异常设备详情">
      <div class="detail-item">
        <span class="detail-title">设备位置:</span>
        <span class="detail-text">A5把弄区域</span>
      </div>
      <div class="detail-item">
        <span class="detail-title">异常描述:</span>
        <span class="detail-text">设备外观污染,破损</span>
      </div>
      <div class="detail-item">
        <span class="detail-title">图片:</span>
        <div class="image-box">
          <img src="../../../assets/image/head.jpg" class="image-item" />
          <img src="../../../assets/image/head.jpg" class="image-item" />
          <img src="../../../assets/image/head.jpg" class="image-item" />
          <img src="../../../assets/image/head.jpg" class="image-item" />
          <img src="../../../assets/image/head.jpg" class="image-item" />
          <img src="../../../assets/image/head.jpg" class="image-item" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import { formDate3, currentTimeArr } from '../../../utils/helper'
  import { ABNORMAL_DEVICE_DETAIL, ABNORMAL_DEVICE_HANDLE } from '../../../config/api'
  export default {
    data() {
      return {
        //设备名称
        deviceName: '',
        //报告时间
        reportTime: '',
        //设备标题、列表
        tableTitle: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '序号',
            width: 80,
            key: 'index'
          },
          {
            title: '设备名称',
            key: 'name'
          },
          {
            title: '来源',
            key: 'source'
          },
          {
            title: '设备编号',
            key: 'number'
          },
          {
            title: '上报人',
            key: 'reporter'
          },
          {
            title: '上报时间',
            key: 'reportTime'
          },
          {
            title: '设备类型',
            key: 'type'
          },
          {
            title: '问题描述',
            key: 'desc'
          },
          {
            title: '处理进度',
            key: 'process'
          },
          {
            title: '处理备注',
            key: 'remark'
          },
          {
            title: '处理时间',
            key: 'dealTime'
          },
          {
            title: '操作',
            slot: 'action',
            width: 90,
            align: 'center'
          },
        ],
        tableList: [],
        //处理弹框状态
        dealModal: false,
        //处理进度
        dealProcess: '',
        processList: [
          {label: '未处理', value: 0},
          {label: '已处理', value: 1},
        ],
        //备注
        remark: '',
        //
        detailModal: false
      }
    },
    created() {
      // this.requestAbnormalDevice()
    },
    methods: {
      //请求异常设备列表
      requestAbnormalDevice() {
        this.$get(`${ABNORMAL_DEVICE_DETAIL}/${this.currentScene}`).then(res => {
          console.log(res)
        }).catch(err => {
          //异常情况
          this.$httpErr(err, ABNORMAL_DEVICE_DETAIL)
        })
      },
      //批量处理
      delGroup() {

      },
      //导出异常设备
      exportDevice() {

      },
      //查询设备
      searchDevice() {
        //TODO
      },
      //处理设备
      dealDevice(data) {
        this.dealModal = true;
      },
      //
      ok() {

      },
      //
      cancel() {

      }
    },
    computed: {
      ...mapGetters([
        'currentScene'
      ]),
    },
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
