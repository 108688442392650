<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">发布通知</span>
            <div class="btn-group">
                <Button type="primary" @click="releaseMsg">发布</Button>
            </div>
        </div>
        <div class="notice-publish-view">
            <div class="input-item">
                <label class="item-label">
                    <span class="label-vital">*</span>通知内容
                </label>
                <Input
                    v-model="noticeText"
                    type="textarea"
                    rows="10"
                    placeholder="请输入"
                    style="width: 50%"
                />
            </div>
            <div class="input-item">
                <label class="item-label">
                    <span class="label-vital">*</span>发送范围
                </label>
                <div class="member-box">
                    <RadioGroup v-model="memberSelectAll">
                        <Radio label="1">全部人员</Radio>
                        <Radio label="2">部分人员</Radio>
                    </RadioGroup>&nbsp;&nbsp;
                    <span v-show="memberSelectAll === '2'">{{ memberSelect }}</span>
                </div>
                <Button
                    type="primary"
                    v-show="memberSelectAll === '2'"
                    ghost
                    @click="editSendRange"
                >编辑</Button>
            </div>
        </div>
        <Modal v-model="editStatus" title="编辑人员" width="730" @on-ok="ok">
            <Transfer
                :titles="titleArr"
                :data="memberList"
                :target-keys="selectedKeys"
                :list-style="listStyle"
                :operations="['向左', '向右']"
                filterable
                @on-change="handleChange"
            ></Transfer>
        </Modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { RELEASE_MSG_USER, MSG_LIST, RELEASE_MSG } from "@/config/api";
import { formDate3, currentTimeArr } from "@/utils/helper";
import { getToken, getScene } from "@/utils/auth";
export default {
    data() {
        return {
            //角色id
            id: null,
            //通知内容
            noticeText: "",
            //编辑弹框状态
            editStatus: false,
            //成员列表
            memberList: [],
            //已选成员列表
            selectedKeys: [],
            //穿梭框样式
            listStyle: {
                width: "300px",
                height: "400px",
            },
            //穿梭狂标题
            titleArr: ["人员列表", "已选人员"],
            memberSelect: "",
            memberSelectAll: "1",
        };
    },
    created() {
        this.requestBaseUser();
    },
    methods: {
        // 获取人员列表
        requestBaseUser() {
            this.$get(`${RELEASE_MSG_USER}/${this.currentScene}`).then(
                (res) => {
                    if (!res || !res.length) {
                        this.memberList = [];
                        return;
                    }
                    this.memberList = res.map((item) => {
                        item.key = item.id;
                        item.label = item.nickname;
                        item.disabled = false;
                        return item;
                    });
                }
            );
        },
        //编辑部门
        editSendRange() {
            this.editStatus = true;
        },
        // 发布消息
        releaseMsg() {
            if (!this.noticeText) {
                return this.$Message.error("请输入消息内容！");
            }
            if (this.memberSelectAll === "2" && !this.selectedKeys.length) {
                return this.$Message.error("请编辑发送人员范围！");
            }
            //TODO
            let formData = new FormData();
            formData.append(
                "doerUsers",
                this.memberSelectAll === "1" ? "" : this.selectedKeys.toString()
            );
            formData.append("content", this.noticeText);
            formData.append("title", "巡检通知");
            formData.append("token", getToken());

            this.$post(`${RELEASE_MSG}/${this.currentScene}`, formData).then(
                (res) => {
                    this.$router.go(-1);
                }
            );
        },
        //
        ok() {
            // this.$Message.info('Clicked ok');
            let memberSelect = "";
            this.memberList.forEach((item) => {
                if (this.selectedKeys.includes(item.key)) {
                    memberSelect += `${item.nickname}、`;
                }
            });
            this.memberSelect = memberSelect.slice(0, memberSelect.length - 1);
        },
        handleChange(newTargetKeys) {
            this.selectedKeys = newTargetKeys;
        },
    },
    computed: {
        ...mapState({
            currentScene: (state) => state.common.currentScene,
        }),
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
