<template>
    <div class="page-view page-deploy">
        <div class="page-header">
            <span class="header-text">部署设备</span>
        </div>
        <div class="deploy-view">
            <div id="fengMap"></div>
        </div>
        <device-edit-modal
            :deviceId="deviceId"
            :deviceData="deviceData"
            type="deploy"
            @deviceEvent="deviceEvent"
            ref="device"
        ></device-edit-modal>
        <Modal v-model="modalStatus" :title="title">
            <Form :model="formValidate" :label-width="100">
                <FormItem label="楼层">
                    <Select
                        v-model="updateObj.floorId"
                        placeholder="请选择"
                        disabled
                    >
                        <Option
                            :value="item.value"
                            v-for="item in mapFloor"
                            :key="item.value"
                            >{{ item.label }}</Option
                        >
                    </Select>
                </FormItem>
                <FormItem label="设备坐标">
                    <Row>
                        <Col span="11">
                            <FormItem>
                                <Input
                                    v-model="updateObj.x"
                                    placeholder="请输入"
                                    disabled
                                />
                            </FormItem>
                        </Col>
                        <Col span="2" style="text-align: center; color: #999999"
                            >-</Col
                        >
                        <Col span="11">
                            <FormItem>
                                <Input
                                    v-model="updateObj.y"
                                    placeholder="请输入"
                                    disabled
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" @click="cancelUpdate">取消</Button>
                <Button
                    type="primary"
                    :loading="requestStatus"
                    @click="updateDevice"
                    >确认</Button
                >
            </div>
        </Modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeviceEditModal from "../../../components/DeviceEditModal";
import { DEVICE_LIST_NOPAGE, DEVICE_EDIT, baseUrl } from "../../../config/api";
import { conversionFrom } from "../../../utils/helper";
//地图对象
let _map = null;

export default {
    data() {
        return {
            //设备对象
            deviceObj: {},
            //楼层对象
            floorObj: {},
            //设备Id
            deviceId: null,
            //设备信息
            deviceData: {},
            //更新位置的设备信息
            deviceItemObj: {},
            //楼层id
            groupId: null,
            //弹框状态
            modalStatus: false,
            //弹框标题
            title: "",
            //更新对象
            updateObj: {
                floorId: "",
                x: null,
                y: null,
            },
            //请求状态
            requestStatus: false,
        };
    },
    components: {
        DeviceEditModal,
    },
    created() {
        let mes = this.$route.query.mes;
        //判断是否为更新导入的设备
        if (mes) {
            let mesObj = JSON.parse(mes);
            this.deviceId = mesObj.id;
            for (let val in mesObj) {
                if (val !== "id") {
                    this.deviceItemObj[val] = mesObj[val];
                }
            }
        }
    },
    mounted() {
        if (!!this.fmapID) {
            this.requestAllDevice();
        }
    },
    methods: {
        //请求所设备
        requestAllDevice(status) {
            if (this._isBeingDestroyed) {
                return;
            }
            this.$get(`${DEVICE_LIST_NOPAGE}/${this.currentScene}`)
                .then((res) => {
                    this.floorObj = {};
                    this.deviceObj = {};
                    this.mapFloor.forEach((arr) => {
                        this.floorObj[arr.value] = arr.number;
                        this.deviceObj[arr.number] = [];
                    });
                    res.forEach((arr) => {
                        if (arr.floorId !== null) {
							console.log('arr.floorId', arr.floorId);
                            let floorNumber = this.floorObj[arr.floorId];
                            this.deviceObj[floorNumber].push(arr);
                        }
                    });
                    if (!_map) {
                        this.renderMap();
                    }
                    if (status === "update") {
                        this.addMarks(_map, this.groupId);
                    }
                })
                // .catch((err) => {
                //     //异常情况
                //     this.$httpErr(err, DEVICE_LIST_NOPAGE);
                // });
        },
        //渲染地图加载
        renderMap() {
            let _this = this;
            const mapOptions = {
                container: document.getElementById("fengMap"), //渲染dom
                //地图数据
                mapServerURL: `${baseUrl}${_this.mapServerUrl}`,
                //地图主题
                mapThemeURL: `${baseUrl}${_this.mapThemeUrl}`,
                //默认主题
                defaultThemeName: _this.mapThemeStyle,
                //开发者应用名称
                appName: _this.appName,
                //开发者申请应用下web服务的key
                key: _this.key,
                //显示瓦片数据
                // tile:true
            };
            _map = new fengmap.FMMap(mapOptions);
            _map.openMapById(_this.fmapID, (error) => {
                _this.$Message.error({
                    background: true,
                    content: "地图生成异常",
                });
            });
            // 地图加载完成事件
            _map.on("loadComplete", function () {
                //显示楼层控件
                _this.loadDimensionCtrl(_map);
                //显示维度控件
                _this.loadScrollFloorCtrl(_map);
            });
            // map.mapScaleLevel = 20;
            _map.on("mapClickNode", function (event) {
                if (_this.$refs.device.modalStatus) {
                    return;
                }
                let clickedObj = event.mapCoord;
                let clickedTarget = event.target;
                if (!clickedObj) {
                    return;
                }
                // console.log(clickedTarget)
                if (clickedTarget.markername) {
                    //点击标注展示标注详情(可更新)
                    _this.showDevice(clickedTarget.markername);
                    return;
                }
                //地图内部点击效果可以部署设备
                _this.deployDevice(clickedObj, clickedTarget.groupID);
            });
            //页面销毁，移除监听器
            _this.$once("hook:beforeDestroy", function () {
                _map.off("mapClickNode", () => {});
                _map.off("loadComplete", () => {});
                _map = null;
            });
        },
        // 加载滚动型楼层切换控件
        loadScrollFloorCtrl(map) {
            /**
             * 渲染楼层切换控件
             * map.groupIDs 获取当前模型的所有楼层ID集合
             * * */
            const scrollFloorCtlOpt = {
                position: fengmap.FMControlPosition.RIGHT_TOP,
                showBtnCount: 6,
                allLayer: false,
                needAllLayerBtn: true,
                offset: {
                    x: -20,
                    y: 120,
                },
                imgURL: "./map_images/",
            };
            const scrollFloorControl = new fengmap.FMScrollGroupsControl(
                map,
                scrollFloorCtlOpt
            );
            let _this = this;
            _this.groupId = map._currentFocusGroup;
            _this.addMarks(map, map._currentFocusGroup);
            // 楼层切换
            scrollFloorControl.onChange((groups, allLayer) => {
                _this.groupId = groups[0];
                _this.addMarks(map, groups[0]);
            });
        },
        //添加地图标注
        addMarks(map, groupId) {
            let _this = this;
            let groupLayer = map.getFMGroup(groupId);
            _this.deviceObj[groupId].forEach((arr) => {
                //添加文字标注
                // let textLayer = new fengmap.FMTextMarkerLayer();
                // let text = new fengmap.FMTextMarker({
                //   name: arr.name,
                //   x: arr.x,
                //   y: arr.y,
                //   //文字标注样式设置
                //   fillcolor: "45,140,240", //填充色
                //   fontsize: 14, //字体大小
                //   height: 2.5,
                //   avoid: true
                // });
                // textLayer.addMarker(text);
                // text.alwaysShow();
                // groupLayer.addLayer(textLayer);
                //添加图片标注
                let imgLayer = new fengmap.FMImageMarkerLayer();
                let img = new fengmap.FMImageMarker({
                    name: arr.id,
                    x: arr.x,
                    y: arr.y,
                    url: !!arr.deviceType.icon
                        ? _this.$fileSrc(arr.deviceType.icon)
                        : _this.systemIcon,
                    size: 30,
                    height: 1,
                    avoid: true,
                });
                imgLayer.addMarker(img);
                img.alwaysShow();
                groupLayer.addLayer(imgLayer);
            });
        },
        //加载2D/3D切换
        loadDimensionCtrl(map) {
            //2D、3D控件配置
            const toolControl = new fengmap.toolControl(map, {
                //控件显示位置
                position: fengmap.FMControlPosition.RIGHT_TOP,
                offset: {
                    x: -20,
                    y: -20,
                },
                //初始化2D模式
                init2D: true,
                //设置为false表示只显示2D,3D切换按钮
                groupsButtonNeeded: false,
                // 配置图片资源的路径地址
                imgURL: "./map_images/",
                //点击按钮的回调方法,返回type表示按钮类型,value表示对应的功能值
                clickCallBack: function (type, value) {
                    console.log(type, value);
                },
            });
        },
        //展示设备详情
        showDevice(id) {
            this.deviceId = id;
            let obj = {};
            let groupDevices = this.deviceObj[this.groupId];
            for (let i = 0; i < groupDevices.length; i++) {
                if (groupDevices[i].id === id) {
                    obj = JSON.stringify(groupDevices[i]);
                    break;
                }
            }
            this.deviceData = JSON.parse(obj);
            this.$refs.device.modalStatus = true;
        },
        //部署设备
        deployDevice(data, groupID) {
            if (this.deviceId === null) {
                //新增设备
                this.deviceData = {
                    x: data.x,
                    y: data.y,
                    groupID: groupID,
                };
                this.$refs.device.modalStatus = true;
            } else {
                //更新设备
                this.title = `部署设备--${this.deviceItemObj.name} (${this.deviceItemObj.number})`;
                //确定楼层id
                let floorId;
                this.mapFloor.forEach((arr) => {
                    if (arr.number === groupID) {
                        floorId = arr.value;
                    }
                });
                this.updateObj = {
                    x: data.x,
                    y: data.y,
                    floorId: floorId,
                };
                this.modalStatus = true;
            }
        },
        //部署设备事件
        deviceEvent(content) {
            if (content.type === "new") {
                this.$Message.success({
                    background: true,
                    content: "新增设备成功",
                });
                // let newDevice = {
                //   id: Date.now(),
                //   deviceType: {
                //     icon: ''
                //   },
                //   ...content.data
                // };
                // this.deviceObj[this.groupId].push(newDevice)
                // this.addMarks(_map, this.groupId)
                this.requestAllDevice("update");
            } else if (content.type === "update") {
                let groupDevice = this.deviceObj[this.groupId];
                for (let i = 0; i < groupDevice.length; i++) {
                    if (this.deviceId === groupDevice[i].id) {
                        groupDevice[i] = { ...groupDevice[i], ...content.data };
                        break;
                    }
                }
                this.deviceId = null;
                this.$Message.success({
                    background: true,
                    content: "编辑设备成功",
                });
                this.requestAllDevice();
            } else if (content.type === "close") {
                this.deviceId = null;
            }
        },
        //更新设备
        updateDevice() {
            let data = {
                ...this.deviceItemObj,
                ...this.updateObj,
            };
            this.requestStatus = true;
            this.modalStatus = false;
            let url = `${DEVICE_EDIT}/${this.currentScene}/${this.deviceId}`;
            this.$put(url, conversionFrom(data))
                .then((res) => {
                    this.requestStatus = false;
                    this.$Message.success({
                        background: true,
                        content: "部署设备成功",
                    });
                    history.go(-1);
                })
                .catch((err) => {
                    this.requestStatus = false;
                    //异常情况
                    this.$httpErr(err, DEVICE_EDIT);
                });
        },
        //取消更新
        cancelUpdate() {
            this.modalStatus = false;
        },
    },
    computed: {
        ...mapGetters([
            "systemIcon",
            "currentScene",
            "mapServerUrl",
            "mapThemeStyle",
            "mapThemeUrl",
            "fmapID",
            "appName",
            "key",
            "mapFloor",
        ]),
    },
    watch: {
        fmapID: function (val) {
            this.requestAllDevice();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
