<template>
    <div class="page-view">
        <div class="system-view">
            <p class="title">
                当产生待派单报修工单、待确认报修工单、生成巡检报告时，会通过巡检公众号推送报修工单消息、巡检报告消息，扫描关注公众号并绑定平台账号，即可接收消息通知。
            </p>
            <div class="main">
                <div class="code-view">
                    <div class="img">
                        <img
                            src="../../../assets/image/wechat.jpg"
                            class="loading-img"
                        />
                    </div>
                    <p class="text">
                        请使用要接收报修工单、巡检报告的微信扫描以上二维码，关注巡检公众号并绑定平台账号。
                    </p>
                </div>
                <div class="list">
                    <div class="header">
                        <p class="left">已关注公众号成员</p>
                        <p class="right" @click="queryBindUser">刷新列表</p>
                    </div>
                    <Table
                        :columns="tableTitle"
                        :data="tableList"
                        size="small"
                        :show-header="false"
                        disabled-hover
                    >
                        <template slot-scope="{ row }" slot="action">
                            <span v-if="row.title">操作</span>
                            <p class="del-btn" v-else @click="showModal(row)">
                                移除
                            </p>
                        </template>
                    </Table>
                    <div class="footer">共{{tableList.length - 1}}条</div>
                </div>
            </div>
        </div>
        <Modal
            v-model="modalShow"
            title="提示"
            @on-ok="unBindUser"
            @on-cancel="modalShow = false"
        >
            <p>确定从已关注列表移除用户【{{ modalForm.nickname }}】吗？</p>
        </Modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { BIND_USERS, UNBIND_USERS } from "../../../config/api";
export default {
    data() {
        return {
            // 表格标题
            tableTitle: [
                {
                    title: "手机号",
                    key: "username",
                    width: 130,
                },
                {
                    title: "微信id",
                    key: "wxOpenid",
                    width: 320,
                },
                {
                    title: "姓名",
                    key: "nickname",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 80,
                    align: "center",
                },
            ],
            // 表格数据
            tableList: [],
            // 显示移除模态框
            modalShow: false,
            modalForm: {
                userId: 0,
            },
        };
    },
    created() {
        this.queryBindUser();
    },
    methods: {
        // 查询已绑定用户
        queryBindUser() {
            this.$get(`${BIND_USERS}/${this.currentScene}`).then((res) => {
                this.tableList = [
                    {
                        username: "手机号",
                        wxOpenid: "微信id",
                        nickname: "姓名",
                        title: "操作",
                    },
                    ...res,
                ];
            });
        },
        // 显示解绑弹窗
        showModal(row) {
            this.modalShow = true;
            this.modalForm = row;
        },
        // 用户解绑
        unBindUser() {
            this.$post(
                `${UNBIND_USERS}/${this.currentScene}/${this.modalForm.userId}`
            ).then((res) => {
                this.modalShow = false;
                this.queryBindUser();
                this.$Message.success("移除成功！");
            });
        },
    },
    computed: {
        ...mapState({
            currentScene: (state) => state.common.currentScene,
        }),
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
