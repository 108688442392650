<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">{{ !!id ? '编辑隐藏成员' : '新增隐藏成员' }}</span>
      <div class="btn-group">
        <Button type="primary" @click="saveAuthority">保存</Button>
        <Button type="primary" ghost @click="cancelEdit">取消</Button>
      </div>
    </div>
    <div class="authority-edit-view">
      <div class="edit-list">
        <div class="list-title">
          <span class="title-text">隐藏的成员</span>
          <div class="btn-group">
            <Button type="primary" ghost size="small" @click="editMember">编辑</Button>
          </div>
        </div>
        <div class="list-box">
          <div class="user-item" v-for="user in  memeberList" :key="user.key">
            <span class="iconfont iconyonghu"></span>
            <span class="user-name">{{ user.label }}</span>
          </div>
        </div>
      </div>
      <div class="edit-list">
        <div class="list-title">
          <span class="title-text">白名单</span>
          <div class="btn-group">
            <Button type="primary" ghost size="small" @click="editWhite">编辑</Button>
          </div>
        </div>
        <div class="list-box">
          <div class="user-item" v-for="user in  whiteList" :key="user.key">
            <span class="iconfont iconyonghu"></span>
            <span class="user-name">{{ user.label }}</span>
          </div>
        </div>
      </div>
    </div>
    <Modal
        v-model="memberStatus"
        title="修改成员"
        width="730">
      <Transfer
          :titles="titleArr"
          :data="allMemberList"
          :target-keys="selectedUsers"
          :list-style="listStyle"
          :operations="['向左','向右']"
          filterable
          @on-change="handleChange($event, 'user')">
      </Transfer>
      <div slot="footer">
        <Button type="text" @click="handleReset('user')">取消</Button>
        <Button type="primary"  @click="handleConfirm('user')">确认</Button>
      </div>
    </Modal>
    <Modal
        v-model="whiteStatus"
        title="修改白名单"
        width="730">
      <Transfer
          :titles="titleArr"
          :data="allWhiteList"
          :target-keys="selectedWhites"
          :list-style="listStyle"
          :operations="['向左','向右']"
          filterable
          @on-change="handleChange($event, 'white')">
      </Transfer>
      <div slot="footer">
        <Button type="text" @click="handleReset('white')">取消</Button>
        <Button type="primary"  @click="handleConfirm('white')">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {RIGHT_RULE_LIST, RIGHT_RULE_EDIT, MEMBER_LIST_NOPAGE} from '../../../config/api'
  import {conversionFrom} from '../../../utils/helper'
  export default {
    data() {
      return {
        //规则id
        id: null,
        //名单列表
        memeberList: [],
        //白名单列表
        whiteList: [],
        //所有规则已选择用户
        allSelectedUsers: [],
        //编辑弹框状态
        memberStatus: false,
        //所有成员列表
        allMemberList: [],
        //已选成员列表
        selectedUsers: [],
        //编辑白名单弹框状态
        whiteStatus: false,
        //所有白名单列表
        allWhiteList: [],
        //已选白名单列表
        selectedWhites: [],
        //穿梭框样式
        listStyle: {
          width: '300px',
          height: '400px'
        },
        //穿梭狂标题
        titleArr: ['成员列表', '已选成员']
      }
    },
    created() {
      let id = this.$route.params.id;
      this.id = id === '0' ? null : id;
      this.requestRightList(this.id)

    },
    methods: {
      //请求人员管理列表
      requestRightList(id) {
        this.$get(RIGHT_RULE_LIST).then(res => {
          res.forEach(arr => {
            //筛选对应隐藏规则数据
            if (`${arr.id}` === id) {
              this.initAuthority(arr)
            } else {
              //合并其他规则所有隐藏成员
              this.allSelectedUsers = this.allSelectedUsers.concat(arr.hiddenUserIds);
            }
          });
          this.requestAllMembers()
        }).catch(err => {
          //异常情况
          this.$httpErr(err, RIGHT_RULE_LIST)
        })
      },
      //初始化权限列表
      initAuthority(data) {
        data.hiddenUsers.forEach(arr => {
          this.memeberList.push({key: arr.id, label: arr.nickname})
        });
        data.whiteListUsers.forEach(arr => {
          this.whiteList.push({key: arr.id, label: arr.nickname})
        });
        this.selectedUsers = [...data.hiddenUserIds];
        this.selectedWhites = [...data.whiteListUserIds];
      },
      //获取所有成员列表
      requestAllMembers() {
        this.$get(`${MEMBER_LIST_NOPAGE}/${this.currentScene}`).then(res => {
          res.forEach(arr => {
            let obj = {
              key: arr.id,
              label: arr.nickname,
              // description: arr.username,
              // disabled: false
            };
            if (!this.allSelectedUsers.includes(arr.id)) {
              this.allMemberList.push(obj)
            }
            this.allWhiteList.push(obj)
          })
        }).catch(err => {
          //异常情况
          this.$httpErr(err, MEMBER_LIST_NOPAGE)
        })
      },
      //取消编辑/保存
      cancelEdit() {
        history.go(-1)
      },
      //编辑隐藏成员
      editMember() {
        this.memberStatus = true;
      },
      //编辑白名单
      editWhite() {
        this.whiteStatus = true;
      },
      //重置弹框数据
      handleReset(val) {
        let resetArr = [];
        if (val === 'user') {
          this.memeberList.forEach(arr => {
            resetArr.push(arr.key)
          });
          this.selectedUsers = resetArr;
          this.memberStatus = false;
        } else if (val === 'white') {
          this.whiteList.forEach(arr => {
            resetArr.push(arr.key)
          });
          this.selectedUsers = resetArr;
          this.whiteStatus = false;
        }
      },
      //确认保存弹框数据
      handleConfirm(val) {
        let updateArr = [];
        if (val === 'user') {
          this.allMemberList.forEach(arr => {
            if (this.selectedUsers.includes(arr.key)) {
              updateArr.push({...arr})
            }
          });
          this.memeberList = updateArr;
          this.memberStatus = false;
        } else if (val === 'white') {
          this.allWhiteList.forEach(arr => {
            if (this.selectedWhites.includes(arr.key)) {
              updateArr.push({...arr})
            }
          });
          this.whiteList = updateArr;
          this.whiteStatus = false;
        }
      },
      //保存成员权限
      saveAuthority() {
        let data = {
          hiddenUserIds: this.selectedUsers,
          whiteListUserIds: this.selectedWhites
        };
        let httpType = this.id !== null ? '$put' : '$post';
        let url = this.id !== null ? `${RIGHT_RULE_EDIT}/${this.id}` : `${RIGHT_RULE_EDIT}`;
        this[httpType](url, conversionFrom(data)).then(res => {
          let msg = this.id !== null ? '编辑规则成功' : '新增规则成功';
          this.$Message.success({
            background: true,
            content: msg
          });
          history.go(-1)
        }).catch(err => {
          //异常情况
          this.$httpErr(err, RIGHT_RULE_EDIT)
        })
      },
      //弹框变动
      handleChange (newTargetKeys, val) {
        if (val === 'user') {
          this.selectedUsers = newTargetKeys;
        } else if (val === 'white') {
          this.selectedWhites = newTargetKeys;
        }
      }
    },
    computed: {
      ...mapState({
        currentScene: state => state.common.currentScene
      }),
    }
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
