<template>
    <div class="header">
        <div class="header-left">
            <span class="iconfont iconshouye"></span>
            <Breadcrumb>
                <BreadcrumbItem v-for="(item, index) in routeRecord" :key="index" :to="item.path">{{ item.name }}</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="header-right">
            <!--  当前场景剩余天数 -->
            <div class="remain-day">
                <p>注意：系统还有</p>
                <p class="red-text">{{ remainDayObj.remainDay }}</p>
                <p>天到期，请联系业务人员续费！</p>
            </div>
            <Dropdown style="margin-left: 20px" @on-click="operateDown">
                <a class="drop-btn">
                    <span class="iconfont icongerenfill"></span>
                    <span class="drop-text">{{ nickname }}</span>
                    <Icon type="ios-arrow-down"></Icon>
                </a>
                <DropdownMenu slot="list">
                    <DropdownItem name="scene">切换场景</DropdownItem>
                    <DropdownItem name="login-out">登出</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { pageConfig } from '../../config/route'
export default {
    data() {
        return {
            //路由记录列表
            routeRecord: [],
            // 剩余时间数据
            remainDayObj: {}
        }
    },
    created() {
        let path = this.$route.path
        this.configRouteHistory(path)
        this.init()
    },
    methods: {
        init() {
            const sceneList = localStorage.getItem('sceneList')
            if (sceneList) {
                this.getRemainDay(JSON.parse(sceneList))
            }
        },
        getRemainDay(arr) {
            const currentScene = this.$store.getters.currentScene
            arr.forEach(el => {
                if (el.id === currentScene) {
                    this.remainDayObj = el
                }
            })
        },
        //配置路由参数
        configRouteHistory(path) {
            let pathArr = path.split('/').slice(1)
            if (pathArr.length < 2) {
                return
            }
            let pathRecord = []
            //首页路由
            pathRecord.push({ path: '/page/managerhome', name: '首页' })
            if (pathArr.length > 2) {
                //第一层路由
                pathRecord.push({
                    path: `/${pathArr[0]}/${pathArr[1]}`,
                    name: pageConfig[pathArr[1]].name
                })
                //判断最后一个路由是否为路由配置参数:id => 参数存在配置列表中则为时，否就向前推进一位
                let lastRoute = !!pageConfig[pathArr[pathArr.length - 1]] ? pathArr[pathArr.length - 1] : pathArr[pathArr.length - 2]
                //判断最后一个路由是否为路由配置参数:id => 是则缓存
                let params = !pageConfig[pathArr[pathArr.length - 1]] ? pathArr[pathArr.length - 1] : ''
                //路由参数带符号"-"的为编辑/新增页面 , "-"前则为其跳转页面
                if (lastRoute.includes('-')) {
                    let middlePath = lastRoute.split('-')[0]
                    pathRecord.push({
                        path: `/${pathArr[0]}/${pathArr[1]}/${middlePath}`,
                        name: pageConfig[middlePath].name
                    })
                }
                //params(如果不是空) => 路由默认参数(因为新增和编辑配置为一个页面，所有根据路由的默认参数来判断 => id(0是新增 | 其他为编辑))
                let finalPath = params === '' ? `/${pathArr[0]}/${pathArr[1]}/${pathArr[2]}` : `/${pathArr[0]}/${pathArr[1]}/${pathArr[2]}/${params}`

                let finalName
                if (params === '') {
                    finalName = pageConfig[pathArr[2]].name
                } else {
                    let otherName = params !== '0' ? '编辑' : '新增'
                    finalName = `${otherName}${pageConfig[pathArr[2]].name}`
                }
                pathRecord.push({ path: finalPath, name: finalName })
            } else {
                //第一层路由
                pathRecord.push({
                    path: `/${pathArr[0]}/${pathArr[1]}`,
                    name: pageConfig[pathArr[1]].name
                })
            }
            this.routeRecord = pathRecord
        },
        //操作下拉菜单
        operateDown(val) {
            if (val === 'login-out') {
                this.$logout()
            } else if (val === 'scene') {
                this.$emit('headerEvent', 'scene')
            }
        }
    },
    computed: {
        ...mapState({
            nickname: state => state.user.nickname,
            userId: state => state.user.userId
        })
    },
    watch: {
        $route: function(val) {
            this.configRouteHistory(val.path)
        }
    }
}
</script>

<style scoped lang="scss">
@import './index';
</style>
