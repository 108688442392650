<template>
    <div class="table-view">
        <div class="title">
            <div class="text">{{summary.title}}</div>
            <RadioGroup v-if="summary.switch" type="button" size="small" v-model="summary.switch" @on-change="changeSummaryDate">
                <Radio label="day">今日</Radio>
                <Radio label="week">本周</Radio>
            </RadioGroup>
        </div>
        <div class="data-list">
            <div class="date-item" v-for="(item, index) in summary.data" :key="index">
                <div class="num">{{item.count}}</div>
                <div class="state">{{item.title}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        summary: {
            type: Object,
            default: {},
        },
    },
    methods: {
        changeSummaryDate(val) {
            this.$emit('changeSummaryDate', this.summary.index, val);
        }
    },
};
</script>

<style lang="scss" scoped>
.table-view {
    font-size: 14px;
    box-shadow: 0px 0px 8px #ccc;
    .title {
        width: 100%;
        background-color: #eef5ff;
        @include fj;
        padding: 6px 20px;
        .text {
            // color: red;
            font-weight: 700;
        }
    }
    .data-list {
        @include fj(center);
        padding: 20px;
        .date-item {
            flex: 1;
            @include fc(center);
            .num {
                @include hh(36px);
            }
        }
    }
}
</style>