<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">人员管理</span>
      <div class="btn-group">
        <Button type="primary" @click="addMember">新增人员</Button>
      </div>
    </div>
    <div class="member-view">
      <div class="view-title">
        <div class="title-left">
          <div class="left-item">
            <span class="left-title">人员名称/账号</span>
            <Input v-model="memberName" placeholder="请输入" style="width: 300px" />
          </div>
          <Button type="primary" ghost @click="searchMember">查询</Button>
        </div>
        <div class="title-right">
          <Button type="primary" @click="distributeScene" :disabled="!checkedMember.length">分配场景</Button>
        </div>
      </div>
      <Table stripe ref="selection" :columns="tableTitle" :data="tableList" @on-selection-change="selectChange">
        <template slot-scope="{ row, index }" slot="action">
          <span class="iconfont iconxiugai" @click="editMember(row)"></span>
          <span class="iconfont iconsahnchufuben" @click="delItem(row)"></span>
        </template>
      </Table>
      <div class="paging-box">
        <Page :current="pageNo"
              :page-size="pageSize"
              :total="total"
              @on-change="changePage"
              @on-page-size-change="changeSize"
              show-total show-elevator show-sizer />
      </div>
    </div>
    <Modal
        v-model="editStatus"
        title="分配场景"
        width="730">
      <Transfer
          :titles="titleArr"
          :data="sceneList"
          :target-keys="selectedKeys"
          :list-style="listStyle"
          :operations="['向左','向右']"
          filterable
          @on-change="handleChange">
      </Transfer>
      <div slot="footer">
        <Button type="text" @click="cancel()">取消</Button>
        <Button type="primary" @click="confirmScene()">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {MEMBER_LIST_PAGE, MEMBER_ITEM_EDIT, USER_OWN_BUILDINGS,
    MEMBER_SCENE_EDIT, MEMBER_RELATION_TASK } from '../../../config/api'
  import {dateConversion, conversionFrom} from '../../../utils/helper'
  export default {
    data() {
      return {
        //人员名称
        memberName: '',
        //已悬选成员
        checkedMember: [],
        //表单数据
        tableTitle: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '序号',
            key: 'index',
            width: 80,
          },
          {
            title: '姓名',
            key: 'nickname'
          },
          {
            title: '账号',
            key: 'username'
          },
          {
            title: '性别',
            key: 'gender',
            width: 80,
          },
          // {
          //   title: '所属组织',
          //   key: 'organization'
          // },
          {
            title: '角色',
            key: 'roleName'
          },
          {
            title: '状态',
            key: 'status',
            width: 80,
          },
          {
            title: '创建时间',
            key: 'createTime'
          },
          {
            title: '操作',
            slot: 'action',
            width: 100,
            align: 'center'
          },
        ],
        tableList: [],
        //分页数据
        pageNo: 1,
        pageSize: 10,
        total: 0,
        //编辑弹框状态
        editStatus: false,
        //成员列表
        sceneList: [],
        //已选成员列表
        selectedKeys: [],
        //穿梭框样式
        listStyle: {
          width: '300px',
          height: '400px'
        },
        //穿梭狂标题
        titleArr: ['场景', '已选择的场景'],
        //操作用户id/type
        operateMemberId: '',
        operateMemberType: ''
      }
    },
    created() {
      this.requestAllScene()
      this.requestMemberList()
    },
    methods: {
      //查询人员列表
      requestMemberList() {
        let data = {
          current: this.pageNo,
          size: this.pageSize,
          search: this.memberName
        };
        this.$get(`${MEMBER_LIST_PAGE}/${this.currentScene}`, data).then(res => {
          let tableList  = [];
          this.total = res.total;
          res.records.forEach((arr, index) => {
            arr.index = index + 1;
            arr.status = arr.isEnable ? '启用' : '禁用';
            arr.roleName = !arr.roles.length ? '' : arr.roles[0].name;
            arr.createTime = dateConversion(arr.createTime);
            tableList.push({...arr})
          });
          this.tableList = tableList;
        }).catch(err => {
          //异常情况
          this.$httpErr(err, MEMBER_LIST_PAGE)
        })
      },
      //查询用户所有可分配场景
      requestAllScene() {
        this.$get(USER_OWN_BUILDINGS).then(res => {
          let sceneList = [];
          res.forEach(arr => {
            sceneList.push({
              key: arr.id,
              label: arr.comment,
              description: arr.name,
              disabled: (arr.id === this.currentScene)
            })
          });
          this.sceneList = sceneList;
        }).catch(err => {
          //异常情况
          this.$httpErr(err, USER_OWN_BUILDINGS)
        })
      },
      //查询成员
      searchMember() {
        this.pageNo = 1;
        this.requestMemberList()
      },
      //分配场景
      distributeScene () {
        if (this.checkedMember.length > 1) {
          this.$Modal.warning({
            title: '提示',
            content: '一次只能选择一个人员分配场景'
          });
        } else {
          let member = this.checkedMember[0];
          this.selectedKeys = member.buildingIds || [];
          this.operateMemberId = member.id;
          this.operateMemberType = member.type;
          this.editStatus = true;
        }
      },
      //编辑成员
      editMember(data) {
        let query = {
          username: data.username,
          nickname: data.nickname,
          gender: data.gender,
          roleIds: data.roleIds,
          departmentIds: data.departmentIds,
          isEnable: data.isEnable,
        };
        this.$router.push({path: `member-edit/${data.id}`, query: {mes: JSON.stringify(query)}})
      },

      //删除成员
      delItem(data) {
        this.$Modal.confirm({
          title: '提示',
          content: '<p>确定删除此成员?</p>',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            this.delConfirm(data.id)
          }
        });
      },
      //删除前确认是否关联巡检任务
      delConfirm(id) {
        this.$get(`${MEMBER_RELATION_TASK}/${id}`).then(res => {
          if (!res) {
            this.delMember(id)
          } else {
            this.$Message.error({
              background: true,
              content: '用户已关联巡检任务，无法删除'
            });
          }
        }).catch(err => {
          //异常情况
          this.$httpErr(err, MEMBER_RELATION_TASK)
        })
      },
      //删除成员
      delMember(id) {
        this.$delete(`${MEMBER_ITEM_EDIT}/${id}`).then(res => {
          this.$Message.success({
            background: true,
            content: '删除成员成功'
          });
          this.requestMemberList()
        }).catch(err => {
          //异常情况
          this.$httpErr(err)
        })
      },
      //添加成员
      addMember() {
        this.$router.push({path: `member-edit/0`})
      },
      //人员选择变化
      selectChange(selection) {
        this.checkedMember = selection;
      },
      //切换页码
      changePage(val) {
        this.pageNo = val;
        this.requestMemberList()
      },
      //切换每页条数
      changeSize(val) {
        this.pageNo = 1;
        this.pageSize = val;
        this.requestMemberList()
      },
      //穿梭框变化修改选择值
      handleChange(val) {
        console.log(val)
        this.selectedKeys = val;
      },
      //取消场景分配
      cancel() {
        this.editStatus = false;
      },
      //确定场景分配
      confirmScene() {
        this.editStatus = false;
        let data = {
          type: this.operateMemberType,
          buildingIds: [...this.selectedKeys]
        };
        this.$post(`${MEMBER_SCENE_EDIT}/${this.operateMemberId}`, conversionFrom(data)).then(res => {
          this.$Message.success({
            background: true,
            content: '场景配分配成功'
          });
          this.requestMemberList()
        }).catch(err => {
          //异常情况
          this.$httpErr(err, MEMBER_SCENE_EDIT)
        })
      }
    },
    computed: {
      ...mapGetters([
        'currentScene'
      ]),
    },
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
