<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">知识库</span>
        </div>
        <div class="knowledge-view">
            <div class="view-title">
                <div class="title-left">
                    <div class="left-item">
                        <span class="left-title">标题</span>
                        <Input
                            v-model="knowledgeTitle"
                            placeholder="请输入"
                            style="width: 300px"
                        />
                    </div>
                    <Button type="primary" ghost @click="searchTitle"
                        >查询</Button
                    >
                </div>
                <div class="title-right">
                    <Button type="primary" @click="addKnowledge">新增</Button>
                    <Upload
                        :show-upload-list="false"
                        :action="uploadUrl"
                        :data="uploadData"
                        :before-upload="beforeUpload"
                        :on-progress="uploading"
                        :on-success="uploadSuccess"
                    >
                        <Button type="primary">导入</Button>
                    </Upload>
                    <!-- <Button type="primary" @click="inputKnowledge">导入</Button> -->
                    <Button @click="delKnowledgeAll">批量删除</Button>
                </div>
            </div>
            <Table
                stripe
                ref="selection"
                :columns="tableTitle"
                :data="tableList"
                @on-selection-change="tableSelect"
            >
                <template slot-scope="{ row, index }" slot="action">
                    <span
                        class="iconfont iconxiugai"
                        @click="editKnowledge(row, index)"
                    ></span>
                    <span
                        class="iconfont iconsahnchufuben"
                        @click="showDelModal(row, index)"
                    ></span>
                </template>
            </Table>
            <div class="paging-box">
                <Page
                    :total="total"
                    @on-change="changePage"
                    @on-page-size-change="changeSize"
                    show-total
                    show-elevator
                    show-sizer
                />
            </div>
        </div>
        <Modal
            v-model="deleteModalShow"
            title="提示"
            @on-ok="delKnowledge"
            @on-cancel="hideDeleteModal"
        >
            <p>确定删除选中的内容？</p>
        </Modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { KNOWLEDGE_LIST, KNOWLEDGE_UPLOAD, baseUrl } from "@/config/api";
export default {
    data() {
        return {
            uploadUrl: "", // 上传知识库地址
            uploadData: {},
            //知识标题
            knowledgeTitle: "",
            //
            tableTitle: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "序号",
                    width: 100,
                    type: "index",
                },
                {
                    title: "标题",
                    key: "title",
                },
                {
                    title: "更新时间",
                    key: "updateTime",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center",
                },
            ],
            tableList: [],
            tableSelectList: [],
            //分页数据
            pageNo: 1,
            pageSize: 10,
            total: 0,
            deleteModalShow: false, // 删除弹窗
            deleteData: {},
            multipleDelete: false, // 是否批量删除
        };
    },
    created() {
        this.queryKnowledgeList();
        this.uploadUrl = `${baseUrl}${KNOWLEDGE_UPLOAD}/${this.currentScene}`;
        this.uploadData = {
            token: this.token,
        };
    },
    mounted() {},
    methods: {
        // 查询知识库列表
        queryKnowledgeList() {
            let params = {
                current: this.pageNo,
                size: this.pageSize,
                search: this.knowledgeTitle,
            };
            this.$get(`${KNOWLEDGE_LIST}/${this.currentScene}`, params).then(
                (res) => {
                    this.tableList = res.records;
                    this.total = res.total;
                }
            );
        },
        // 搜索
        searchTitle() {
            this.pageSize = 10;
            this.pageNo = 1;
            this.queryKnowledgeList();
        },
        //切换页码
        changePage(val) {
            this.pageNo = val;
            this.queryKnowledgeList();
        },
        //切换每页条数
        changeSize(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.queryKnowledgeList();
        },
        // 新增
        addKnowledge() {
            this.$router.push({ name: "KnowledgeBaseEdit" });
        },
        // 修改
        editKnowledge(row, index) {
            console.log(row);
            this.$router.push({
                name: "KnowledgeBaseEdit",
                params: {
                    id: row.knowledgeUuid,
                },
            });
        },
        // 展示删除弹窗
        showDelModal(row, index) {
            this.deleteModalShow = true;
            this.deleteData = row;
        },
        // 隐藏删除弹窗
        hideDeleteModal() {
            this.deleteModalShow = false;
            this.multipleDelete = false;
        },
        // 删除
        delKnowledge() {
            let ids = "";
            if (this.multipleDelete) {
                ids = this.tableSelectList.join(",");
            } else {
                ids = this.deleteData.knowledgeUuid;
            }
            this.$delete(`${KNOWLEDGE_LIST}/${this.currentScene}/${ids}`).then(
                () => {
                    this.hideDeleteModal();
                    this.queryKnowledgeList();
                }
            );
        },
        // 表格选择
        tableSelect(e) {
            console.log(e);
            this.tableSelectList = e.map((item) => {
                return item.knowledgeUuid;
            });
        },
        // 批量删除
        delKnowledgeAll() {
            if (!this.tableSelectList.length) {
                return this.$Message.error("请选择需要删除的项目");
            }
            this.deleteModalShow = true;
            this.multipleDelete = true;
        },
        // 导入之前
        beforeUpload() {},
        // 导入中
        uploading() {
            this.$Message.loading("正在导入…");
        },
        // 导入知识库成功
        uploadSuccess() {
            this.$Message.destroy();
            setTimeout(() => {
                this.$Message.success("导入成功");
            }, 100);
            this.queryKnowledgeList();
        },
    },
    computed: {
        ...mapGetters(["currentScene", "token"]),
    },
    watch: {},
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
