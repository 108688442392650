<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">设备管理</span>
            <div class="btn-group">
                <Button type="primary" @click="deployDevice">部署设备</Button>
                <Button type="primary" @click="manageCategory">设备类别管理</Button>
            </div>
        </div>
        <div class="inspect-device-view">
            <div class="device-title">
                <div class="title-item max-item">
                    <span class="left-title max-title">设备名称/编号</span>
                    <Input v-model="deviceName" placeholder="请输入" />
                </div>
                <div class="title-item">
                    <span class="left-title min-title">楼层</span>
                    <Select v-model="floorId" clearable>
                        <Option
                            v-for="item in mapFloor"
                            :value="item.value"
                            :key="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </div>
                <div class="title-item">
                    <span class="left-title min-title">状态</span>
                    <Select v-model="status" clearable>
                        <Option
                            v-for="item in statusList"
                            :value="item.value"
                            :key="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </div>
                <div class="title-item max-item">
                    <span class="left-title max-title">设备类型</span>
                    <Select v-model="type" clearable>
                        <Option
                            v-for="item in typeList"
                            :value="item.value"
                            :key="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </div>
                <div class="title-item">
                    <Button type="primary" ghost @click="searchDevice">查询</Button>
                    <div class="upload-btn">
                        <input
                            class="sel-input-file"
                            type="file"
                            @change="changeImport($event)"
                            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                        <Button icon="ios-cloud-upload-outline">批量导入设备</Button>
                    </div>
                </div>
            </div>
            <Table stripe ref="selection" :columns="tableTitle" :data="tableList">
                <template slot-scope="{ row, index }" slot="record">
                    <span class="icon-text" @click="showRecord(row)">巡检</span>
                    <span class="icon-text" @click="fixRecord(row)">报修</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <span
                        class="iconfont iconpin"
                        v-show="row.floorId === null"
                        @click="updateDevice(row)"
                    ></span>
                    <span class="iconfont iconxiugai" @click="editDevice(row)"></span>
                    <span class="iconfont iconsahnchufuben" @click="delItem(row)"></span>
                </template>
            </Table>
            <div class="paging-box">
                <Page
                    :current="pageNo"
                    :page-size="pageSize"
                    :total="total"
                    @on-change="changePage"
                    @on-page-size-change="changeSize"
                    show-total
                    show-elevator
                    show-sizer
                />
            </div>
        </div>
        <Modal width="720" v-model="inspectStatus" :title="recordTitle" footer-hide>
            <Table
                stripe
                ref="selection"
                :loading="inspectLoading"
                :columns="inspectTitle"
                :data="inspectTable"
            ></Table>
            <div class="paging-box">
                <Page
                    :current="recordPageNo"
                    :page-size="recordPageSize"
                    :total="recordTotal"
                    @on-change="changeRecordPage"
                    @on-page-size-change="changeRecordSize"
                    show-total
                    show-elevator
                    show-sizer
                />
            </div>
        </Modal>
        <Modal width="720" v-model="fixStatus" title="报修记录" footer-hide>
            <Table stripe ref="selection" :columns="fixTitle" :data="fixTable"></Table>
            <div class="paging-box">
                <Page :total="repairListTotal" show-total show-elevator show-sizer />
            </div>
        </Modal>
        <device-edit-modal
            :deviceId="deviceId"
            :deviceData="deviceData"
            type="edit"
            @deviceEvent="deviceEvent"
            ref="device"
        ></device-edit-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeviceEditModal from "../../../components/DeviceEditModal";
import {
    DEVICE_LIST_PAGE,
    DEVICE_EDIT,
    DEVICE_BATCH_IMPORT,
    DEVICE_INSPECT_RECORD,
    DEVICE_TYPE_NOPAGE,
    DEVICE_RELATION_POINT,
    DEVICE_REPAIR_DETAIL,
} from "../../../config/api";
import { dateConversion } from "../../../utils/helper";

export default {
    data() {
        return {
            //设备名称
            deviceName: "",
            //楼层id
            floorId: null,
            //设备状态
            status: null,
            statusList: [
                { label: "启用", value: "启用" },
                { label: "闲置", value: "闲置" },
                { label: "报废", value: "报废" },
            ],
            //设备类型
            type: null,
            typeList: [],
            //表单列表
            tableTitle: [
                {
                    title: "序号",
                    key: "index",
                    width: 70,
                },
                {
                    title: "设备名称",
                    key: "name",
                },
                {
                    title: "设备编号",
                    key: "number",
                },
                {
                    title: "楼层",
                    key: "floorName",
                    width: 80,
                },
                {
                    title: "坐标",
                    key: "coordinate",
                    width: 200,
                },
                {
                    title: "设备类型",
                    key: "type",
                },
                {
                    title: "位置",
                    key: "location",
                },
                {
                    title: "设备状态",
                    key: "status",
                    width: 100,
                },
                {
                    title: "记录查询",
                    slot: "record",
                    key: "record",
                    width: 100,
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 100,
                    align: "center",
                },
            ],
            tableList: [],
            //分页数据
            pageNo: 1,
            pageSize: 10,
            total: 0,
            //设备Id
            deviceId: null,
            //当前编辑设备
            deviceData: {},
            //巡检弹框状态
            inspectStatus: false,
            //巡检弹框loading
            inspectLoading: false,
            //巡检标题
            recordTitle: "",
            //巡检标题列表
            inspectTitle: [
                {
                    title: "序号",
                    key: "index",
                    width: 70,
                },
                {
                    title: "巡检时间",
                    key: "inspectTime",
                },
                {
                    title: "巡检情况",
                    key: "inspectStatus",
                    width: 100,
                },
                {
                    title: "备注",
                    key: "comment",
                },
                {
                    title: "巡检人",
                    key: "doerNickname",
                },
            ],
            inspectTable: [],
            //巡检记录分页数据
            recordTotal: 0,
            recordPageNo: 1,
            recordPageSize: 10,
            //维修弹框状态
            fixStatus: false,
            //维修列表标题
            fixTitle: [
                {
                    title: "序号",
                    key: "index",
                    width: 100,
                },
                {
                    title: "上报人",
                    key: "reportUser",
                },
                {
                    title: "报修时间",
                    key: "repairTime",
                },
                {
                    title: "问题描述",
                    key: "comment",
                },
            ],
            fixTable: [],
            repairListTotal: 0,
        };
    },
    created() {
        // console.log(this)
        this.requestType();
        this.requestDevice();
    },
    components: {
        DeviceEditModal,
    },
    methods: {
        //请求设备类型
        requestType() {
            this.$get(`${DEVICE_TYPE_NOPAGE}/${this.currentScene}`)
                .then((res) => {
                    let typeList = [];
                    res.forEach((arr) => {
                        typeList.push({ label: arr.name, value: arr.id });
                    });
                    this.typeList = typeList;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, DEVICE_TYPE_NOPAGE);
                });
        },
        //请求设备列表
        requestDevice() {
            let data = {
                current: this.pageNo,
                size: this.pageSize,
                search: this.deviceName,
                "floor-id": this.floorId,
                "dt-id": this.type,
                status: this.status,
            };
            this.$get(`${DEVICE_LIST_PAGE}/${this.currentScene}`, data)
                .then((res) => {
                    console.log(res.records);
                    this.total = res.total;
                    let tableList = [];
                    res.records.forEach((arr, index) => {
                        arr.index = index + 1;
                        arr.coordinate = "";
                        if (arr.floorId !== null) {
                            arr.coordinate = `(${arr.x.toFixed(
                                1
                            )}, ${arr.y.toFixed(1)})`;
                        }
                        arr.type = arr.deviceType.name;
                        arr.deviceTypeId = arr.deviceType.id;
                        tableList.push(arr);
                    });
                    this.tableList = tableList;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, DEVICE_LIST_PAGE);
                });
        },
        //查询设备列表
        searchDevice() {
            this.pageNo = 1;
            this.requestDevice();
        },
        //部署设备
        deployDevice() {
            this.$router.push({ path: "device-deploy" });
        },
        //导入设备文件变化
        changeImport(event) {
            let file = event.target.files[0];
            this.importRequest(file);
            //上传完成后清空input上传内容
            event.target.value = "";
        },
        //导入设备请求
        importRequest(file) {
            let formData = new FormData();
            formData.append("devicesFile", file);
            this.$post(`${DEVICE_BATCH_IMPORT}/${this.currentScene}`, formData)
                .then((res) => {
                    this.$Message.success({
                        background: true,
                        content: "导入设备成功",
                    });
                    this.pageNo = 1;
                    this.requestDevice();
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, DEVICE_BATCH_IMPORT);
                });
        },
        //管理设备类别
        manageCategory() {
            this.$router.push({ path: "device-category" });
        },
        //更新设备信息
        updateDevice(data) {
            let mesObj = {
                id: data.id,
                name: data.name,
                number: data.number,
                deviceTypeId: data.deviceTypeId,
                location: data.location,
                status: data.status,
            };
            this.$router.push({
                path: "device-deploy",
                query: { mes: JSON.stringify(mesObj) },
            });
        },
        //编辑设备
        editDevice(data) {
            this.deviceId = data.id;
            this.deviceData = data;
            this.$refs.device.modalStatus = true;
        },
        //编辑设备事件
        deviceEvent(content) {
            if (content.type === "update") {
                this.$Message.success({
                    background: true,
                    content: "编辑设备成功",
                });
                this.requestDevice();
            }
        },
        //删除某条数据
        delItem(data) {
            this.$Modal.confirm({
                title: "提示",
                content: "<p>确定删除此条数据</p>",
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                    this.delConfirm(data.id);
                },
            });
        },
        //删除前确认是否关联巡检点
        delConfirm(id) {
            this.$get(`${DEVICE_RELATION_POINT}/${id}`)
                .then((res) => {
                    if (!res) {
                        this.delDevice(id);
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "设备已关联巡检点，无法删除",
                        });
                    }
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, DEVICE_RELATION_POINT);
                });
        },
        //删除某条数据
        delDevice(id) {
            this.$delete(`${DEVICE_EDIT}/${this.currentScene}/${id}`)
                .then((res) => {
                    this.$Message.success({
                        background: true,
                        content: "删除设备成功",
                    });
                    this.requestDevice();
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, DEVICE_EDIT);
                });
        },
        //展示巡检记录
        showRecord(data) {
            this.recordTitle = `巡检记录--${data.name}(${data.number})`;
            this.deviceId = data.id;
            this.inspectRecord(false);
        },
        //设备巡检记录
        inspectRecord(status) {
            let content = {
                current: this.recordPageNo,
                size: this.recordPageSize,
            };
            this.inspectLoading = true;
            this.$get(
                `${DEVICE_INSPECT_RECORD}/${this.currentScene}/${this.deviceId}`,
                content,
                status
            )
                .then((res) => {
                    this.inspectStatus = true;
                    this.inspectLoading = false;
                    this.recordTotal = res.total;
                    let inspectTable = [];
                    res.records.forEach((arr, index) => {
                        arr.index = index + 1;
                        arr.inspectStatus = arr.status === 0 ? "正常" : "异常";
                        arr.inspectTime = dateConversion(arr.realIptTime);
                        inspectTable.push(arr);
                    });
                    this.inspectTable = inspectTable;
                })
                .catch((err) => {
                    this.inspectLoading = false;
                    //异常情况
                    this.$httpErr(err, DEVICE_INSPECT_RECORD);
                });
        },
        //设备报修记录
        fixRecord(data) {
            this.fixStatus = true;
            this.$get(
                `${DEVICE_REPAIR_DETAIL}/${this.currentScene}/${data.id}`
            ).then((res) => {
                let repairList = [];
                res.records.forEach((item, index) => {
                    item.index = index + 1;
                    repairList.push(item);
                });
                this.repairListTotal = res.total;
                this.fixTable = repairList;
            });
        },
        //切换页码
        changePage(val) {
            this.pageNo = val;
            this.requestDevice();
        },
        //切换每页条数
        changeSize(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.requestDevice();
        },
        //切换页码
        changeRecordPage(val) {
            this.recordPageNo = val;
            this.inspectRecord(true);
        },
        //切换每页条数
        changeRecordSize(val) {
            this.recordPageNo = 1;
            this.recordPageSize = val;
            this.inspectRecord(true);
        },
    },
    computed: {
        ...mapGetters(["currentScene", "mapFloor"]),
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
