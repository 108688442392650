<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">组织架构管理</span>
    </div>
    <div class="framework-view">

    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  export default {
    data() {
      return {

      }
    },
    created() {

    },
    mounted() {

    },
    methods: {
      //查询设备列表
      searchDevice() {
        //TODO
      },
    },
    computed: {
      ...mapState({

      }),
    },
    watch: {

    }
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
