<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">{{ reportName }}</span>
            <div class="btn-group">
                <Button type="primary" @click="exportReport">导出报告</Button>
            </div>
        </div>
        <div class="report-detail-view">
            <div class="report-title">巡检情况统计</div>
            <Table stripe ref="selection" :columns="tableTitle" :data="tableList">
                <template slot-scope="{ row, index }" slot="errNum">
                    <span class="err-text">{{ row.expCount }}</span>
                </template>
            </Table>
        </div>
        <div class="report-detail-view">
            <div class="report-title">巡检明细</div>
            <Table stripe ref="selection" :columns="detailTitle" :data="detailList">
                <template slot-scope="{ row, index }" slot="errItem">
                    <span :class="{ 'err-text': row.status === 1 }">
                        {{
                        row.errStatus
                        }}
                    </span>
                </template>
            </Table>
        </div>
        <div class="report-map-view">
            <div class="report-title">巡检轨迹</div>
            <div id="fengMap"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    REPORT_ITEM_DETAIL,
    REPORT_ITEM_EXPORT,
    baseUrl,
} from "../../../config/api";
import { dateConversion, downloadFile } from "../../../utils/helper";
//地图对象
let _map = null;
//路线规划对象
let _naviAnalyser = null;

export default {
    data() {
        return {
            //报告id
            reportId: "",
            //报告名称
            reportName: "",
            //统计标题列表
            tableTitle: [
                {
                    title: "设备类型",
                    key: "dtName",
                },
                {
                    title: "检查设备数量",
                    key: "count",
                },
                {
                    title: "异常数",
                    key: "expCount",
                    slot: "errNum",
                },
            ],
            tableList: [],
            //巡检详情列表
            detailTitle: [
                {
                    title: "序号",
                    key: "index",
                    width: 80,
                },
                {
                    title: "设备名称",
                    key: "deviceName",
                },
                {
                    title: "设备编号",
                    key: "deviceNumber",
                },
                {
                    title: "设备位置",
                    key: "deviceLocation",
                },
                {
                    title: "设备类型",
                    key: "deviceType",
                },
                {
                    title: "所属巡检点",
                    key: "pointName",
                },
                {
                    title: "上报人",
                    key: "reporter",
                },
                {
                    title: "上报时间",
                    key: "realIptTime",
                },
                {
                    title: "是否异常",
                    key: "errStatus",
                    slot: "errItem",
                },
                {
                    title: "问题描述",
                    key: "comment",
                },
            ],
            detailList: [],
            //巡检点集合
            locals: [],
            //巡检点对象
            localObj: {},
        };
    },
    mounted() {
        this.reportId = this.$route.query.id;
        this.reportName = this.$route.query.name;
        if (!!this.fmapID) {
            this.requestInspectReport();
        }
    },
    methods: {
        //查询巡检报告
        requestInspectReport() {
            this.$get(
                `${REPORT_ITEM_DETAIL}/${this.currentScene}/${this.reportId}`
            ).then((res) => {
                let tableList = [],
                    detailList = [];
                //设备列表列表
                res.stats.forEach((arr) => {
                    tableList.push(arr);
                });
                //设备类型集合
                let typeObj = {};
                res.deviceTypes.forEach((arr) => {
                    typeObj[arr.id] = arr.name;
                });
                let floorObj = {};
                this.localObj = {};
                this.mapFloor.forEach((arr) => {
                    floorObj[arr.value] = arr.number;
                    this.localObj[arr.number] = [];
                });
                //巡检点集合
                let pointsObj = {};
                res.points.forEach((arr) => {
                    pointsObj[arr.id] = arr.name;
                    this.locals.push({
                        x: arr.x,
                        y: arr.y,
                        groupID: floorObj[arr.floorId],
                    });
                    this.localObj[floorObj[arr.floorId]].push({
                        x: arr.x,
                        y: arr.y,
                    });
                });
                //上报人
                let reporter = "";
                res.realDoerUsers.forEach((arr) => {
                    reporter +=
                        reporter === "" ? arr.nickname : `,${arr.nickname}`;
                });
                //巡检设备明细列表
                res.detail.forEach((arr, index) => {
                    arr.index = index + 1;
                    arr.deviceName = arr.device.name;
                    arr.deviceNumber = arr.device.number;
                    arr.deviceLocation = arr.device.location;
                    arr.deviceName = arr.device.name;
                    arr.deviceType = typeObj[arr.device.deviceTypeId];
                    arr.pointName = pointsObj[arr.pointId];
                    arr.reporter = reporter;
                    arr.errStatus = arr.status === 0 ? "正常" : "异常";
                    arr.realIptTime = arr.realIptTime
                        ? dateConversion(arr.realIptTime)
                        : "";
                    detailList.push(arr);
                });
                this.tableList = tableList;
                this.detailList = detailList;
                this.$nextTick(() => {
                    this.renderMap();
                });
            });
            // .catch((err) => {
            //     //异常情况
            //     this.$httpErr(err, REPORT_ITEM_DETAIL);
            // });
        },
        //导出巡检报告
        exportReport() {
            let data = {
                reportIds: this.reportId,
            };
            this.$getFile(`${REPORT_ITEM_EXPORT}/${this.currentScene}`, data)
                .then((res) => {
                    let type =
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
                    let blob = new Blob([res], { type: type });
                    let name = "巡检报告.xlsx";
                    downloadFile(blob, name);
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, REPORT_ITEM_EXPORT);
                });
        },
        //查询设备列表
        renderMap() {
            let _this = this;
            if (_this._isBeingDestroyed) {
                return;
            }
            let mapOptions = {
                container: document.getElementById("fengMap"), //渲染dom
                //地图数据
                mapServerURL: `${baseUrl}${_this.mapServerUrl}`,
                //地图主题
                mapThemeURL: `${baseUrl}${_this.mapThemeUrl}`,
                //默认主题
                defaultThemeName: _this.mapThemeStyle,
                //开发者应用名称
                appName: _this.appName,
                //开发者申请应用下web服务的key
                key: _this.key,
            };
            _map = new fengmap.FMMap(mapOptions);
            _map.openMapById(_this.fmapID, (error) => {
                _this.$Message.error({
                    background: true,
                    content: "地图生成异常",
                });
            });
            // 地图加载完成事件
            _map.on("loadComplete", function () {
                //显示楼层控件
                _this.loadDimensionCtrl(_map);
                //显示维度控件
                _this.loadScrollFloorCtrl(_map);
                //初始化导航路线
                _this.drawGroupLine(_map);
            });

            this.$once("hook:beforeDestroy", function () {
                _map.off("mapClickNode", () => {});
                _map.off("loadComplete", () => {});
                _map = null;
                _naviAnalyser = null;
            });
        },
        // 加载滚动型楼层切换控件
        loadScrollFloorCtrl(map) {
            let _this = this;
            /**
             * 渲染楼层切换控件
             * map.groupIDs 获取当前模型的所有楼层ID集合
             * * */
            const scrollFloorCtlOpt = {
                position: fengmap.FMControlPosition.RIGHT_TOP,
                showBtnCount: 6,
                allLayer: false,
                needAllLayerBtn: true,
                offset: {
                    x: -20,
                    y: 120,
                },
                imgURL: "./map_images/",
            };
            const scrollFloorControl = new fengmap.FMScrollGroupsControl(
                map,
                scrollFloorCtlOpt
            );
            _this.addMarks(map, map._currentFocusGroup);
            // 楼层切换
            scrollFloorControl.onChange((groups, allLayer) => {
                console.log(groups);
                _this.addMarks(map, groups[0]);
            });
        },
        //加载2D/3D切换
        loadDimensionCtrl(map) {
            //2D、3D控件配置
            const toolControl = new fengmap.toolControl(map, {
                //控件显示位置
                position: fengmap.FMControlPosition.RIGHT_TOP,
                offset: {
                    x: -20,
                    y: -20,
                },
                //初始化2D模式
                init2D: true,
                //设置为false表示只显示2D,3D切换按钮
                groupsButtonNeeded: false,
                // 配置图片资源的路径地址
                imgURL: "./map_images/",
                //点击按钮的回调方法,返回type表示按钮类型,value表示对应的功能值
                clickCallBack: function (type, value) {
                    console.log(type, value);
                },
            });
        },
        //添加地图标注
        addMarks(map, groupId) {
            let _this = this;
            let groupLayer = map.getFMGroup(groupId);
            _this.localObj[groupId].forEach((arr) => {
                //添加图片标注
                let imgLayer = new fengmap.FMImageMarkerLayer();
                let img = new fengmap.FMImageMarker({
                    name: "",
                    x: arr.x,
                    y: arr.y,
                    url: "./map_images/route_icon.png",
                    size: 30,
                    height: 1,
                    avoid: true,
                });
                imgLayer.addMarker(img);
                img.alwaysShow();
                groupLayer.addLayer(imgLayer);
            });
        },
        //绘制分段导航线
        drawGroupLine(map) {
            if (this.locals.length < 2) {
                return;
            }
            console.log(this.locals);
            _naviAnalyser = new fengmap.FMNaviAnalyser(map);
            for (let i = 0; i < this.locals.length - 1; i++) {
                console.log(1);
                this.drawNaviLine(
                    map,
                    this.locals[i],
                    this.locals[i + 1],
                    _naviAnalyser
                );
            }
        },
        //画导航线
        drawNaviLine(map, start, end, naviAnalyser) {
            let analyzeNaviResult = naviAnalyser.analyzeNavi(
                start.groupID,
                start,
                end.groupID,
                end,
                fengmap.FMNaviMode.MODULE_BEST
            );
            if (
                fengmap.FMRouteCalcuResult.ROUTE_SUCCESS !== analyzeNaviResult
            ) {
                return;
            }
            //获取路径分析结果对象，所有路线集合
            let results = naviAnalyser.getNaviResults();
            //初始化线图层
            let line = new fengmap.FMLineMarker();
            for (let i = 0; i < results.length; i++) {
                let result = results[i];
                //楼层id
                let gid = result.groupId;
                //路径线点集合
                let points = result.getPointList();
                let points3d = [];
                points.forEach((point) => {
                    points3d.push({
                        //x坐标点
                        x: point.x,
                        //y坐标点
                        y: point.y,
                        //线标注高度
                        z: 1,
                    });
                });
                let seg = new fengmap.FMSegment();
                seg.groupId = gid;
                seg.points = points3d;
                line.addSegment(seg);
            }
            //配置线型、线宽、透明度等
            let lineStyle = {
                //设置线的宽度
                lineWidth: 6,
                //设置线的透明度
                alpha: 0.8,
                //设置线的类型为导航线
                lineType: fengmap.FMLineType.FMARROW,
                //设置线动画,false为动画
                noAnimate: false,
            };
            //画线
            map.drawLineMark(line, lineStyle);
        },
    },
    computed: {
        ...mapGetters([
            "systemIcon",
            "currentScene",
            "mapServerUrl",
            "mapThemeStyle",
            "mapThemeUrl",
            "fmapID",
            "appName",
            "key",
            "mapFloor",
        ]),
    },
    watch: {
        fmapID: function (val) {
            this.requestInspectReport();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
