<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">{{ !!id ? '编辑巡检任务' : '新增巡检任务' }}</span>
        </div>
        <div class="task-edit-view">
            <div class="form-item">
                <label class="item-label"></label>
                <div class="mes-box">
                    <span class="iconfont icontishi"></span>
                    <span class="mes-text">每天晚上23:00按照任务规则生成子任务</span>
                </div>
            </div>
            <div class="form-item">
                <label class="item-label">
                    <span class="label-vital">*</span>任务名称
                </label>
                <div class="input-box">
                    <Input
                        v-model="name"
                        placeholder="请输入"
                        style="width: 50%"
                        @on-blur="blurInput('name')"
                    />
                </div>
                <div class="form-err" v-show="errForm.has('name')">请输入任务名称</div>
            </div>
            <div class="form-item">
                <label class="item-label">
                    <span class="label-vital">*</span>巡检路线
                </label>
                <div class="input-box">
                    <Select
                        v-model="route"
                        style="width: 50%;"
                        @on-change="validateSelect('route', 'only')"
                    >
                        <Option
                            v-for="item in routeList"
                            :value="item.value"
                            :key="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </div>
                <div class="form-err" v-show="errForm.has('route')">请选择巡检路线</div>
            </div>
            <div class="form-item">
                <label class="item-label">巡检人</label>
                <div class="input-box">
                    <Select v-model="member" multiple style="width: 50%;">
                        <Option
                            v-for="item in memberList"
                            :value="item.value"
                            :key="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </div>
                <!--<div class="form-err" v-show="errForm.has('member')">请选择巡检人/div>-->
            </div>
            <div class="form-item">
                <label class="item-label">
                    <span class="label-vital">*</span>巡检周期
                </label>
                <div class="input-box">
                    <Select
                        v-model="cycle"
                        style="width: 50%;"
                        @on-change="validateSelect('cycle', 'only')"
                    >
                        <Option
                            v-for="item in frequencyList"
                            :value="item.value"
                            :key="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </div>
                <div class="form-err" v-show="errForm.has('cycle')">请选择巡检周期</div>
            </div>
            <div class="form-item" v-show="cycle === '自定义'">
                <label class="item-label">自定义周期间隔</label>
                <div class="input-box">
                    <Row>
                        <Col span="22">
                            <InputNumber :max="365" :min="1" v-model="intervalDay"></InputNumber>
                        </Col>
                        <Col span="2" style="transform: translateY(6px)">天</Col>
                    </Row>
                </div>
            </div>
            <div class="form-item" v-show="cycle !== '每日'">
                <label class="item-label">
                    <span class="label-vital">*</span>
                    {{ cycle === '临时' ? '巡检日期' : cycle=== '自定义' ? '开始日期' : '巡检时间' }}
                </label>
                <div class="input-box" v-show="cycle === '临时'">
                    <div class="input-body">
                        <div class="body-item" v-for="(item, index) in tempDates" :key="item.key">
                            <span class="item-span">{{ item }}</span>
                            <i class="ivu-icon ivu-icon-ios-close" @click="delTempDates(index)"></i>
                        </div>
                    </div>
                    <Button type="primary" ghost @click="addDate">添加</Button>
                </div>
                <div class="input-box" v-show="cycle === '每周'">
                    <Select
                        v-model="weekDays"
                        multiple
                        style="width: 50%;"
                        @on-change="validateSelect('weekDays', 'more')"
                    >
                        <Option
                            v-for="item in weekList"
                            :value="item.value"
                            :key="item.value"
                        >{{ item.label }}</Option>
                    </Select>
                </div>
                <div class="input-box" v-show="cycle === '自定义'">
                    <DatePicker
                        v-model="customDate"
                        confirm
                        type="date"
                        :clearable="false"
                        placement="bottom-end"
                        :start-date="dateNow"
                        :editable="false"
                        format="yyyy-MM-dd"
                        style="width: 50%;"
                        @on-change="selectCustomDate"
                    ></DatePicker>
                </div>
                <div class="form-err" v-show="errForm.has('tempDates')">请选择巡检日期</div>
                <div class="form-err" v-show="errForm.has('weekDays')">请选择巡检时间</div>
            </div>
            <div class="form-item">
                <label class="item-label">
                    <span class="label-vital">*</span>巡检时段
                </label>
                <div class="input-box">
                    <div class="input-body">
                        <div class="body-item" v-for="(item, index) in durations" :key="index">
                            <span class="item-span">
                                {{ `${JSON.parse(item).start}-${JSON.parse(item).end}${JSON.parse(item).isNextDay
                                ? '(跨' + JSON.parse(item).crossDay + '天)' : ''}` }}
                            </span>
                            <i class="ivu-icon ivu-icon-ios-close" @click="delDuration(index)"></i>
                        </div>
                    </div>
                    <Button type="primary" ghost @click="addTime">添加</Button>
                </div>
                <div class="form-err" v-show="errForm.has('durations')">请选择巡检时段</div>
            </div>
            <div class="form-item">
                <label class="item-label">
                    <span class="label-vital">*</span>是否按顺序巡检
                </label>
                <div class="input-box">
                    <RadioGroup v-model="isInOrder">
                        <Radio :label="true">
                            <span>是</span>
                        </Radio>
                        <Radio :label="false">
                            <span>否</span>
                        </Radio>
                    </RadioGroup>
                </div>
            </div>
            <div class="form-item">
                <label class="item-label">
                    <span class="label-vital">*</span>是否开启
                </label>
                <div class="input-box">
                    <RadioGroup v-model="isEnable">
                        <Radio :label="true">
                            <span>是</span>
                        </Radio>
                        <Radio :label="false">
                            <span>否</span>
                        </Radio>
                    </RadioGroup>
                </div>
            </div>
            <div class="form-item">
                <label class="item-label"></label>
                <Button type="primary" @click="saveTask">保存</Button>
            </div>
        </div>
        <Modal v-model="modalStatus" title="添加时段">
            <div class="form-item">
                <label class="item-label">
                    <span class="label-vital">*</span>是否跨天
                </label>
                <div class="input-box">
                    <Select v-model="overDay" placeholder="请选择" @on-change="validateOverDay">
                        <Option value="1">是</Option>
                        <Option value="2">否</Option>
                    </Select>
                </div>
                <div class="form-err" v-show="tempDatesForm.has('overDay')">请选择是否跨天</div>
            </div>
            <div class="form-item" v-show="overDay === '1'">
                <label class="item-label">
                    <span class="label-vital">*</span>跨天天数
                </label>
                <div class="input-box">
                    <Row>
                        <Col span="4" style="transform: translateY(6px)">跨</Col>
                        <Col span="18">
                            <InputNumber :max="365" :min="1" v-model="crossDay"></InputNumber>
                            <!-- <Select
                                v-model="crossDay"
                                placeholder="请选择"
                                @on-change="validateOverDay"
                                style="margin: 0 10px;width: 100px;"
                            >
                                <Option
                                    v-for="(item, index) in 30"
                                    :key="index"
                                    :value="index"
                                >{{index}}</Option>
                            </Select>-->
                        </Col>
                        <Col span="2" style="transform: translateY(6px)">天</Col>
                    </Row>
                </div>
                <div class="form-err" v-show="tempDatesForm.has('overDay')">请选择是否跨天</div>
            </div>
            <!-- <div class="form-item" v-show="overDay === '1'">
                <label class="item-label">
                    <span class="label-vital">*</span>开始日期
                </label>
                <div class="input-box">
                    <DatePicker
                        confirm
                        type="date"
                        :clearable="false"
                        placement="bottom-end"
                        :start-date="dateNow"
                        :editable="false"
                        format="yyyy-MM-dd"
                        style="width: 100%;"
                        @on-change="selectCustomDate"
                    ></DatePicker>
                </div>
                <div class="form-err" v-show="tempDatesForm.has('timeArr')">请选择开始日期</div>
            </div>-->
            <div class="form-item" v-show="overDay !== '1'">
                <label class="item-label">
                    <span class="label-vital">*</span>时间
                </label>
                <div class="input-box">
                    <TimePicker
                        confirm
                        type="timerange"
                        :clearable="false"
                        placement="bottom-end"
                        @on-change="validateTimeArr"
                        v-model="timeArr"
                        :editable="false"
                        placeholder="Select time"
                        style="width: 100%;"
                    ></TimePicker>
                </div>
                <div class="form-err" v-show="tempDatesForm.has('timeArr')">请选择时间段</div>
            </div>
            <div class="form-item" v-show="overDay === '1'">
                <label class="item-label">
                    <span class="label-vital">*</span>时间
                </label>
                <div class="input-box">
                    <Row>
                        <Col span="11">
                            <TimePicker
                                type="time"
                                :clearable="false"
                                @on-change="validateStartTime"
                                v-model="startTime"
                                :editable="false"
                                placeholder="start time"
                                style="width: 100%"
                            ></TimePicker>
                            <div
                                class="form-err other-err"
                                v-show="tempDatesForm.has('startTime')"
                            >请选择起始时间</div>
                        </Col>
                        <Col span="2" style="text-align: center; color: #999999;">-</Col>
                        <Col span="11">
                            <TimePicker
                                type="time"
                                :clearable="false"
                                @on-change="validateEndTime"
                                v-model="endTime"
                                :editable="false"
                                placeholder="end time"
                                style="width: 100%"
                            ></TimePicker>
                            <div
                                class="form-err other-err"
                                v-show="tempDatesForm.has('endTime')"
                            >请选择结束时间</div>
                        </Col>
                    </Row>
                </div>
                <!--<div class="form-err" v-show="tempDatesForm.has('startTime')">请选择起始时间</div>-->
                <!--<div class="form-err" v-show="tempDatesForm.has('endTime') && !tempDatesForm.has('startTime')">请选择结束时间</div>-->
            </div>
            <div slot="footer">
                <Button type="text" @click="handleReset">取消</Button>
                <Button type="primary" @click="handleSubmit">确认</Button>
            </div>
        </Modal>
        <Modal v-model="dateStatus" title="添加巡检日期">
            <DatePicker
                type="date"
                v-model="inspectDate"
                show-week-numbers
                placeholder="Select date"
                style="width: 100%"
            ></DatePicker>
            <div slot="footer">
                <Button type="text" @click="cancel()">取消</Button>
                <Button type="primary" @click="confirm()">确认</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    MEMBER_LIST_NOPAGE,
    ROUTE_LIST_NOPAGE,
    TASK_ITEM_EDIT,
} from "../../../config/api";
import { formDate2, currentTimeArr } from "../../../utils/helper";
export default {
    data() {
        return {
            //角色id
            id: null,
            //任务名称
            name: "",
            //巡检路线
            route: "",
            routeList: [],
            //巡检周期
            cycle: "每日",
            frequencyList: [
                { label: "每日", value: "每日" },
                { label: "每周", value: "每周" },
                { label: "临时", value: "临时" },
                { label: "自定义", value: "自定义" },
            ],
            //巡检人员
            member: [],
            memberList: [],
            //巡检人员分部门示例
            groupList: [
                {
                    name: "第一部门",
                    memberList: [
                        { label: "战三", value: 1 },
                        { label: "李四", value: 2 },
                        { label: "丁一", value: 3 },
                    ],
                },
            ],
            //临时巡检时间段
            tempDates: [],
            //每周时间段
            weekDays: [],
            weekList: [
                { label: "周一", value: 1 },
                { label: "周二", value: 2 },
                { label: "周三", value: 3 },
                { label: "周四", value: 4 },
                { label: "周五", value: 5 },
                { label: "周六", value: 6 },
                { label: "周七", value: 7 },
            ],
            //巡检时段数组
            durations: [],
            //是否按顺序
            isInOrder: true,
            //是否启用
            isEnable: true,
            //自定义错误内容
            errForm: new Set(),
            //巡检时间临时弹框列表
            modalStatus: false,
            //临时时间段
            overDay: "",
            // 跨天天数
            crossDay: 0,
            // 自定义间隔天数
            intervalDay: 0,
            // 开始日期
            customDate: "",
            dateNow: "", // 当前日期
            timeArr: [],
            //巡检时间段
            startTime: "",
            endTime: "",
            //自定义巡检临时时间段错误内容
            tempDatesForm: new Set(),
            //巡检日期弹框
            dateStatus: false,
            //巡检日期
            inspectDate: "",
        };
    },
    created() {
        let id = this.$route.params.id;
        this.id = id === "0" ? null : id;
        this.requestMember();
        this.requestRoutes();
        if (this.id !== null) {
            let mes = this.$route.query.mes;
            this.initTask(mes);
        }
        let now = currentTimeArr()[0];
        this.customDate = now.slice(0, 10);
        // this.dateNow = now.slice(0, 10);
    },
    methods: {
        //请求人员列表
        requestMember() {
            this.$get(`${MEMBER_LIST_NOPAGE}/${this.currentScene}`)
                .then((res) => {
                    let memberList = [];
                    res.forEach((arr) => {
                        memberList.push({ label: arr.nickname, value: arr.id });
                    });
                    this.memberList = memberList;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, MEMBER_LIST_NOPAGE);
                });
        },
        //请求巡检路线列表
        requestRoutes() {
            this.$get(`${ROUTE_LIST_NOPAGE}/${this.currentScene}`)
                .then((res) => {
                    let routeList = [];
                    res.forEach((arr) => {
                        routeList.push({ label: arr.name, value: arr.id });
                    });
                    this.routeList = routeList;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, MEMBER_LIST_NOPAGE);
                });
        },
        //初始化任务详情
        initTask(mes) {
            let data = JSON.parse(mes);
            this.name = data.name;
            this.route = data.routeId;
            this.member = data.doerUserIds;
            this.cycle = data.cycle;
            this.intervalDay = data.intervalDay;
            this.customDate = data.customDate;
            if (data.cycle === "每周") {
                this.weekDays = [...data.weekDays];
            } else if (data.cycle === "临时") {
                this.tempDates = [...data.tempDates];
            }
            data.durations.forEach((arr) => {
                console.log(arr);
                this.durations.push(JSON.stringify(arr));
            });
            this.isEnable = data.isEnable;
            this.isInOrder = data.isInOrder;
        },
        //验证输入框
        blurInput(name) {
            let validateText = this[name] === "";
            this.updateFormErr(validateText, name);
        },
        //验证选择框
        validateSelect(name, type) {
            if (name === "cycle") {
                this.errForm.delete("weekDays");
                this.errForm.delete("tempDates");
            }
            let validateText;
            if (type === "only") {
                validateText = !this[name];
            } else if (type === "more") {
                validateText = !this[name].length;
            }
            this.updateFormErr(validateText, name);
        },
        //验证临时巡检时段
        validateTempDates() {
            let validateText = !this.tempDates.length;
            this.updateFormErr(validateText, "tempDates");
        },
        //验证巡检日期
        validateDuration() {
            let validateText = !this.durations.length;
            this.updateFormErr(validateText, "durations");
        },
        //更新表单错误信息
        updateFormErr(val, name) {
            if (val) {
                this.errForm.add(name);
            } else {
                this.errForm.delete(name);
            }
            this.$forceUpdate();
        },
        //删除巡检时段
        delTempDates(index) {
            this.tempDates.splice(index, 1);
            this.validateTempDates();
        },
        //删除巡检日期
        delDuration(index) {
            this.durations.splice(index, 1);
            this.validateDuration();
        },
        //验证任务表单
        validateTask() {
            this.blurInput("name");
            this.validateSelect("route", "only");
            this.validateSelect("cycle", "only");
            if (this.cycle === "每周") {
                this.validateSelect("weekDays", "more");
            } else if (this.cycle === "临时") {
                this.validateTempDates();
            }
            this.validateDuration();
            return this.errForm.size === 0;
        },
        //保存任务
        saveTask() {
            if (!this.validateTask()) {
                return;
            }
            let routeName;
            for (let i = 0; i < this.routeList.length; i++) {
                if (this.routeList[i].value === this.route) {
                    routeName = this.routeList[i].label;
                }
            }
            let durations = [];
            this.durations.forEach((arr) => {
                let obj = JSON.parse(arr);
                if (this.id !== null) {
                    obj.taskId = this.id;
                }
                durations.push(obj);
            });
            let data = {
                name: this.name,
                routeId: this.route,
                routeName: routeName,
                doerUserIds: this.member,
                cycle: this.cycle,
                isEnable: this.isEnable,
                isInOrder: this.isInOrder,
                durations: durations,
                weekDays: this.weekDays,
                tempDates: this.tempDates,
                customDate: this.customDate,
                intervalDay: this.intervalDay,
            };
            let httpType = this.id !== null ? "$put" : "$post";
            let url =
                this.id !== null
                    ? `${TASK_ITEM_EDIT}/${this.currentScene}/${this.id}`
                    : `${TASK_ITEM_EDIT}/${this.currentScene}`;
            this[httpType](url, data)
                .then((res) => {
                    let msg =
                        this.id !== null ? "编辑任务成功" : "新增任务成功";
                    this.$Message.success({
                        background: true,
                        content: msg,
                    });
                    history.go(-1);
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, TASK_ITEM_EDIT);
                });
        },
        //添加日期(临时)
        addDate() {
            this.dateStatus = true;
        },
        //取消日期选择
        cancel() {
            this.dateStatus = false;
        },
        //确认日期
        confirm() {
            if (!!this.inspectDate) {
                let tempDates = [
                    ...this.tempDates,
                    formDate2(this.inspectDate),
                ];
                this.tempDates = Array.from(new Set(tempDates));
                this.validateTempDates();
            }
            this.dateStatus = false;
        },
        //添加时间段
        addTime() {
            this.modalStatus = true;
        },
        //验证是否跨天
        validateOverDay(val) {
            this.tempDatesForm.clear();
            if (this.overDay !== "") {
                this.tempDatesForm.delete("overDay");
            } else {
                this.tempDatesForm.add("overDay");
            }
            this.$forceUpdate();
        },
        //时间段改变
        validateTime(val, text) {
            if (val) {
                this.tempDatesForm.delete(text);
            } else {
                this.tempDatesForm.add(text);
            }
            this.$forceUpdate();
        },
        //验证时间数据
        validateTimeArr() {
            let hasVal = !!this.timeArr[0];
            this.validateTime(hasVal, "timeArr");
        },
        //验证时间数据
        validateStartTime() {
            let hasVal = !!this.startTime;
            this.validateTime(hasVal, "startTime");
        },
        //验证时间数据
        validateEndTime() {
            let hasVal = !!this.endTime;
            this.validateTime(hasVal, "endTime");
        },
        //验证时间段参数
        validateTempDate() {
            this.validateOverDay();
            if (this.overDay === "1") {
                this.validateStartTime();
                this.validateEndTime();
            } else {
                this.validateTimeArr();
            }
            return this.tempDatesForm.size === 0;
        },
        // 选择跨天开始日期
        selectCustomDate(e) {
            this.customDate = e;
        },
        //提交时间段表单
        handleSubmit() {
            if (this.validateTempDate()) {
                let data = {
                    start:
                        this.overDay === "1" ? this.startTime : this.timeArr[0],
                    end: this.overDay === "1" ? this.endTime : this.timeArr[1],
                    isNextDay: this.overDay === "1",
                    crossDay: this.crossDay,
                };
                let timeStr = JSON.stringify(data);
                let durationArr = [...this.durations, timeStr];
                this.durations = Array.from(new Set(durationArr));
                this.modalStatus = false;
                this.validateDuration();
            }
        },
        //关闭临时时间段弹框
        handleReset() {
            this.modalStatus = false;
        },
    },
    computed: {
        ...mapGetters(["currentScene"]),
    },
    watch: {
        modalStatus: function (val) {
            if (!val) {
                this.overDay = "";
                this.timeArr = [];
                this.startTime = "";
                this.endTime = "";
                this.tempDatesForm.clear();
            } else {
                this.overDay = "";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
