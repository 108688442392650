<template>
  <div class="manager-page">
    <router-view></router-view>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {renderPath} from '../../utils/helper'
  export default {
    created() {
      let path = this.$route.path;
      renderPath(this, path, this.menuList)
    },
    computed: {
      ...mapState({
        menuList: state => state.common.menuList,
      }),
    },
    watch: {
      '$route': function(val) {
        if (val.name === 'InspectManager') {
          renderPath(this, val.path, this.menuList)
        }
      }
    }
  }
</script>

