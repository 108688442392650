<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">消息通知</span>
            <div class="title-right">
                <Button type="primary" @click="publishNotice">发布通知</Button>
            </div>
        </div>
        <div class="notice-view">
            <div class="view-title">
                <div class="title-left">
                    <div class="left-item">
                        <span class="left-title">通知内容</span>
                        <Input
                            v-model="noticeText"
                            placeholder="请输入"
                            style="width: 300px"
                        />
                    </div>
                    <Button type="primary" ghost @click="searchNotice"
                        >查询</Button
                    >
                </div>
            </div>
            <Table
                stripe
                ref="selection"
                :columns="tableTitle"
                :data="tableList"
            >
                <template slot-scope="{ row, index }" slot="action">
                    <span class="iconfont iconxiugai"></span>
                    <span
                        class="iconfont iconsahnchufuben"
                        @click="delItem(row, index)"
                    ></span>
                </template>
            </Table>
            <div class="paging-box">
                <Page
                    :total="total"
                    :current="pageNo"
                    :page-size="pageSize"
                    @on-change="changePage"
                    @on-page-size-change="changeSize"
                    show-total
                    show-elevator
                    show-sizer
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { MSG_LIST, RELEASE_MSG } from "@/config/api";
import { formDate3, currentTimeArr } from "@/utils/helper";
export default {
    data() {
        return {
            //通知内容
            noticeText: "",
            //
            tableTitle: [
                {
                    title: "序号",
                    type: "index",
                    width: 100,
                },
                {
                    title: "通知内容",
                    key: "content",
                },
                {
                    title: "接收人",
                    key: "doerUsers",
                    tooltip: true
                },
                {
                    title: "发布时间",
                    key: "publishTime",
                },
                {
                    title: "已阅人数",
                    key: "readUserCount",
                    width: 100,
                },
            ],
            tableList: [],
            //分页数据
            pageNo: 1,
            pageSize: 10,
            total: 0,
        };
    },
    created() {
        this.searchNotice();
    },
    mounted() {},
    methods: {
        //查询通知
        searchNotice() {
            let params = {
                search: this.noticeText,
                current: this.pageNo,
                size: this.pageSize,
            };
            this.$get(`${MSG_LIST}/${this.currentScene}`, params).then(
                (res) => {
                    this.total = res.total;
                    this.tableList = res.records.map((item) => {
                        item.publishTime = item.time;
                        return item;
                    });
                }
            );
        },
        //切换页码
        changePage(val) {
            this.pageNo = val;
            this.searchNotice();
        },
        //切换每页条数
        changeSize(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.searchNotice();
        },
        //
        publishNotice() {
            this.$router.push({ path: "mesnotice-publish" });
        },
    },
    computed: {
        ...mapState({
            currentScene: (state) => state.common.currentScene,
        }),
    },
    watch: {},
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
