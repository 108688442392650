<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">{{ !!id ? '编辑人员' : '新增人员' }}</span>
    </div>
    <div class="member-edit-view">
      <Form ref="memberValidate" :model="formValidate" :rules="ruleValidate" :label-width="140">
        <FormItem label="姓名" prop="nickname">
          <Input v-model="formValidate.nickname" placeholder="请输入" style="width: 50%;" />
        </FormItem>
        <FormItem label="性别" prop="gender">
          <Select v-model="formValidate.gender" placeholder="请选择" style="width: 50%;">
            <Option v-for="item in genderList" :value="item.value" :key="item.id">{{ item.label }}</Option>
          </Select>
        </FormItem>
        <FormItem label="账号" prop="username">
          <Input v-model="formValidate.username" placeholder="请输入" style="width: 50%;" :disabled="!!id" />
        </FormItem>
        <FormItem label="密码" prop="password">
          <Input v-model="formValidate.password" disabled type="text" placeholder="请输入" style="width: 50%;" />
          <Button type="primary" ghost @click="editPsd" style="margin-left: 12px;">编辑密码</Button>
        </FormItem>
        <!--<FormItem label="部门" prop="departmentIds">-->
          <!--<Select v-model="formValidate.departmentIds" multiple  style="width: 50%;">-->
            <!--<Option v-for="item in departmentList" :value="item.value" :key="item.value">{{ item.label }}</Option>-->
          <!--</Select>-->
        <!--</FormItem>-->
        <FormItem label="角色" prop="roleIds">
          <Select v-model="formValidate.roleIds" style="width: 50%;">
            <Option v-for="item in roleList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </FormItem>
        <FormItem label="是否启用" prop="isEnable" style="width: 50%;">
          <RadioGroup v-model="formValidate.isEnable">
            <Radio :label="true">是</Radio>
            <Radio :label="false">否</Radio>
          </RadioGroup>
        </FormItem>
      </Form>
      <div class="form-item">
        <label class="item-label"></label>
        <Button type="primary" @click="handleSubmit('memberValidate')">保存</Button>
        <Button type="primary" ghost @click="cancelEdit">取消</Button>
      </div>
    </div>
    <Modal
        class="member-edit-modal"
        v-model="editStatus"
        title="编辑密码">
      <div class="form-item">
        <label class="item-label"><span class="label-vital">*</span>密码</label>
        <div class="input-box">
          <Input v-model="psd" type="password" placeholder="请输入"
                 style="width: 100%" @on-blur="blurInput('psd')" />
        </div>
        <div class="form-err" v-show="errForm.has('psd')">请输入密码</div>
      </div>
      <div class="form-item">
        <label class="item-label"><span class="label-vital">*</span>密码确认</label>
        <div class="input-box">
          <Input v-model="psdAgain" type="password" placeholder="请输入"
                 style="width: 100%" @on-blur="blurInput('psdAgain')" />
        </div>
        <div class="form-err" v-show="errForm.has('psdAgain')">请再次输入密码</div>
        <div class="form-err" v-show="errForm.has('psdErr')">两次输入密码不一致</div>
      </div>
      <div slot="footer">
        <Button type="text" @click="operatePsd('cancel')">取消</Button>
        <Button type="primary" @click="operatePsd('confirm')">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {ROLE_LIST_NOPAGE, MEMBER_ITEM_EDIT} from '../../../config/api'
  import {conversionFrom} from '../../../utils/helper'
  export default {
    data() {
      return {
        //角色id
        id: null,
        //性别列表
        genderList: [
          {label: '男', value: '男', id: 1},
          {label: '女', value: '女', id: 2},
        ],
        //角色列表
        roleList: [],
        //部门列表
        departmentList: [],
        //编辑菜单项校验数据
        formValidate: {
          nickname: '',
          gender: '',
          username: '',
          password: '',
          roleIds: '',
          // departmentIds: [],
          isEnable: true,
        },
        //菜单校验规则
        ruleValidate: {
          nickname: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ],
          gender: [
            { required: true, message: '请选择性别', trigger: 'change' },
          ],
          username: [
            { required: true, message: '请输入账号', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'change' }
          ],
          roleIds: [
            { required: true, type: 'number', message: '请选择角色', trigger: 'change' }
          ],
          // departmentIds: [
          //   { required: true, type: 'array', min: 1, message: '请选择部门', trigger: 'change' }
          // ],
          isEnable: [
            { required: true, type: 'boolean', message: '请选择是或否', trigger: 'change' }
          ]
        },
        //编辑密码状态
        editStatus: false,
        //表单密码
        formPsd: '',
        //密码
        psd: '',
        //密码确认
        psdAgain: '',
        //错误对象
        errForm: new Set()
      }
    },
    created() {
      let id = this.$route.params.id;
      this.id = id === '0' ? null : id;
      let memberMes = this.$route.query.mes;
      if (!!memberMes) {
        this.initMember(memberMes)
      }
      this.requestRoleList()
    },
    methods: {
      //请求角色列表
      requestRoleList() {
        this.$get(`${ROLE_LIST_NOPAGE}/${this.currentScene}`).then(res => {
          let roleList = [];
          res.forEach(arr => {
            roleList.push({label: arr.name, value: arr.id})
          });
          this.roleList = roleList;
        }).catch(err => {
          //异常情况
          this.$httpErr(err, ROLE_LIST_NOPAGE)
        })
      },
      //初始化成员信息
      initMember(data) {
        let mes = JSON.parse(data);
        this.formValidate.nickname = mes.nickname;
        this.formValidate.gender = mes.gender;
        this.formValidate.username = mes.username;
        //编辑人员时无法获取人员密码,则用假值(******)代替
        this.formValidate.password = '******';
        this.formValidate.roleIds = !mes.roleIds.length ? '' : Number(mes.roleIds[0]);
        this.formValidate.departmentIds = mes.departmentIds || [];
        this.formValidate.isEnable = !!mes.isEnable;
      },
      //修改密码
      editPsd() {
        this.editStatus = true;
      },
      //输入框失去焦点事件
      blurInput(name) {
        if (this[name] !== '') {
          this.errForm.delete(name)
        } else {
          this.errForm.add(name)
        }
        this.errForm.delete('psdErr');
        this.$forceUpdate()
      },
      //验证密码
      validatePsd() {
        this.blurInput('psd')
        this.blurInput('psdAgain')
        if (this.errForm.size === 0) {
          (this.psd !== this.psdAgain) && this.errForm.add('psdErr')
        }
        return (this.errForm.size === 0)
      },
      //操作密码
      operatePsd(type) {
        if (type === 'confirm') {
          if (this.validatePsd()) {
            this.formValidate.password = '******';
            this.formPsd = this.psd
          } else {
            return
          }
        }
        this.editStatus = false;
      },
      //保存用户验证
      handleSubmit(name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            //验证成功
            this.submitMember();
          }
        })
      },
      //提交成员信息
      submitMember() {
        let memberData = {...this.formValidate};
        //编辑人员时，如果密码是页面默认密码参数=>(******)，则视为不修改人员密码，删除password参数
        if (memberData.password === '******' && this.formPsd !== '') {
          memberData.password = this.formPsd;
        } else {
          delete memberData.password;
        }
        memberData.roleIds = [memberData.roleIds];
        let httpType = this.id !== null ? '$put' : '$post';
        let url = this.id !== null ? `${MEMBER_ITEM_EDIT}/${this.id}` : `${MEMBER_ITEM_EDIT}/${this.currentScene}`;
        this[httpType](url, conversionFrom(memberData)).then(res => {
          let msg = this.id !== null ? '编辑成员成功' : '新增成员成功';
          this.$Message.success({
            background: true,
            content: msg
          });
          history.go(-1)
        }).catch(err => {
          //异常情况
          this.$httpErr(err, MEMBER_ITEM_EDIT)
        })
      },
      //取消编辑/保存
      cancelEdit() {
        history.go(-1)
      },
    },
    computed: {
      ...mapState({
        currentScene: state => state.common.currentScene
      }),
    },
    watch: {
      editStatus: function(val) {
        if (!val) {
          this.psdAgain = '';
          this.psd = '';
          this.errForm.clear()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
