<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">人员查看权限管理</span>
    </div>
    <div class="authority-view">
      <div class="view-title">
        <span class="title-text">隐藏成员</span>
        <Button type="primary" @click="editHideList(0)">添加</Button>
      </div>
      <div class="hide-item" v-for="list in authorityList" :key="list.id">
        <div class="item-title">
          <span class="title-text">成员列表</span>
          <div class="btn-group">
            <Button type="primary" size="small" ghost @click="editHideList(list.id)">编辑</Button>
            <Button type="text" size="small" @click="delHideList(list.id)">删除</Button>
          </div>
        </div>
        <div class="user-list">
          <div class="user-item" v-for="user in  list.hiddenUsers" :key="user.id">
            <span class="iconfont iconyonghu"></span>
            <span class="user-name">{{ user.nickname }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {RIGHT_RULE_LIST, RIGHT_RULE_EDIT} from '../../../config/api'
  export default {
    data() {
      return {
        //权限列表
        authorityList: [],
      }
    },
    created() {
      this.requestRightList()
    },
    methods: {
      //请求人员管理列表
      requestRightList() {
        this.$get(RIGHT_RULE_LIST).then(res => {
          let authorityList = [];
          res.forEach(arr => {
            authorityList.push(arr)
          });
          this.authorityList = authorityList;
        }).catch(err => {
          //异常情况
          this.$httpErr(err, RIGHT_RULE_LIST)
        })
      },
      //编辑操作列表
      editHideList(id) {
        //id => 传0则为新增，其他传参则为编辑
        this.$router.push({path: `authority-edit/${id}`})
      },
      //删除隐藏列表
      delHideList(id) {
        this.$Modal.confirm({
          title: '提示',
          content: '<p>确定删除此这条隐藏规则吗？删除数据后不可恢复!</p>',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            this.delRequest(id)
          }
        });
      },
      //
      delRequest(id) {
        this.$delete(`${RIGHT_RULE_EDIT}/${id}`).then(res => {
          this.$Message.success({
            background: true,
            content: '删除隐藏规则成功'
          });
          this.requestRightList()
        }).catch(err => {
          //异常情况
          this.$httpErr(err, RIGHT_RULE_EDIT)
        })
      }
    },
    computed: {
      ...mapState({
        currentScene: state => state.common.currentScene
      }),
    }
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
