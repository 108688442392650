import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import httpInit from './http'
import global from './utils/global'

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';


Vue.use(ViewUI);
httpInit(Vue);
global(Vue);

// 引入 ECharts 主模块
let echarts = require('echarts/lib/echarts');
// 引入折线图/柱状图/饼图等组件
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/funnel'
// import 'echarts/lib/chart/map'
// import 'echarts/lib/chart/radar'
// 引入提示框和title组件，图例
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/legendScroll'//图例滚动
import "echarts/lib/component/markLine"
//vue全局注入echarts
Vue.prototype.$echarts = echarts;

// 错误日志提示
Vue.config.errorHandler = function (err, vm, info) {
  console.log(String.repeat('*', 10));
  console.log(err);
  console.log(info);
  console.log(String.repeat('*', 10));
};

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
