import { getToken } from '../utils/auth'
/**
 * 用户信息管理
 */
const state = {
    //用户token
    token: getToken(),
    //用户名称
    userName: '',
    //用户昵称
    nickname: '',
    //用户id
    userId: '',
    //用户角色
    userRole: '',
    //用户场景类型 0代表所有, 1代表部分场景
    sceneType: 1,
    //用户部门列表
    department: []
}

const getters = {}

const mutations = {
    //更新用户token
    UPDATE_TOKEN(state, token) {
        state.token = token
    },
    //更新用户ID
    UPDATE_USERID(state, id) {
        state.userId = id
    },
    //更新用户角色
    UPDATE_USERROLE(state, role) {
        state.userRole = role
    },
    //更新用户名
    UPDATE_USERNAME(state, name) {
        state.userName = name
    },
    //更新用户昵称
    UPDATE_NICKNAME(state, name) {
        state.nickname = name
    },
    //更新用户昵称
    UPDATE_SCENETYPE(state, type) {
        state.sceneType = type
    },
    //更新用户部门列表
    UPDATE_DEPARTMENT(state, list) {
        state.department = list
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
