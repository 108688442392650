<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">{{ id === '0' ? '新增知识库' : '编辑知识库' }}</span>
            <div class="btn-group">
                <Button type="primary" @click="saveContent">保存</Button>
            </div>
        </div>
        <div class="notice-publish-view">
            <div class="input-item">
                <label class="item-label">
                    <span class="label-vital">*</span>标题
                </label>
                <Input v-model="title" placeholder="请输入" style="width: 50%;" />
            </div>
            <div class="input-item">
                <label class="item-label">
                    <span class="label-vital">*</span>内容详情
                </label>
                <div class="editor-view" ref="contentHtml" v-if="knowledgeSrc === '02'"></div>
                <div class="editor-view" v-else>
                    <div id="toolbar-container"></div>
                    <div id="editor-container"></div>
                </div>
            </div>
            <div class="input-item">
                <label class="item-label">
                    <span class="label-vital">*</span>摘要
                </label>
                <Input v-model="knowledgeAbstract" placeholder="请输入" style="width: 50%;" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { KNOWLEDGE_LIST, KNOWLEDGE_UPLOAD, baseUrl } from "@/config/api";
import "@wangeditor/editor/dist/css/style.css";
import {
    createEditor,
    createToolbar,
    IEditorConfig,
    IDomEditor,
} from "@wangeditor/editor";
export default {
    data() {
        return {
            knowledgeId: null,
            //标题
            title: "",
            //内容
            content: "",
            // 摘要
            knowledgeAbstract: "",
            knowledgeSrc: "",
        };
    },
    created() {},
    mounted() {
        let params = this.$route.params;
        if (params.id) {
            this.queryKnowledgeDetail(params.id);
            this.knowledgeId = params.id;
        } else {
            this.createEditorFunc();
        }
    },
    methods: {
        // 查询知识库详情
        queryKnowledgeDetail(id) {
            this.$get(`${KNOWLEDGE_LIST}/${this.currentScene}/${id}`).then(
                (res) => {
                    this.title = res.title;
                    this.knowledgeAbstract = res.knowledgeAbstract;
                    this.knowledgeSrc = res.knowledgeSrc;
                    this.$nextTick(() => {
                        if (res.knowledgeSrc === "02") {
                            this.content = res.content;
                            let div = document.createElement("div");
                            div.innerHTML = res.content;
                            console.log(this.$refs.contentHtml);
                            this.$refs.contentHtml.appendChild(div);
                        } else {
                            this.createEditorFunc(res.content);
                        }
                    });
                }
            );
        },
        // 创建富文本编辑器
        createEditorFunc(html = "") {
            const editorConfig = {
                MENU_CONF: {},
            };
            const token = this.token;
            editorConfig.placeholder = "请输入内容";
            editorConfig.onChange = (editor) => {
                // 当编辑器选区、内容变化时，即触发
                // console.log("content", editor.children);
                console.log("html", editor.getHtml());
                this.content = editor.getHtml();
            };

            // 上传图片配置
            editorConfig.MENU_CONF["uploadImage"] = {
                fieldName: "file",
                // 单个文件的最大体积限制，默认为 2M
                maxFileSize: 10 * 1024 * 1024, // 1M
                server: `${baseUrl}/common/upload`,
                // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
                meta: {
                    token,
                },
                // 上传之前触发
                onBeforeUpload(file) {
                    // file 选中的文件，格式如 { key: file }
                    return file;

                    // 可以 return
                    // 1. return file 或者 new 一个 file ，接下来将上传
                    // 2. return false ，不上传这个 file
                },
                // 上传进度的回调函数
                onProgress(progress) {
                    // progress 是 0-100 的数字
                    console.log("progress", progress);
                },
                // 单个文件上传成功之后
                onSuccess(file, res) {
                    console.log(`${file.name} 上传成功`, res);
                },
                // 单个文件上传失败
                onFailed(file, res) {
                    console.log(`${file.name} 上传失败`, res);
                },
                // 上传错误，或者触发 timeout 超时
                onError(file, err, res) {
                    console.log(`${file.name} 上传出错`, err, res);
                },
                // 自定义插入图片
                customInsert(res, insertFn) {
                    // res 即服务端的返回结果
                    console.log("999999999999", res);
                    if (res.code === 0 && res.data.url) {
                        insertFn(res.data.url);
                    } else {
                        this.$Message.error(res.msg || "上传图片失败！");
                    }
                },
            };

            // 创建编辑器
            const editor = createEditor({
                html,
                selector: "#editor-container",
                config: editorConfig,
                mode: "default", // 或 'simple' 参考下文
            });
            // 创建工具栏
            const toolbar = createToolbar({
                editor,
                selector: "#toolbar-container",
                mode: "default", // 或 'simple' 参考下文
            });
        },
        //保存内容
        saveContent() {
            if (!this.title) {
                return this.$Message.error("请输入标题！");
            }
            if (!this.content) {
                return this.$Message.error("请输入内容详情！");
            }
            if (!this.knowledgeAbstract) {
                return this.$Message.error("请输入摘要！");
            }
            let formData = new FormData();
            formData.append("title", this.title);
            formData.append("content", this.content);
            formData.append("knowledgeAbstract", this.knowledgeAbstract);
            if (this.knowledgeId) {
                this.$put(
                    `${KNOWLEDGE_LIST}/${this.currentScene}/${this.knowledgeId}`,
                    formData
                ).then(() => {
                    this.$router.go(-1);
                });
            } else {
                this.$post(
                    `${KNOWLEDGE_LIST}/${this.currentScene}`,
                    formData
                ).then(() => {
                    this.$router.go(-1);
                });
            }
        },
    },
    computed: {
        ...mapGetters(["currentScene", "token"]),
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
