<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">设备类别管理</span>
      <div class="btn-group">
        <Button type="primary" @click="addCategory">新增</Button>
      </div>
    </div>
    <div class="category-view">
      <div class="view-title">
        <div class="title-left">
          <div class="left-item">
            <span class="left-title">设备类别名称</span>
            <Input v-model="categoryName" placeholder="请输入" style="width: 300px" />
          </div>
          <Button type="primary" ghost @click="searchCategory">查询</Button>
        </div>
      </div>
      <Table stripe :columns="tableTitle" :data="tableList">
        <template slot-scope="{ row, index }" slot="action">
          <span class="iconfont iconxiugai" @click="editItem(row)"></span>
          <span class="iconfont iconsahnchufuben" @click="delItem(row)"></span>
        </template>
      </Table>
      <div class="paging-box">
        <Page :current="pageNo"
              :page-size="pageSize"
              :total="total"
              @on-change="changePage"
              @on-page-size-change="changeSize"
              show-total show-elevator show-sizer />
      </div>
    </div>
    <Modal
        class="category-modal"
        v-model="modalStatus"
        title="修改设备类别">
      <div class="form-item">
        <label class="item-label"><span class="label-vital">*</span>类别名称</label>
        <div class="input-box">
          <Input v-model="formValidate.name" placeholder="请输入" @on-blur="blurInput('name')" />
        </div>
        <div class="form-err" v-show="errForm.has('name')">请输入类别名称</div>
      </div>
      <div class="form-item">
        <label class="item-label">异常标签</label>
        <div class="input-box">
          <Input v-model="formValidate.exceptionTags"
                 type="textarea" :autosize="{minRows: 3, maxRows: 6}" placeholder="请输入" />
        </div>
        <!--<div class="form-err" v-show="errForm.has('exceptionTags')">请输入异常标签</div>-->
      </div>
      <div class="form-item">
        <label class="item-label">上传icon</label>
        <div class="input-box">
          <upload-image width="120" height="120" :updateStatus="modalStatus"
                        text="自定义" :file="iconUrl"
                        @uploadEvent="uploadIcon"></upload-image>
        </div>
        <div class="icon-list">
          <img class="icon-img" :src="item.url" @click="selectIcon(item)"
               v-for="item in iconList" :key="item.id">
        </div>
        <!--<div class="form-err" v-show="errForm.has('icon')">请上传icon</div>-->
      </div>
      <!--<div class="form-item">-->
        <!--<label class="item-label"><span class="label-vital">*</span>上传图片</label>-->
        <!--<div class="input-box">-->
          <!--<upload-image width="200" height="200" text="图片" :file="formValidate.pic"-->
                        <!--@uploadEvent="uploadImage"></upload-image>-->
        <!--</div>-->
        <!--<div class="form-err" v-show="errForm.has('pic')">请上传图片</div>-->
      <!--</div>-->
      <div slot="footer">
        <Button type="text" @click="handleReset()">取消</Button>
        <Button type="primary" @click="handleSubmit()">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import UploadImage from '../../../components/UploadImage'
  import { DEVICE_TYPE_PAGE, DEVICE_TYPE_EDIT, DEVICE_TYPE_ICON } from '../../../config/api'
  export default {
    data() {
      return {
        //设备类别名称
        categoryName: '',
        //表格标题
        tableTitle: [
          {
            title: '序号',
            width: 120,
            key: 'index'
          },
          {
            title: '设备类别名称',
            key: 'name'
          },
          {
            title: '异常标签',
            key: 'exceptionTags'
          },
          {
            title: '操作',
            slot: 'action',
            width: 150,
            align: 'center'
          },
        ],
        tableList: [],
        //分页数据
        pageNo: 1,
        pageSize: 10,
        total: 0,
        //编辑类别弹框状态
        modalStatus: false,
        //icon列表
        iconList: [],
        //设备类别id
        categoryId: null,
        //类别数据表单
        formValidate: {
          //设备类别名称
          name: '',
          //异常标签
          exceptionTags: '',
          //类别icon
          icon: '',
          //设备类别图片
          pic: ''
        },
        //图标路径
        iconUrl: '',
        //错误表单
        errForm: new Set(),
      }
    },
    components: {
      UploadImage
    },
    created() {
      console.log(this.systemIcon)
      this.requestDeviceIcon()
      this.requestCategory()
    },
    methods: {
      //失去焦点 输入框验证
      blurInput(name) {
        let validateText = this.formValidate[name] === '';
        this.updateFormErr(validateText, name)
      },
      //上传验证
      uploadValidate(name) {
        let validateText = !this.formValidate[name];
        this.updateFormErr(validateText, name)
      },
      //更新表单错误信息
      updateFormErr(val, name) {
        if (val) {
          this.errForm.add(name);
        } else {
          this.errForm.delete(name);
        }
        this.$forceUpdate()
      },
      //请求系统默认icon
      requestDeviceIcon() {
        this.$get(`${DEVICE_TYPE_ICON}`, null, true).then(res => {
          this.iconList = res.map(arr => {
            arr.url = this.$fileSrc(arr.icon);
            return arr
          })
        }).catch(err => {
          //异常情况
          this.$httpErr(err, DEVICE_TYPE_ICON)
        })
      },
      //请求设备类别列表
      requestCategory() {
        let data = {
          current: this.pageNo,
          size: this.pageSize,
          search: this.categoryName
        };
        this.$get(`${DEVICE_TYPE_PAGE}/${this.currentScene}`, data).then(res => {
          this.total = res.total;
          let tableList = [];
          res.records.forEach((arr, index) => {
            arr.index = index + 1;
            tableList.push(arr)
          });
          this.tableList = tableList;
        }).catch(err => {
          //异常情况
          this.$httpErr(err, DEVICE_TYPE_PAGE)
        })
      },
      //查询设备类别
      searchCategory() {
        this.pageNo = 1;
        this.requestCategory()
      },
      //新增设备类别
      addCategory() {
        this.modalStatus = true;
        this.categoryId = null;
      },
      //删除某条数据
      delItem(data) {
        this.$Modal.confirm({
          title: '提示',
          content: '<p>确定删除此条数据</p>',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            this.delCategory(data.id)
          }
        });
      },
      //删除设备类别
      delCategory (id) {
        this.$delete(`${DEVICE_TYPE_EDIT}/${this.currentScene}/${id}`).then(res => {
          this.$Message.success({
            background: true,
            content: '删除设备类别成功'
          });
          this.requestCategory()
        }).catch(err => {
          //异常情况
          this.$httpErr(err, DEVICE_TYPE_EDIT)
        })
      },
      //编辑设备类别项
      editItem(data) {
        console.log(data.icon)
        this.categoryId = data.id;
        this.formValidate.name = data.name;
        this.formValidate.exceptionTags = data.exceptionTags;
        this.formValidate.icon = !!data.icon ? data.icon : '';
        this.iconUrl = !!data.icon ? data.icon : '';
        // this.formValidate.pic = this.$fileSrc(data.pic);
        this.modalStatus = true;
      },
      //验证提交表单
      validateForm() {
        this.blurInput('name')
        // this.blurInput('exceptionTags')
        // this.uploadValidate('icon')
        // this.uploadValidate('pic')
        return (this.errForm.size === 0)
      },
      //处理表单验证
      handleSubmit() {
        if (this.validateForm()) {
          //验证成功
          this.modalStatus = false;
          let data = {...this.formValidate};
          if (this.iconUrl !== '') {
            data['icon-path'] = this.iconUrl;
          }
          this.submitCategoryData(data)
        }
      },
      //提交类别数据
      submitCategoryData(data) {
        let formData = new FormData();
        for (let val in data) {
          formData.append(val, data[val]);
        }
        let httpType = this.categoryId !== null ? '$put' : '$post';
        let url = this.categoryId !== null ? `${DEVICE_TYPE_EDIT}/${this.currentScene}/${this.categoryId}` :
          `${DEVICE_TYPE_EDIT}/${this.currentScene}`;
        this[httpType](url, formData).then(res => {
          let msg = this.categoryId !== null ? '编辑设备类别成功' : '新增设备类别成功';
          this.$Message.success({
            background: true,
            content: msg
          });
          if (this.categoryId === null) {
            this.pageNo = 1;
          }
          this.requestCategory()
        }).catch(err => {
          //异常情况
          this.$httpErr(err, DEVICE_TYPE_EDIT)
        })
      },
      //表单数据回撤
      handleReset() {
        this.modalStatus = false;
      },
      //上传icon
      uploadIcon(file) {
        this.formValidate.icon = file;
        this.iconUrl = '';
        // this.uploadValidate('icon')
      },
      //选择icon
      selectIcon(item) {
        this.formValidate.icon = item.icon;
        this.iconUrl = item.icon;
      },
      //上传图片
      uploadImage(file) {
        this.formValidate.pic = file;
        // this.uploadValidate('pic')
      },
      //切换页码
      changePage(val) {
        this.pageNo = val;
        this.requestCategory()
      },
      //切换每页条数
      changeSize(val) {
        this.pageNo = 1;
        this.pageSize = val;
        this.requestCategory()
      }
    },
    computed: {
      ...mapState({
        currentScene: state => state.common.currentScene,
        systemIcon: state => state.common.systemIcon,
      }),
    },
    watch: {
      'modalStatus': function(val) {
        if (!val) {
          //弹框关闭,数据重置
          this.errForm.clear();
          this.iconUrl = '';
          this.formValidate = {
            name: '',
            exceptionTags: '',
            icon: '',
            pic: '',
          };
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
