//配置角色权限token
const TokenKey = '_token';

export function getToken() {
  return JSON.parse(localStorage.getItem(TokenKey) || "{}").token || ""
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, JSON.stringify({token: token}))
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}


//配置角色权限场景
const SceneKey = '_scene';

export function getScene() {
  return JSON.parse(localStorage.getItem(SceneKey) || "{}").scene || ""
}

export function setScene(scene) {
  return localStorage.setItem(SceneKey, JSON.stringify({scene: scene}))
}

export function removeScene() {
  return localStorage.removeItem(SceneKey)
}