<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">任务详情</span>
    </div>
    <div class="task-detail-view">
      <Table :columns="detailTile" :data="detailList">
        <template slot-scope="{ row, index }" slot="status">
          <i-switch v-model="row.isEnable" disabled></i-switch>
        </template>
      </Table>
    </div>
    <div class="task-detail-view">
      <div class="view-header">历史记录</div>
      <div class="view-title">
        <div class="title-left">
          <div class="left-item">
            <span class="left-title">巡检时间</span>
            <DatePicker  :value="inspectTime" format="yyyy-MM-dd HH:mm" @on-change="updateTaskDate"
                         type="datetimerange" :clearable="false" :editable="false"
                        split-panels placeholder="请选择日期范围" style="width: 280px"></DatePicker>
          </div>
          <Button type="primary" @click="requestChildTask">查询</Button>
        </div>
      </div>
      <Table :columns="tableTitle" :data="tableList"></Table>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import { TASK_CHILD_LIST_NOPAGE } from '../../../config/api'
  import {formDate3, formDate4, dateConversion, childTaskStatus, currentTimeArr} from '../../../utils/helper'
  export default {
    data() {
      return {
        //任务id
        id: null,
        //巡检时间
        inspectTime: [],
        //详情列表
        detailTile: [
          {
            title: '任务名称',
            key: 'name',
          },
          {
            title: '巡检路线',
            key: 'routeName',
          },
          {
            title: '执行人',
            key: 'person',
          },
          {
            title: '巡检频率',
            key: 'period',
            width: 100
          },
          {
            title: '巡检时段',
            key: 'timeStep',
          },
          {
            title: '创建时间',
            key: 'createTime',
          },
          {
            title: '状态',
            slot: 'status',
            width: 100
          },
        ],
        detailList: [],
        //表格头
        tableTitle: [
          {
            title: '序号',
            key: 'index',
            width: 80,
          },
          {
            title: '任务时间',
            key: 'taskTime'
          },
          {
            title: '执行人',
            key: 'doPersons'
          },
          {
            title: '巡检状态',
            key: 'taskStatus'
          },
        ],
        tableList: [],
      }
    },
    created() {
      //初始化日期数据
      this.inspectTime = currentTimeArr();
      this.id = this.$route.query.id;
      let mes = this.$route.query.mes;
      this.initTask(mes)
      this.requestChildTask()
    },
    methods: {
      //更新巡检时间
      updateTaskDate(val) {
        this.inspectTime = val[0] === '' ? currentTimeArr() : val;
      },
      //初始化任务详情
      initTask(mes) {
        let data = JSON.parse(mes);
        let timeStep = '';
        data.durations.forEach(arr => {
          let timeStr = `${arr.start}-${arr.end}${arr.isNextDay ? '(跨天)' : ''}`;
          timeStep += (timeStep === '' ? timeStr : `,${timeStr}`)
        });
        let obj = {
          name: data.name,
          routeName: data.routeName,
          person: data.person,
          period: data.period,
          timeStep: timeStep,
          createTime: data.createTime,
          isEnable: data.isEnable
        };
        this.detailList = [obj];
      },
      //请求子任务列表
      requestChildTask() {
        let data = {};
        if (this.inspectTime.length !== 0) {
          data['start-time'] = this.inspectTime[0] === '' ? null : formDate3(this.inspectTime[0]);
          data['end-time'] = this.inspectTime[1] === '' ? null : formDate3(this.inspectTime[1]);
        }
        this.$get(`${TASK_CHILD_LIST_NOPAGE}/${this.currentScene}/${this.id}`, data).then(res => {
          let tableList = [];
          res.forEach((arr, index) => {
            arr.index = index + 1;
            arr.taskTime = `${formDate4(arr.iptStartTime)} - ${formDate4(arr.iptEndTime)}`;
            arr.taskStatus = childTaskStatus[arr.status];
            arr.doPersons = '';
            arr.doerUsers.forEach(item => {
              arr.doPersons += (arr.doPersons === '' ? item.nickname : `,${item.nickname}`);
            });
            tableList.push(arr)
          });
          this.tableList = tableList;
        }).catch(err => {
          //异常情况
          this.$httpErr(err, TASK_CHILD_LIST_NOPAGE)
        })
      },
    },
    computed: {
      ...mapGetters([
        'currentScene'
      ]),
    },
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
