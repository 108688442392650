<template>
  <div class="upload-image">
    <div class="image-text" v-show="!!imageSrc || !!file">
      <img :src="imageSrc" :width="width" :height="height" v-if="!!imageSrc" />
      <img :src="imgPath" :width="width" :height="height" v-else />
    </div>
    <div class="upload-btn">
      <input class="sel-input-file" type="file" accept="image/*" @change="changeImage($event)"/>
      <Button icon="ios-cloud-upload-outline">{{ text || '上传' }}</Button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        //图片资源
        imageSrc: '',
        //图片路径
        imgPath: ''
      }
    },
    props: ['width', 'height', 'text', 'file', 'updateStatus'],
    mounted() {
      if (this.file) {
        this.imgPath = this.$fileSrc(this.file)
      }
    },
    methods: {
      //重新上传
      changeImage(event) {
        let file = event.target.files[0];
        if (file) {
          //设置文件文件流
          let reader = new FileReader();
          reader.readAsDataURL(file);
          //文件加载完毕后提取数据
          reader.onload = (e) => {
            console.log('上传图片大小=>',reader.result.length,'B')
            //上传图片最大值(单位字节)（ 1 M = 1048576 B ）超过1M上传失败
            if (reader.result.length > 1048000) {
              this.$Message.error('上传图片不能超过1M');
              return
            }
            this.$emit('uploadEvent', file)
            // 截取base64码部分 页面展示文件内容
            this.imageSrc = reader.result;
            //上传完成后清空input上传内容
            event.target.value = '';
          }
        }
      }
    },
    watch: {
      'updateStatus': function(val) {
        if (!val) {
          this.imageSrc = '';
        }
      },
      'file': function(val) {
        if (!!val) {
          this.imageSrc = '';
          this.imgPath = this.$fileSrc(val)
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
