
import axios from 'axios';
import store from '../store/index';
import { getToken } from '../utils/auth'
import { baseUrl } from "@/config/api";

// create an axios instance
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 180000, // request timeout (180000 => 3分钟)
  // headers: {  // request no cache
  //   'Cache-Control': 'no-cache',
  //   'Pragma': 'no-cache'
  // }
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['token'] = getToken()
      if (config.params) {
        config.params['token'] = getToken()
      } else {
        config = {
          ...config,
          params: {
            token: getToken()
          }
        }
      }
    }
    // console.log('===========', config)
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  res => {
    if (res.status >= 200 && res.status < 300) {
      return res
    }
    return Promise.reject(res)
  },
  err => {
    // 网络异常
    return Promise.reject(err)
  }
);

/**
 * promise封装axios请求结果
 * _options => 请求接口参数
 * loading => 添加内容页面 loading标志 (默认添加, 传真值则不展示)
 **/
const http = function (_options, loading) {
  if (!loading) {
    store.getters.showLoading || store.commit('common/UPDATE_LOADING', true)
  }
  return new Promise((resolve, reject) => {
    //非本地开发环添加前缀 /api
    // if (process.env.NODE_ENV !== 'development') {
    //   _options.url = `/api${_options.url}`;
    // }
    if (process.env.NODE_ENV !== 'development') {
      _options.url = `${baseUrl}${_options.url}`;
      // _options.url = `https://xj.huixuniot.com/api${_options.url}`
    }
    // console.log('_options', _options.url)
    service(_options).then(res => {
      if (!loading) {
        store.getters.showLoading && store.commit('common/UPDATE_LOADING', false)
      }
      if (res.data.code === 0) {
        resolve(res.data.data)
      } else if (_options.responseType === 'blob') {
        //此分支为下载(res.data)为blob对象
        resolve(res.data)
      } else {
        reject(res.data)
      }
    }).catch(err => {
      //网络异常/请求超时
      if (!loading) {
        store.getters.showLoading && store.commit('common/UPDATE_LOADING', false)
      }
      reject(err)
    })
  })
};

/**
 * 接口请求方式封装
 * url => 接口请求url
 * data => 接口传参
 * loading => 添加内容 loading标志 (默认添加, 传真值则不展示)
 **/

const api = {
  post(url, data, loading) {
    return http({
      url: url,
      method: "post",
      data: data,
    }, loading)
  },
  get(url, data, loading) {
    return http({
      url: url,
      method: "get",
      params: data,
    }, loading)
  },
  delete(url, data, loading) {
    return http({
      url: url,
      method: "delete",
      data: data,
      params: data
    }, loading)
  },
  put(url, data, loading) {
    return http({
      url: url,
      method: "put",
      data: data
    }, loading)
  },
  getFile(url, data, loading) {
    return http({
      url: url,
      method: "get",
      params: data,
      responseType: 'blob'
    }, loading)
  }
};

export default api

