
//头部header 父路由=>子路由关联配置
export const routeConfig = {
	inspectmanager: [
		{ name: '设备管理', path: 'device' },
		{ name: '巡检点管理', path: 'locale' },
		{ name: '巡检路线', path: 'way' },
		{ name: '巡检任务', path: 'task' },
		{ name: '巡检报告', path: 'report' },
	],
	abnormaldevicemanager: [
		{ name: '异常设备', path: 'abnormaldevice' },
	],
	mesnoticemanager: [
		{ name: '消息通知', path: 'noticehome' },
	],
	knowledgebasemanager: [
		{ name: '知识库管理', path: 'knowledgemanager' },
	],
	repairManager: [
		{ name: '报修管理', path: 'repairManager' }
	],
	basicconfigmanager: [
		{ name: '组织架构管理', path: 'framework' },
		{ name: '人员管理', path: 'member' },
		{ name: '系统设置', path: 'systemsetting' },
	],
	platformmanager: [
		{ name: '角色管理', path: 'role' },
		{ name: '人员查看权限管理', path: 'authority' },
		{ name: '数据同步管理', path: 'data' },
	],
};

/***
 * 页面对应的配置参数
 * name => 页面名称
 * parent => 父路由
 * root => 根路由
 ***/
export const pageConfig = {
	//巡检管理模块
	'inspectmanager': {
		name: '巡检管理',
		parent: 'page',
		root: 'page'
	},
	'device': {
		name: '设备管理',
		parent: 'inspectmanager',
		root: 'page'
	},
	'device-deploy': {
		name: '部署设备',
		parent: 'device',
		root: 'page'
	},
	'device-category': {
		name: '设备类别管理',
		parent: 'device',
		root: 'page'
	},
	'device-import': {
		name: '导入设备',
		parent: 'device',
		root: 'page'
	},
	'locale': {
		name: '巡检点管理',
		parent: 'inspectmanager',
		root: 'page'
	},
	'locale-deploy': {
		name: '巡检点部署',
		parent: 'locale',
		root: 'page'
	},
	'locale-relatedevice': {
		name: '关联设备',
		parent: 'locale',
		root: 'page'
	},
	'way': {
		name: '巡检路线',
		parent: 'inspectmanager',
		root: 'page'
	},
	'way-edit': {
		name: '巡检路线',
		parent: 'way',
		root: 'page'
	},
	'way-show': {
		name: '查看巡检路线',
		parent: 'way',
		root: 'page'
	},
	'task': {
		name: '巡检任务',
		parent: 'inspectmanager',
		root: 'page'
	},
	'task-edit': {
		name: '巡检任务',
		parent: 'task',
		root: 'page'
	},
	'task-detail': {
		name: '巡检任务详情',
		parent: 'task',
		root: 'page'
	},
	'report': {
		name: '巡检报告',
		parent: 'inspectmanager',
		root: 'page'
	},
	'report-detail': {
		name: '查看巡检报告',
		parent: 'report',
		root: 'page'
	},
	'report-abnormal': {
		name: '异常项',
		parent: 'report',
		root: 'page'
	},
	//报修管理模块
	'repairManager': {
		name: '报修管理',
		parent: 'page',
		root: 'page'
	},
	'repairOrder': {
		name: '报修工单',
		parent: 'repairManager',
		root: 'page'
	},
	//异常设备模块
	'abnormaldevicemanager': {
		name: '异常设备管理',
		parent: 'page',
		root: 'page'
	},
	//异常设备页面
	'abnormaldevice': {
		name: '异常设备',
		parent: 'abnormaldevicemanager',
		root: 'page'
	},
	//消息通知模块
	'mesnoticemanager': {
		name: '消息通知管理',
		parent: 'page',
		root: 'page'
	},
	'mesnotice': {
		name: '消息通知',
		parent: 'mesnoticemanager',
		root: 'page'
	},
	'mesnotice-publish': {
		name: '发布通知',
		parent: 'mesnoticemanager',
		root: 'page'
	},
	//知识库模块
	'knowledgebasemanager': {
		name: '知识库管理',
		parent: 'page',
		root: 'page'
	},
	'knowledgebase': {
		name: '知识库',
		parent: 'knowledgebasemanager',
		root: 'page'
	},
	'knowledgebase-edit': {
		name: '知识库',
		parent: 'knowledgebasemanager',
		root: 'page'
	},
	//基础配置模块
	'basicconfigmanager': {
		name: '基础配置管理',
		parent: 'page',
		root: 'page'
	},
	'framework': {
		name: '组织架构管理',
		parent: 'basicconfigmanager',
		root: 'page'
	},
	'member': {
		name: '人员管理',
		parent: 'basicconfigmanager',
		root: 'page'
	},
	'member-edit': {
		name: '人员',
		parent: 'basicconfigmanager',
		root: 'page'
	},
	'systemsetting': {
		name: '系统设置',
		parent: 'basicconfigmanager',
		root: 'page'
	},
	'wechatOfficial': {
		name: '巡检公众号',
		parent: 'basicconfigmanager',
		root: 'page'
	},
	//平台管理模块
	'platformmanager': {
		name: '平台管理',
		parent: 'page',
		root: 'page'
	},
	'role': {
		name: '角色管理',
		parent: 'platformmanager',
		root: 'page'
	},
	'role-edit': {
		name: '角色',
		parent: 'platformmanager',
		root: 'page'
	},
	'authority': {
		name: '人员查看权限管理',
		parent: 'platformmanager',
		root: 'page'
	},
	'authority-edit': {
		name: '隐藏成员',
		parent: 'platformmanager',
		root: 'page'
	},
	'data': {
		name: '数据同步管理',
		parent: 'platformmanager',
		root: 'page'
	},
	'menu': {
		name: '菜单管理',
		parent: 'platformmanager',
		root: 'page'
	},
	'sceneValiditySetting': {
		name: '场景有效期管理',
		parent: 'platformmanager',
		root: 'page'
	}
};