<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">{{ !!id ? '编辑巡检路线' : '新增巡检路线' }}</span>
      <div class="title-right">
        <Button type="primary" @click="submitRoute">保存</Button>
      </div>
    </div>
    <div class="way-edit-view">
      <!--<div class="input-item">-->
        <!--<label class="item-label"><span class="label-vital">*</span>巡检路线名称</label>-->
        <!--<Input v-model="wayName" placeholder="请输入" style="width: 50%" />-->
      <!--</div>-->
      <div class="form-item">
        <label class="item-label"><span class="label-vital">*</span>巡检路线名称</label>
        <div class="input-box">
          <Input v-model="wayName" maxlength="50" placeholder="请输入" style="width: 50%" @on-blur="blurInput('wayName')" />
        </div>
        <div class="form-err" v-show="errForm.has('wayName')">请输入名称</div>
      </div>
      <div class="locale-box">
        <div class="select-locale">
          <div class="select-title">已选巡检点</div>
          <div class="select-table">
            <div class="table-title">
              <div class="title-item">
                <span class="item-label">巡检点名称/位置</span>
                <Input placeholder="请输入" width="200px;" v-model="selectedRoutes" />
              </div>
              <div class="title-item">
                <span class="item-label label-center">楼层</span>
                <Select v-model="floorId" placeholder="请选择楼层">
                  <Option :value="-1">全部</Option>
                  <Option v-for="item in mapFloor" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </div>
              <div class="title-item"></div>
              <div class="title-item">
                <Button type="primary" ghost @click="searchSelected">查询</Button>
              </div>
            </div>
            <Table stripe ref="selection" height="400" :columns="selectedTitle" type="index"
                   @on-selection-change="selectChange" :data="selectedSearch">
              <template slot-scope="{ row, index }" slot="order">
                <span v-show="orderId === null" class="action-item" @click="orderItem(row)">移动到</span>
                <span v-show="orderId === row.id" class="action-cancel" @click="orderItem(null)">取消</span>
                <span v-show="orderId !== null && orderId !== row.id"
                      class="action-item" @click="insertItem(row)">移至此点后</span>
              </template>
            </Table>
            <div class="table-total">总计{{ selectedNum }}条</div>
          </div>
        </div>
        <div class="local-icon">
           <span class="iconfont iconxiangyou" @click="renderSelected('selected')"
                 :class="{'icon-false': !selectedChecked.length}"></span>
          <span class="iconfont iconxiangzuo" @click="renderSelected('unselected')"
          :class="{'icon-false': !unselectedChecked.length}"></span>
        </div>
        <div class="select-locale">
          <div class="select-title">未选巡检点</div>
          <div class="select-table">
            <div class="table-title">
              <div class="title-item">
                <span class="item-label">巡检点名称/位置</span>
                <Input placeholder="请输入" v-model="unselectedRoutes" />
              </div>
              <div class="title-item">
                <span class="item-label label-center">楼层</span>
                <Select v-model="unSelectedFloorId" placeholder="请选择楼层">
                  <Option :value="-1">全部</Option>
                  <Option v-for="item in mapFloor" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </div>
              <div class="title-item"></div>
              <div class="title-item">
                <Button type="primary" ghost  @click="searchUnselected">查询</Button>
              </div>
            </div>
            <Table stripe ref="selection" height="400" :columns="unselectedTitle" type="index"
                   @on-selection-change="unselectedChange" :data="unselectedSearch"></Table>
            <div class="table-total">总计{{ unselectedNum }}条</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import { LOCALE_LIST_NOPAGE, ROUTE_RELATION_POINT, ROUTE_LINE_EDIT } from '../../../config/api'
  import {conversionFrom, copyObj} from '../../../utils/helper'
  export default {
    data() {
      return {
        //规则id
        id: null,
        //楼层id
        floorId: -1,
        unSelectedFloorId: -1,
        //路线名称
        wayName: '',
        //巡检点列表
        localsList: [],
        //已选巡检点列表id
        selectedIds: [],
        //已选巡检点列表checked数据ids
        selectedChecked: [],
        //已选巡检点搜索
        selectedRoutes: '',
        //已选巡检点搜索列表
        selectedSearch: [],
        //已选巡检点数量
        selectedNum: 0,
        //已选巡检点标题列表
        selectedTitle: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '序号',
            key: 'index',
            width: 70,
          },
          {
            title: '巡检点名称',
            key: 'name'
          },
          {
            title: '位置',
            key: 'location'
          },
          {
            title: '楼层',
            key: 'floorName',
            width: 80,
          },
          {
            title: '排序',
            slot: 'order',
            width: 110,
          },
        ],
        selectedList: [],
        //未选巡检点列表checked数据ids
        unselectedChecked: [],
        //未选巡检点搜索
        unselectedRoutes: '',
        //未选巡检点搜索列表
        unselectedSearch: [],
        //未选巡检点数量
        unselectedNum: 0,
        //未选巡检点标题列表
        unselectedTitle: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '序号',
            key: 'index',
            width: 70,
          },
          {
            title: '巡检点名称',
            key: 'name'
          },
          {
            title: '位置',
            key: 'location'
          },
          {
            title: '楼层',
            key: 'floorName',
            width: 80,
          },
        ],
        unselectedList: [],
        //验证表单
        errForm: new Set(),
        //排序操作ID
        orderId: null,
        //排序对象
        orderObj: null
      }
    },
    created() {
      let id = this.$route.params.id;
      this.id = id === '0' ? null : id;
      if (this.id !== null) {
        this.wayName = this.$route.query.name;
      }
      this.requestAllLocals()
    },
    methods: {
      //请求所有巡检点接口
      requestAllLocals() {
        this.$store.commit('common/UPDATE_LOADING', true)
        Promise.all([this.requestRelatedLocals(), this.requestLocals()]).then(res => {
          this.$store.commit('common/UPDATE_LOADING', false)
          this.updateRouteLocal()
        }).catch(err => {
          this.$store.commit('common/UPDATE_LOADING', false)
          console.log(err)
        })
      },
      //请求巡检路线已关联巡检点
      requestRelatedLocals() {
        if (this.id !== null) {
          return new Promise((resolve, reject) => {
            this.$get(`${ROUTE_RELATION_POINT}/${this.currentScene}/${this.id}`, null, true).then(res => {
              if (res !== null) {
                res.forEach(arr => {
                  this.selectedIds.push(arr.id)
                });
              }
              resolve(1)
            }).catch(err => {
              reject(1)
              //异常情况
              this.$httpErr(err, ROUTE_RELATION_POINT)
            })
          })
        } else {
          return Promise.resolve(1)
        }
      },
      //请求巡检点列表
      requestLocals() {
        return new Promise((resolve, reject) => {
          this.$get(`${LOCALE_LIST_NOPAGE}/${this.currentScene}`, null, true).then(res => {
            if (res !== null) {
              this.localsList = res;
            }
            resolve(2)
          }).catch(err => {
            reject(2)
            //异常情况
            this.$httpErr(err, LOCALE_LIST_NOPAGE)
          })
        })
      },
      //更新巡检路线巡检点
      updateRouteLocal() {
        let selectedList = [];
        let selectedSearch = [];
        let unselectedSearch = [];
        let unselectedList = [];
        this.localsList.forEach(arr => {
          if (this.selectedIds.includes(arr.id)) {
            let newObj = copyObj(arr);
            if (this.selectedChecked.includes(arr.id)) {
              newObj._checked = true;
            }
            selectedList.push(copyObj(arr))
            selectedSearch.push(newObj)
          } else {
            let newObj = copyObj(arr);
            if (this.unselectedChecked.includes(arr.id)) {
              newObj._checked = true;
            }
            unselectedSearch.push(copyObj(arr))
            unselectedList.push(newObj)
          }
        });
        this.selectedList = this.decorateList(selectedList);
        this.selectedSearch = this.decorateList(selectedSearch);
        this.unselectedList = this.decorateList(unselectedList);
        this.unselectedSearch = this.decorateList(unselectedSearch);
        this.selectedNum = this.selectedSearch.length;
        this.unselectedNum = this.unselectedSearch.length;
      },
      //装饰数据
      decorateList(data) {
        data.map((arr, index) => {
          arr.index = index + 1;
          return arr
        });
        return data;
      },
      //失去焦点
      blurInput(name) {
        let validateText = this[name] === '';
        if (validateText) {
          this.errForm.add(name);
        } else {
          this.errForm.delete(name);
        }
        this.$forceUpdate()
      },
      //查询巡检点
      searchSelected() {
        let selectedSearch = [];
        this.selectedChecked = [];
        this.selectedList.forEach(arr => {
          let searchText = arr.name.includes(this.selectedRoutes) || arr.location.includes(this.selectedRoutes);
          let floorText = this.floorId === -1 ? true : (arr.floorId === this.floorId);
          if (searchText && floorText) {
            selectedSearch.push(copyObj(arr))
          }
        });
        this.selectedSearch = this.decorateList(selectedSearch);
      },
      //查询未选择巡检点
      searchUnselected() {
        let unselectedSearch = [];
        this.unselectedChecked = [];
        this.unselectedList.forEach(arr => {
          let searchText = arr.name.includes(this.unselectedRoutes) || arr.location.includes(this.unselectedRoutes);
          let floorText = this.unSelectedFloorId === -1 ? true : (arr.floorId === this.unSelectedFloorId);
          if (searchText && floorText) {
            unselectedSearch.push(copyObj(arr))
          }
        });
        this.unselectedSearch = this.decorateList(unselectedSearch);
      },
      //渲染选择巡检点
      renderSelected(val) {
        if (val === 'selected' && this.selectedChecked.length !== 0) {
          this.orderItem(null)
          //已选巡检点删除
          let newSelectedIds = [];
          this.selectedIds.forEach(arr => {
            if (!this.selectedChecked.includes(arr)) {
              newSelectedIds.push(arr)
            }
          });
          this.selectedIds = newSelectedIds;
          this.selectedChecked = [];
          this.updateRouteLocal()
        } else if (val === 'unselected' && this.unselectedChecked.length !== 0) {
          //已选巡检点添加
          this.selectedIds =  this.selectedIds.concat(this.unselectedChecked)
          this.unselectedChecked = [];
          this.updateRouteLocal()
        }
      },
      //已选列表改变
      selectChange(selection) {
        let ids = [];
        selection.forEach(arr => {
          ids.push(arr.id)
        });
        this.selectedChecked = ids;
      },
      //为选列表改变
      unselectedChange(selection) {
        let ids = [];
        selection.forEach(arr => {
          ids.push(arr.id)
        });
        this.unselectedChecked = ids;
      },
      //验证表单
      validateForm() {
        this.blurInput('wayName')
        return (this.errForm.size === 0)
      },
      //提交巡检路线
      submitRoute() {
        if (!this.validateForm()) {
          return
        }
        let data = {
          name: this.wayName,
          pointIds: this.selectedIds
        };
        let httpType = this.id !== null ? '$put' : '$post';
        let url = this.id !== null ? `${ROUTE_LINE_EDIT}/${this.currentScene}/${this.id}` :
          `${ROUTE_LINE_EDIT}/${this.currentScene}`;
        this[httpType](url, conversionFrom(data)).then(res => {
          let msg = this.id !== null ? '编辑路线成功' : '新增路线成功';
          this.$Message.success({
            background: true,
            content: msg
          });
          history.go(-1)
        }).catch(err => {
          //异常情况
          this.$httpErr(err, ROUTE_LINE_EDIT)
        })
      },
      //排序数据
      orderItem(data) {
        this.orderId = data === null ? null : data.id;
        this.orderObj = data;
      },
      //排序插入数据
      insertItem(data) {
        let selectedList = [], selectedIds = [];
        this.selectedList.forEach(list => {
          if (list.id !== this.orderId) {
            selectedList.push(copyObj(list))
            selectedIds.push(list.id)
          }
          if (list.id === data.id) {
            selectedList.push(copyObj(this.orderObj))
            selectedIds.push(this.orderId)
          }
        });
        this.selectedIds = selectedIds;
        this.selectedList = this.decorateList(selectedList);
        this.searchSelected()
        this.orderItem(null)
      }
    },
    computed: {
      ...mapGetters([
        'currentScene',
        'mapFloor'
      ]),
    }
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
