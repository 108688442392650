<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">{{ reportName }}</span>
        </div>
        <div class="report-abnormal-view">
            <Table stripe ref="selection" :columns="tableTitle" :data="tableList">
                <template slot-scope="{ row, index }" slot="comment">
                    <span class="icon-text" @click="requestAbnormalDetail(row)">{{ row.comment }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <Button
                        type="primary"
                        size="small"
                        v-show="row.progress !== '已处理'"
                        @click="dealErr(row)"
                    >处理</Button>
                    <span v-show="row.progress === '已处理'">已处理</span>
                </template>
            </Table>
            <div class="paging-box">
                <Page
                    :current="pageNo"
                    :page-size="pageSize"
                    :total="total"
                    @on-change="changePage"
                    @on-page-size-change="changeSize"
                    show-total
                    show-elevator
                    show-sizer
                />
            </div>
        </div>
        <Modal v-model="modalStatus" title="异常设备处理">
            <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100">
                <FormItem label="处理进度" prop="process">
                    <Select v-model="formValidate.progress" style="width: 100%;">
                        <Option
                            v-for="item in processList"
                            :value="item.value"
                            :key="item.id"
                        >{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="备注" prop="comment">
                    <Input
                        v-model="formValidate.comment"
                        type="textarea"
                        :autosize="{minRows: 3, maxRows: 5}"
                        placeholder="请输入"
                    />
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" @click="handleReset()">取消</Button>
                <Button
                    type="primary"
                    :loading="dealLoading"
                    @click="handleSubmit('formValidate')"
                >确认</Button>
            </div>
        </Modal>
        <Modal footer-hide width="720px;" v-model="detailModal" title="异常设备详情">
            <div class="detail-item">
                <span class="detail-title">设备位置:</span>
                <span class="detail-text">{{ errLoc }}</span>
            </div>
            <div class="detail-item">
                <span class="detail-title">异常描述:</span>
                <span class="detail-text">{{ errDesc }}</span>
            </div>
            <div class="detail-item">
                <span class="detail-title">图片:</span>
                <div class="image-box">
                    <img :src="img" v-for="(img, index) in errPics" :key="index" class="image-item" />
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    REPORT_ABNORMAL_DEVICE,
    ABNORMAL_DEVICE_HANDLE,
    ABNORMAL_DEVICE_DETAIL,
} from "../../../config/api";
import { dateConversion, conversionFrom } from "../../../utils/helper";
export default {
    data() {
        return {
            //报告id
            reportId: null,
            //报告名称
            reportName: "",
            //异常标题、列表
            tableTitle: [
                {
                    title: "序号",
                    key: "index",
                    width: 70,
                },
                {
                    title: "设备名称",
                    key: "deviceName",
                },
                {
                    title: "设备编号",
                    key: "deviceNumber",
                },
                {
                    title: "上报人",
                    key: "reporter",
                },
                {
                    title: "上报时间",
                    key: "reportTime",
                },
                {
                    title: "设备类型",
                    key: "deviceTypeText",
                },
                {
                    title: "问题描述",
                    key: "comment",
                    slot: "comment",
                },
                {
                    title: "处理进度",
                    key: "progress",
                },
                {
                    title: "处理时间",
                    key: "htime",
                },
                {
                    title: "处理备注",
                    key: "hcomment",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 100,
                },
            ],
            tableList: [],
            //分页数据
            pageNo: 1,
            pageSize: 10,
            total: 0,
            //处理异常id
            dealId: "",
            //处理弹框状态
            modalStatus: false,
            //处理等待状态
            dealLoading: false,
            //状态列表
            processList: [
                { label: "未处理", value: "未处理", id: 0 },
                { label: "处理中", value: "处理中", id: 1 },
                { label: "已处理", value: "已处理", id: 2 },
                { label: "转报修", value: "转报修", id: 3 },
            ],
            //异常处理表单
            formValidate: {
                progress: "",
                comment: "",
            },
            ruleValidate: {
                progress: [
                    {
                        required: true,
                        message: "请选择进度",
                        trigger: "change",
                    },
                ],
                comment: [
                    { required: true, message: "请输入备注", trigger: "blur" },
                ],
            },
            //异常详情弹框状态
            detailModal: false,
            //设备位置
            errLoc: "",
            //异常描述
            errDesc: "",
            //异常图片
            errPics: [],
        };
    },
    created() {
        this.reportId = this.$route.query.id;
        this.reportName = this.$route.query.name;
        this.requestAbnormal();
    },
    methods: {
        //切换页码
        changePage(val) {
            this.pageNo = val;
            this.requestAbnormal();
        },
        //切换每页条数
        changeSize(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.requestAbnormal();
        },
        //请求异常列表
        requestAbnormal() {
            let data = {
                current: this.pageNo,
                size: this.pageSize,
            };
            this.$get(
                `${REPORT_ABNORMAL_DEVICE}/${this.currentScene}/${this.reportId}`,
                data
            )
                .then((res) => {
                    this.total = res.total;
                    let tableList = [];
                    res.records.forEach((arr, index) => {
                        arr.index = index + 1;
                        arr.deviceName = arr.device.name;
                        arr.deviceNumber = arr.device.number;
                        arr.deviceTypeText = arr.deviceType.name;
                        let reporter = "";
                        arr.realDoerUsers.forEach((user) => {
                            reporter +=
                                reporter === ""
                                    ? user.nickname
                                    : `,${user.nickname}`;
                        });
                        arr.reporter = reporter;
                        arr.reportTime = arr.realReportTime;
                        tableList.push(arr);
                    });
                    this.tableList = tableList;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, REPORT_ABNORMAL_DEVICE);
                });
        },
        //处理异常
        dealErr(data) {
            this.dealId = data.id;
            this.modalStatus = true;
        },
        //请求异常详情
        requestAbnormalDetail(data) {
            this.$get(
                `${ABNORMAL_DEVICE_DETAIL}/${this.currentScene}/${data.id}`
            )
                .then((res) => {
                    this.detailModal = true;
                    this.errLoc = res.loc;
                    this.errDesc = res.comment;
                    let errPics = [];
                    res.pics.forEach((arr) => {
                        errPics.push(this.$fileSrc(arr.pic));
                    });
                    this.errPics = errPics;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, ABNORMAL_DEVICE_DETAIL);
                });
        },
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    //验证成功
                    this.modalStatus = false;
                    this.dealAbnormal();
                }
            });
        },
        //处理异常
        dealAbnormal() {
            let data = { ...this.formValidate };
            this.dealLoading = true;
            let url = `${ABNORMAL_DEVICE_HANDLE}/${this.currentScene}/${this.dealId}`;
            this.$put(url, conversionFrom(data))
                .then((res) => {
                    this.dealLoading = false;
                    this.$Message.success({
                        background: true,
                        content: "处理成功",
                    });
                    this.requestAbnormal();
                })
                .catch((err) => {
                    this.dealLoading = false;
                    //异常情况
                    this.$httpErr(err, url);
                });
        },
        //取消处理
        handleReset() {
            this.modalStatus = false;
        },
    },
    computed: {
        ...mapGetters(["currentScene"]),
    },
    watch: {
        modalStatus: function (val) {
            if (!val) {
                this.$refs.formValidate.resetFields();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
