<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">菜单管理</span>
      <div class="btn-group">
        <Button type="primary" @click="addMenu">新增</Button>
      </div>
    </div>
    <div class="role-view">
      <Table row-key="id" ref="selection" :columns="tableTitle" :data="tableList">
        <template slot-scope="{ row, index }" slot="action">
          <span class="iconfont iconxiugai" @click="operateMenu(row, 'edit', index)"></span>
          <span class="iconfont iconsahnchufuben" @click="operateMenu(row, 'del', index)"></span>
        </template>
      </Table>
    </div>
    <Modal
        v-model="modalStatus"
        :title="modalTitle">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100">
        <FormItem label="菜单名称" prop="text">
          <Input v-model="formValidate.text" placeholder="请输入" />
        </FormItem>
        <FormItem label="菜单id" prop="name">
          <Input v-model="formValidate.name" placeholder="请输入" />
        </FormItem>
        <FormItem label="菜单URL" prop="url">
          <Input v-model="formValidate.url" placeholder="请输入" />
        </FormItem>
        <FormItem label="上级菜单" prop="parentId">
          <Select v-model="formValidate.parentId" placeholder="请选择">
            <Option v-for="item in parentMenu" :value="item.id" :key="item.id">{{ item.text }}</Option>
          </Select>
        </FormItem>
        <FormItem label="图标" prop="icon">
          <Input v-model="formValidate.icon" placeholder="请输入" />
        </FormItem>
        <FormItem label="顺序号" prop="sort">
          <Input v-model="formValidate.sort" placeholder="请输入" @on-keyup="filterInput($event)" />
        </FormItem>
        <FormItem label="接口资源" prop="apiIds">
          <Select v-model="formValidate.apiIds" multiple style="width: 100%;">
            <Option v-for="item in apiList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="是否隐藏" prop="isHide">
          <RadioGroup v-model="formValidate.isHide">
            <Radio :label="true">是</Radio>
            <Radio :label="false">否</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="备注" prop="comment">
          <Input v-model="formValidate.comment" type="textarea"
                 :autosize="{minRows: 3,maxRows: 5}" placeholder="请输入" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" @click="handleReset()">取消</Button>
        <Button type="primary" :loading="submitLoading" @click="handleSubmit('formValidate')">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {MENU_MANAGER_LIST_NOPAGE, API_LIST_NOPAGE, MENU_ITEM_EDIT, MENU_RELATION_ROLE} from '../../../config/api'
  import {conversionFrom} from '../../../utils/helper'
  export default {
    data() {
      return {
        //编辑的菜单id
        menuId: '',
        //表格标题
        tableTitle: [
          {
            title: '菜单名称',
            key: 'text',
            width: 200,
            tree: true,
          },
          {
            title: '菜单id',
            key: 'name',
          },
          {
            title: 'URL地址',
            key: 'url'
          },
          {
            title: '顺序号',
            key: 'sort',
            width: 80
          },
          {
            title: '是否隐藏',
            key: 'hidden',
            width: 100
          },
          {
            title: '备注',
            key: 'comment'
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            width: 120,
            align: 'center'
          }
        ],
        //表格列表
        tableList: [],
        //接口列表
        apiList: [],
        //菜单列表
        parentMenu: [],
        //弹框标题
        modalTitle: '',
        //菜单编辑弹框
        modalStatus: false,
        //表单提交loading
        submitLoading: false,
        //编辑菜单项校验数据
        formValidate: {
          text: '',
          name: '',
          url: '',
          parentId: '',
          icon: '',
          sort: '',
          apiIds: [],
          isHide: '',
          comment: ''
        },
        //菜单校验规则
        ruleValidate: {
          text: [
            { required: true, message: '请选择名称', trigger: 'change' }
          ],
          name: [
            { required: true, message: '请输入菜单id', trigger: 'blur' },
          ],
          url: [
            { required: true, message: '请输入URL', trigger: 'blur' },
          ],
          parentId: [
            { required: true, type: 'number', message: '请选择上级菜单', trigger: 'change' }
          ],
          icon: [
            { required: true, message: '请输入icon', trigger: 'blur' }
          ],
          sort: [
            { required: true, message: '请输入顺序号', trigger: 'blur' }
          ],
          apiIds: [
            { required: true, type: 'array', min: 1, message: '请选择api', trigger: 'change' }
          ],
          isHide: [
            { required: true, type: 'boolean', message: '请选择是否', trigger: 'change' }
          ],
          comment: [
            { required: true, message: '请输入备注', trigger: 'blur' },
          ]
        }
      }
    },
    created() {
      this.requestMenu()
      this.requestApiList()
    },
    methods: {
      //查询菜单
      requestMenu() {
        this.$get(MENU_MANAGER_LIST_NOPAGE).then(res => {
          //默认根目录一级目录配置
          let parentMenu = [{
            text: '根目录',
            id: 0,
          }];
          let tableList = [];
          let sndMenu = {};
          res.forEach(arr => {
            //筛选一级目录
            if (arr.parentId === 0) {
              parentMenu.push({
                text: arr.text,
                id: arr.id,
              })
            }
            arr.hidden = arr.isHide ? '是' : '否';
            if (arr.parentId !== 0) {
              sndMenu[arr.parentId] = sndMenu[arr.parentId] || [];
              sndMenu[arr.parentId].push({...arr})
            } else {
              tableList.push({...arr});
            }
          });
          tableList.map(list => {
            //默认打开子数据
            // list._showChildren = true;
            list.children = sndMenu[list.id] || [];
            return list
          });
          this.tableList = tableList;
          this.parentMenu = parentMenu;
        }).catch(err => {
          //异常情况
          this.$httpErr(err)
        })
      },
      //查询接口资源
      requestApiList() {
        this.$get(API_LIST_NOPAGE).then(res => {
          this.apiList = res;
        }).catch(err => {
          //异常情况
          this.$httpErr(err)
        })
      },
      //添加菜单
      addMenu() {
        this.menuId = '';
        this.modalTitle = '添加菜单';
        this.modalStatus = true;
        this.resetForm()
      },
      //操作菜单
      operateMenu(data, type, index) {
        if (type === 'edit') {
          this.modalTitle = '编辑菜单';
          this.modalStatus = true;
          this.menuId = data.id;
          console.log(data)
          this.resetForm(data)
        } else if (type === 'del') {
          this.$Modal.confirm({
            title: '提示',
            content: '<p>确定删除此菜单</p>',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              //确认删除
              this.delConfirm(data.id, index)
            }
          });
        }
      },
      //删除前确认是否关联巡检点
      delConfirm(id, index) {
        this.$get(`${MENU_RELATION_ROLE}/${id}`).then(res => {
          if (!res) {
            this.delMenu(id, index)
          } else {
            this.$Message.error({
              background: true,
              content: '菜单已关联角色，无法删除'
            });
          }
        }).catch(err => {
          //异常情况
          this.$httpErr(err, MENU_RELATION_ROLE)
        })
      },
      //删除菜单
      delMenu(id, index) {
        this.$delete(`${MENU_ITEM_EDIT}/${id}`).then(res => {
          this.$Message.success({
            background: true,
            content: '删除菜单成功'
          });
          this.tableList.splice(index, 1);
        }).catch(err => {
          //异常情况
          this.$httpErr(err)
        })
      },
      //过滤input输入框只能输入数字
      filterInput(e) {
        this.formValidate.sort = this.formValidate.sort.replace(/[^\d]/g,'');
      },
      //重置表单
      resetForm(data) {
        this.formValidate.text = !!data ? data.text : '';
        this.formValidate.name = !!data ? data.name : '';
        this.formValidate.url = !!data ? data.url : '';
        this.formValidate.parentId = !!data ? data.parentId : '';
        this.formValidate.icon = !!data ? data.icon : '';
        this.formValidate.sort = !!data ? (data.sort).toString() : '';
        this.formValidate.apiIds = !!data ? [...data.apiIds] : [];
        this.formValidate.isHide = !!data ? data.isHide : '';
        this.formValidate.comment = !!data ? data.comment : '';
      },
      //验证提交
      handleSubmit (name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            //验证成功
            this.submitMenu();
          }
        })
      },
      //提交菜单表单
      submitMenu() {
        this.submitLoading = true;
        let url = !!this.menuId ? `${MENU_ITEM_EDIT}/${this.menuId}` : MENU_ITEM_EDIT;
        let httpType = !!this.menuId ? '$put' : '$post';
        this[httpType](url, conversionFrom(this.formValidate), true).then(res => {
          this.submitLoading = false;
          this.modalStatus = false;
          let msg = !!this.menuId ? '编辑菜单成功' : '添加菜单成功';
          this.$Message.success({
            background: true,
            content: msg
          });
          this.requestMenu()
        }).catch(err => {
          this.submitLoading = false;
          //异常情况
          this.$httpErr(err)
        })
      },
      //取消提交
      handleReset() {
        this.modalStatus = false;
      }
    },
    watch: {
      'modalStatus': function(val) {
        if (!val) {
          this.$refs.formValidate.resetFields();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
