<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">{{
                !!id ? "编辑角色" : "新增角色"
            }}</span>
            <div class="btn-group">
                <Button type="primary" @click="saveRole">保存</Button>
                <Button type="primary" ghost @click="cancelEdit">取消</Button>
            </div>
        </div>
        <div class="role-edit-view">
            <div class="form-item">
                <label class="item-label"
                    ><span class="label-vital">*</span>角色名称</label
                >
                <div class="input-box">
                    <Input
                        v-model="name"
                        placeholder="请输入"
                        style="width: 50%"
                        @on-blur="blurInput('name')"
                    />
                </div>
                <div class="form-err" v-show="errForm.has('name')">
                    请输入名称
                </div>
            </div>
            <div class="form-item">
                <label class="item-label">备注</label>
                <div class="input-box">
                    <Input
                        v-model="comment"
                        placeholder="请输入"
                        style="width: 50%"
                    />
                </div>
            </div>
            <div class="form-item">
                <label class="item-label"
                    ><span class="label-vital">*</span>角色可见范围</label
                >
                <div class="input-box">
                    <RadioGroup v-model="type" @on-change="changeType">
                        <Radio :label="0">
                            <span>全平台可见</span>
                        </Radio>
                        <Radio :label="1">
                            <span>部分场景可见</span>
                        </Radio>
                    </RadioGroup>
                </div>
            </div>
            <div class="form-item">
                <label class="item-label"
                    ><span class="label-vital">*</span>角色类型</label
                >
                <div class="input-box">
                    <RadioGroup v-model="role" @on-change="changeRole">
                        <Radio label="01">
                            <span>一般角色</span>
                        </Radio>
                        <Radio label="02">
                            <span>维修工</span>
                        </Radio>
                    </RadioGroup>
                </div>
            </div>
            <div class="form-item" v-show="type === 1">
                <label class="item-label"
                    ><span class="label-vital">*</span>场景列表</label
                >
                <div class="input-box">
                    <div class="input-body">
                        <div
                            class="body-item"
                            v-for="(item, index) in sceneSelected"
                            :key="item.key"
                        >
                            <span class="item-span">{{ item.label }}</span>
                            <i
                                class="ivu-icon ivu-icon-ios-close"
                                @click="delScene(item.key, index)"
                            ></i>
                        </div>
                    </div>
                    <Button type="primary" ghost @click="selectScene"
                        >分配场景</Button
                    >
                </div>
                <div class="form-err" v-show="errForm.has('scene')">
                    请选择场景
                </div>
            </div>
            <div class="form-item">
                <label class="item-label"
                    ><span class="label-vital">*</span>功能权限</label
                >
                <div class="input-box">
                    <div class="item-func">
                        <div
                            class="func-collapse"
                            v-for="(item, index) in authorityList"
                            :key="item.id"
                        >
                            <div class="collapse-title">
                                <Checkbox
                                    v-model="item.checked"
                                    @on-change="checkModule(index)"
                                    >{{ item.text }}</Checkbox
                                >
                                <div class="title-icon">
                                    <span class="iconfont iconxiangxia"></span>
                                </div>
                            </div>
                            <div class="collapse-body" v-show="item.showDetail">
                                <div
                                    class="body-item"
                                    v-show="item.sndList.length === 0"
                                >
                                    <!--<div class="item-left"></div>-->
                                    <!--<div class="item-line"></div>-->
                                    <!--<div class="item-right"></div>-->
                                </div>
                                <div class="body-item">
                                    <Checkbox
                                        v-model="snd.checked"
                                        v-for="(snd, sndIndex) in item.sndList"
                                        :key="snd.id"
                                        @on-change="
                                            checkSndModule(index, sndIndex)
                                        "
                                        >{{ snd.text }}</Checkbox
                                    >
                                    <!--<div class="item-left">-->
                                    <!--<Checkbox v-model="snd.checked"-->
                                    <!--@on-change="checkSndModule(index, sndIndex)">{{ snd.text }}</Checkbox>-->
                                    <!--</div>-->
                                    <!--<div class="item-line"></div>-->
                                    <!--<div class="item-right">-->
                                    <!--<Checkbox v-model="child.checked" :key="child.id" @on-change="checkChild(index, sndIndex)"-->
                                    <!--v-for="(child, childIndex) in snd.childList">{{ child.text }}</Checkbox>-->
                                    <!--</div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-err" v-show="errForm.has('authority')">
                    请选择权限
                </div>
            </div>
        </div>
        <Modal
            v-model="sceneStatus"
            title="分配场景"
            width="730"
            @on-ok="confirmScene"
            @on-cancel="cancelScene"
        >
            <Transfer
                :titles="titleArr"
                :data="sceneList"
                :target-keys="selectedKeys"
                :list-style="listStyle"
                :operations="['向左', '向右']"
                filterable
                @on-change="handleChange"
            >
            </Transfer>
        </Modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import {
    USER_OWN_BUILDINGS,
    ROLE_ITEM_EDIT,
    MENU_MANAGER_LIST_NOPAGE,
} from "../../../config/api";
import { conversionFrom } from "../../../utils/helper";
export default {
    data() {
        return {
            //角色id
            id: null,
            //角色名称
            name: "",
            //备注
            comment: "",
            //可见范围
            type: 0,
			// 角色类型 01 一般角色 02 维修工
			role: '01',
            //选择的场景
            sceneSelected: [],
            //权限列表
            authorityList: [],
            //错误表单
            errForm: new Set(),
            //编辑弹框状态
            sceneStatus: false,
            //成员列表
            sceneList: [],
            //已选成员列表
            selectedKeys: [],
            //穿梭框样式
            listStyle: {
                width: "300px",
                height: "400px",
            },
            //穿梭狂标题
            titleArr: ["场景", "已选择的场景"],
        };
    },
    created() {
        let id = this.$route.params.id;
        this.id = id === "0" ? null : id;
        this.requestMenu();
        this.requestSceneList();
    },
    methods: {
        //失去焦点
        blurInput(name) {
            let validateText = this[name] === "";
            this.updateFormErr(validateText, name);
        },
        //请求场景列表
        requestSceneList() {
            this.$get(USER_OWN_BUILDINGS)
                .then((res) => {
                    res.forEach((arr) => {
                        let sceneObj = {
                            key: arr.id,
                            description: arr.name,
                            label: arr.comment,
                            disabled: false,
                        };
                        this.sceneList.push(sceneObj);
                    });
                    //id不为null表示编辑角色,初始化角色信息
                    if (this.id !== null) {
                        let roleMes = JSON.parse(this.$route.query.mes);
                        this.initRole(roleMes);
                    }
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err);
                });
        },
        //请求菜单列表
        requestMenu() {
            let menuIds = [];
            if (this.id !== null) {
                let roleMes = JSON.parse(this.$route.query.mes);
                menuIds = roleMes.menuIds;
            }
            this.$get(`${MENU_MANAGER_LIST_NOPAGE}`)
                .then((res) => {
                    let sndMenu = {};
                    let childMenu = {};
                    let menuList = [];
                    res.forEach((arr) => {
                        let obj = {
                            text: arr.text,
                            id: arr.id,
                            checked: false,
                        };
                        if (arr.parentId === 0) {
                            obj.showDetail = true;
                            menuList.push(obj);
                        } else {
                            obj.checked = menuIds.includes(arr.id);
                            obj.childList = [];
                            sndMenu[arr.parentId] = sndMenu[arr.parentId] || [];
                            sndMenu[arr.parentId].push(obj);
                        }
                    });
                    menuList.map((list) => {
                        let checked = true;
                        list.sndList = sndMenu[list.id] || [];
                        list.sndList.forEach((arr) => {
                            if (!arr.checked) {
                                checked = false;
                            }
                        });
                        list.checked = checked;
                        return list;
                    });
                    this.authorityList = menuList;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err);
                });
        },
        //初始化角色数据
        initRole(data) {
            this.name = data.name;
            this.comment = data.comment;
            this.type = data.type;
			this.role = data.roleType || '01';
            if (data.type !== 0) {
                this.selectedKeys = data.buildingIds;
                this.sceneList.forEach((arr) => {
                    if (data.buildingIds.includes(arr.key)) {
                        this.sceneSelected.push({ ...arr });
                    }
                });
            }
        },
        //整合已经菜单权限
        filterMenu() {
            let menuIds = new Set();
            this.authorityList.forEach((list) => {
                let sndMenus = [];
                if (list.checked) {
                    menuIds.add(list.id);
                }
                list.sndList.forEach((arr) => {
                    if (arr.checked) {
                        sndMenus.push(arr.id);
                    }
                });
                if (sndMenus.length > 0) {
                    menuIds.add(list.id);
                    menuIds.add([...sndMenus]);
                }
            });
            return menuIds;
        },
        //验证表单
        validateForm() {
            this.blurInput("name");
            this.validateAuthority();
            if (this.type === 1) {
                this.validateScene();
            }
            return this.errForm.size === 0;
        },
        //保存角色
        saveRole() {
            if (this.validateForm()) {
                let data = {
                    name: this.name,
                    comment: this.comment,
                    type: this.type,
					roleType: this.role,
                    buildingIds: this.selectedKeys,
                    menuIds: [...this.filterMenu()],
                };
                this.submitRole(data);
            }
        },
        //提交角色信息
        submitRole(data) {
            let httpType = this.id !== null ? "$put" : "$post";
            let url =
                this.id !== null
                    ? `${ROLE_ITEM_EDIT}/${this.currentScene}/${this.id}`
                    : `${ROLE_ITEM_EDIT}/${this.currentScene}`;
            this[httpType](url, conversionFrom(data))
                .then((res) => {
                    let msg =
                        this.id !== null ? "编辑角色成功" : "新增角色成功";
                    this.$Message.success({
                        background: true,
                        content: msg,
                    });
                    history.go(-1);
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, ROLE_ITEM_EDIT);
                });
        },
        //取消编辑/保存
        cancelEdit() {
            history.go(-1);
        },
        //角色可见范围变化
        changeType(val) {
            if (val === 0) {
                this.errForm.delete("scene");
                this.selectedKeys = [];
                this.sceneSelected = [];
            }
        },
		// 角色类型切换
		changeRole(val) {

		},
        //分配场景
        selectScene() {
            this.sceneStatus = true;
        },
        //删除已选场景
        delScene(key, index) {
            for (let i = 0; i < this.selectedKeys.length; i++) {
                if (key === this.selectedKeys[i]) {
                    this.selectedKeys.splice(i, 1);
                    break;
                }
            }
            this.sceneSelected.splice(index, 1);
            this.validateScene();
        },
        //确认选择场景
        confirmScene() {
            this.sceneSelected = [];
            if (!this.selectedKeys.length) {
                return;
            }
            this.sceneList.forEach((list) => {
                if (this.selectedKeys.includes(list.key)) {
                    this.sceneSelected.push({ ...list });
                }
            });
            this.validateScene();
        },
        //取消场景选择
        cancelScene() {
            let selectedKeys = [];
            this.sceneSelected.forEach((arr) => {
                selectedKeys.push(arr.key);
            });
            this.selectedKeys = selectedKeys;
        },
        //验证场景
        validateScene() {
            let validateText = !this.selectedKeys.length;
            this.updateFormErr(validateText, "scene");
        },
        //穿梭框变化修改选择值
        handleChange(val) {
            this.selectedKeys = val;
        },
        //全选一级模块
        checkModule(index) {
            let status = this.authorityList[index].checked;
            this.authorityList[index].sndList.map((list) => {
                list.checked = status;
                list.childList.map((arr) => {
                    arr.checked = status;
                    return arr;
                });
                return list;
            });
            this.validateAuthority();
        },
        //全选次级模块
        checkSndModule(index, sndIndex) {
            let status = this.authorityList[index].sndList[sndIndex].checked;
            this.authorityList[index].sndList[sndIndex].childList.map(
                (list) => {
                    list.checked = status;
                    return list;
                }
            );
            //更新一级模块全选
            this.updateModuleCheck(index);
        },
        //更新一级目录全选
        updateModuleCheck(index) {
            let status = true;
            let sndList = this.authorityList[index].sndList;
            for (let i = 0; i < sndList.length; i++) {
                if (!sndList[i].checked) {
                    status = false;
                    break;
                }
            }
            this.authorityList[index].checked = status;
            this.validateAuthority();
        },
        //选择三级内容
        checkChild(index, sndIndex) {
            //更新二级目录全选
            let status = true;
            let childList =
                this.authorityList[index].sndList[sndIndex].childList;
            for (let i = 0; i < childList.length; i++) {
                if (!childList[i].checked) {
                    status = false;
                    break;
                }
            }
            this.authorityList[index].sndList[sndIndex].checked = status;
            this.updateModuleCheck(index);
        },
        //更新功能权限验证
        validateAuthority() {
            let menuIds = this.filterMenu();
            this.updateFormErr(!menuIds.size, "authority");
        },
        //更新表单错误信息
        updateFormErr(val, name) {
            if (val) {
                this.errForm.add(name);
            } else {
                this.errForm.delete(name);
            }
            this.$forceUpdate();
        },
    },
    computed: {
        ...mapState({
            currentScene: (state) => state.common.currentScene,
        }),
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
