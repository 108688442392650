<template>
    <div class="render-page">
        <sidebar></sidebar>
        <div class="page-container">
            <loading v-show="showLoading"></loading>
            <div class="container-box">
                <header-bar ref="header" @headerEvent="headerEvent"></header-bar>
                <router-view></router-view>
            </div>
        </div>
        <Modal v-model="sceneStatus" :closable="false" :mask-closable="false" title="请选择场景">
            <Select v-model="systemScene" @on-change="handleChange" filterable style="width:100%">
                <Option v-for="item in scene" :value="item.id" :key="item.id">{{ item.comment }}</Option>
            </Select>
            <div slot="footer">
                <Button type="text" @click="operateScene('cancel')">取消</Button>
                <Button type="primary" :disabled="btnDisabled" @click="operateScene('confirm')">确认</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Sidebar from '../../components/Sidebar'
import HeaderBar from '../../components/HeaderBar'
import Loading from '../../components/Loading'
import { USER_OWN_INFO, USER_OWN_BUILDINGS, SCENE_CONFIG_INFO } from '../../config/api'
import { setScene, getScene } from '../../utils/auth'
export default {
    data() {
        return {
            //切换场景弹框状态
            sceneStatus: false,
            //系统场景
            systemScene: '',
            btnDisabled: false
        }
    },
    components: {
        Sidebar,
        HeaderBar,
        Loading
    },
    created() {
        this.requestUser()
        this.requestScene()
    },
    methods: {
        // 场景变化
        handleChange(e) {
            let obj = {}
            this.scene.forEach(item => {
                if (item.id === e) {
                    obj = item
                }
            })
            if (obj.isSkipCheckRemainDay===false &&obj.remainDay <= 0) {
                this.btnDisabled = true
                this.$Message.warning({
                    content: '该场景已过期，请联系管理员',
                    duration: 2
                })
            } else {
                this.btnDisabled = false
            }
        },
        //请求用户信息
        requestUser() {
            this.$get(USER_OWN_INFO)
                .then(res => {
                    this.saveUser(res)
                })
                .catch(err => {
                    //异常情况
                    this.$httpErr(err, USER_OWN_INFO)
                })
        },
        //保存用户信息
        saveUser(data) {
            this.$store.commit('user/UPDATE_USERID', data.id)
            this.$store.commit('user/UPDATE_USERNAME', data.username)
            this.$store.commit('user/UPDATE_NICKNAME', data.nickname)
            this.$store.commit('user/UPDATE_USERROLE', data.roleIds[0])
            this.$store.commit('user/UPDATE_SCENETYPE', data.type)
            this.$store.commit('user/UPDATE_DEPARTMENT', data.departmentIds)
        },
        //请求用户场景信息
        requestScene() {
            this.$get(USER_OWN_BUILDINGS)
                .then(res => {
                    let sceneList = []
                    //localStorage里面的场景值
                    let scene = getScene()
                    //场景地图map数据
                    let sceneMap = {}
                    res.forEach(arr => {
                        if (!arr) {
                            return
                        }
                        let sceneObj = {
                            id: arr.id,
                            name: arr.name,
                            comment: arr.comment,
                            appName: arr.appName,
                            mapKey: arr.mapKey,
                            remainDay: arr.remainDay,
                            isSkipCheckRemainDay: arr.isSkipCheckRemainDay
                        }
                        //缓存场景值存在，则取得对应蜂鸟地图map数据
                        if (!!scene && scene === arr.id) {
                            sceneMap = sceneObj
                        }
                        sceneList.push(sceneObj)
                    })
                    this.$store.commit('common/UPDATE_SCENE', sceneList)
                    //判断缓存种是否含有场景值
                    if (!scene) {
                        if (sceneList.length === 1) {
                            //只有一个场景，不需要弹框选择场景
                            this.$store.commit('common/UPDATE_CURRENTSCENE', sceneList[0].id)
                            this.updateScene(sceneList[0])
                        } else {
                            this.sceneStatus = true
                        }
                    } else {
                        //更新场景值以及地图数据
                        this.updateScene(sceneMap)
                    }
                })
                .catch(err => {
                    //异常情况
                    this.$httpErr(err, USER_OWN_BUILDINGS)
                })
        },
        //更新进入场景
        updateScene(data) {
            //更新场景值以及地图数据
            this.systemScene = data.id
            setScene(this.systemScene)
            this.$store.commit('map/UPDATE_APPNAME', data.appName)
            this.$store.commit('map/UPDATE_KEY', data.mapKey)
            this.updateSceneMap(data.id)
            this.updateRoute()
        },
        //操作场景弹框
        operateScene(type) {
            //确认场景
            if (type === 'confirm' && this.systemScene !== this.currentScene) {
                this.$store.commit('common/UPDATE_CURRENTSCENE', this.systemScene)
                setScene(this.systemScene)
                this.updateSceneMap(this.systemScene)
                for (let i = 0; i < this.scene.length; i++) {
                    if (this.systemScene === this.scene[i].id) {
                        //更新蜂鸟地图信息
                        this.$store.commit('map/UPDATE_APPNAME', this.scene[i].appName)
                        this.$store.commit('map/UPDATE_KEY', this.scene[i].mapKey)
                        break
                    }
                }
                this.updateRoute()
            }
            this.sceneStatus = false
        },
        //更新场景地图信息
        updateSceneMap(scene) {
            this.$get(`${SCENE_CONFIG_INFO}/${scene}`)
                .then(res => {
                    let mapData = res.floorList[0]
                    this.$store.commit('map/UPDATE_FMAPID', mapData.fengMapId)
                    this.$store.commit('map/UPDATE_SERVER_URL', mapData.fengMap)
                    this.$store.commit('map/UPDATE_THEME_STYLE', mapData.fengStyle)
                    this.$store.commit('map/UPDATE_THEME_URL', mapData.fengStyleUrl)
                    let floorList = []
                    res.floorList.forEach(arr => {
                        floorList.push({
                            label: arr.name,
                            value: arr.id,
                            number: arr.number
                        })
                    })
                    console.log(floorList)
                    this.$store.commit('map/UPDATE_MAP_FLOOR', floorList)
                })
                .catch(err => {
                    //异常情况
                    this.$httpErr(err, SCENE_CONFIG_INFO)
                })
        },
        //数据更新后路由跳转
        updateRoute() {
            let path = this.$route.path
            let pathArr = path.split('/').slice(1)
            if (pathArr.length < 2) {
                this.$router.push({ path: '/page/managerhome' })
            }
            // 更新顶部操作栏
            this.$refs.header.init()
        },
        //导航条事件
        headerEvent(type) {
            if (type === 'scene') {
                this.sceneStatus = true
            }
        }
    },
    computed: {
        ...mapState({
            showLoading: state => state.common.showLoading,
            scene: state => state.common.scene,
            currentScene: state => state.common.currentScene
        })
    },
    watch: {
        $route: function(val) {
            if (val.name === 'Page') {
                this.$router.push({ path: '/page/managerhome' })
            }
        },
        sceneStatus: function(val) {
            if (!val && this.systemScene !== this.currentScene) {
                this.systemScene = this.currentScene
            }
        }
    }
}
</script>

<style lang="scss">
@import './index';
</style>
