const getters = {
  token: state => state.user.token,
  userId: state => state.user.userId,
  userRole: state => state.user.userRole,
  userName: state => state.user.userName,
  login: state => state.common.login,
  scene: state => state.common.scene,
  showLoading: state => state.common.showLoading,
  currentScene: state => state.common.currentScene,
  systemIcon: state => state.common.systemIcon,
  mapServerUrl: state => state.map.mapServerUrl,
  mapThemeStyle: state => state.map.mapThemeStyle,
  mapThemeUrl: state => state.map.mapThemeUrl,
  fmapID: state => state.map.fmapID,
  appName: state => state.map.appName,
  key: state => state.map.key,
  mapFloor: state => state.map.mapFloor,
};
export default getters
