<template>
  <div class="home">
    <router-view></router-view>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {getToken, getScene} from '../../utils/auth'
  export default {
    created() {
      //系统初始化
      const token = getToken();
      if (!!token) {
        this.$store.commit("common/UPDATE_LOGIN", true);
        this.$store.commit("user/UPDATE_TOKEN", token);
      }
      const scene = getScene();
      if (!!scene) {
        this.$store.commit('common/UPDATE_CURRENTSCENE', scene)
      }
      if (!this.login) {
        this.$router.push({path: '/login'})
      } else {
        if (this.$route.path === '/') {
          this.$router.push({path: '/page'})
        }
      }
    },
    computed: {
      ...mapState({
        login: state => state.common.login,
      })
    },
    watch: {
      '$route': function(val) {
        if (val.name === 'Home') {
          this.$router.push({path: '/page'})
        }
      }
    }
  }
</script>

<style lang="scss">
  .home{
    width: 100%;
    height: 100%;
  }
</style>
