import Vue from 'vue';

import axios from 'axios';

// 设置请求头/自动去除缓存
// axios.defaults.headers['Cache-Control'] = 'no-cache';
// axios.defaults.headers['Pragma'] = 'no-cache';
//默认请求格式
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

Vue.prototype.$http = axios;

import http from './request'

export default function httpInit(
  Vue
) {
  //post请求
  Vue.prototype.$post = http.post;
  //delete请求
  Vue.prototype.$delete = http.delete;
  //get请求
  Vue.prototype.$get = http.get;
  //put请求
  Vue.prototype.$put = http.put;
  //获取文件请求
  Vue.prototype.$getFile = http.getFile;
  //请求异常处理
  Vue.prototype.$httpErr = function (err, url) {
    if (!!url) {
      console.error(`报错接口`, url)
    }
    console.log(err.code)
    if (err.code === undefined) {
      //前端代码逻辑报错/接口报错 打印日志
      console.log(err)
      //异常结果提示
      this.$Message.error({
        background: true,
        duration: 3,
        content: '数据接口异常'
      });
    } else if (err.code === 401) {
      //用户登录过期
      this.$Modal.warning({
        title: '提示',
        content: '登录已过期,请重新登录',
        onOk: () => {
          this.$logout()
        },
      });
    } else {
      // code为ECONNABORTED时 => 服务异常/断网或者超时
      // code为其他值时 => 接口处理异常(提示接口返回的message异常信息)
      let content = err.code === 'ECONNABORTED' ? '服务异常' : err.msg;
      //异常结果提示
      this.$Message.error({
        background: true,
        duration: 3,
        content: content
      });
    }
  };
}