<template>
    <div class="login">
        <div class="login-title">
            <div class="icon-box">
                <span class="iconfont iconshezhishedingpeizhichilun"></span>
            </div>
            <span class="right-name">巡检管理平台</span>
        </div>
        <img src="../../assets/image/login_bg.png" class="image-login" />
        <div class="login-form">
            <div class="form-box">
                <div class="form-title">用户登录</div>
                <input type="text" class="login-input" placeholder="请输入账号" v-model.trim="account" />
                <input type="password" class="login-input" placeholder="请输入密码" v-model.trim="psd" />
                <div class="psd-box">
                    <!--<div class="remember-box">-->
                    <!--<el-checkbox v-model="remember">记住密码</el-checkbox>-->
                    <!--</div>-->
                    <!--<div class="forget-box">-->
                    <!--<span class="forget-mes">忘记密码?</span>-->
                    <!--</div>-->
                </div>
                <Button size="large" type="primary" long shape="circle" :loading="loading" @click="login">
                    <span v-if="!loading">登录</span>
                    <span v-else>登录中...</span>
                </Button>
                <div class="err-box" v-show="errStatus">
                    <span class="err-mes">{{ errMes }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { USER_LOGIN_URL } from '../../config/api'
import { conversionFrom } from '../../utils/helper'
import { getScene, setToken } from '../../utils/auth'
import { USER_OWN_BUILDINGS } from '../../config/api'
export default {
    data() {
        return {
            //记住密码状态
            remember: false,
            //账号
            account: '',
            //密码
            psd: '',
            //加载状态
            loading: false,
            //错误状态
            errStatus: false,
            //报错信息
            errMes: '',
            //错误定时器
            errTime: ''
        }
    },
    methods: {
        //登录
        login() {
            if (!this.submitStatus || this.loading) {
                return
            }
            let data = {
                username: this.account,
                password: this.psd
            }
            this.loading = true
            clearTimeout(this.errTime)
            this.$post(USER_LOGIN_URL, conversionFrom(data))
                .then(res => {
                    this.loginSave(res)
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                    this.errStatus = true
                    this.errMes = !!err.code ? err.msg : '系统异常'
                    //3秒后隐藏登录报错
                    this.errTime = setTimeout(() => {
                        this.errStatus = false
                    }, 3000)
                })
        },
        //登录缓存
        loginSave(data) {
            //浏览器缓存用户数据
            setToken(data)
            this.$store.commit('user/UPDATE_TOKEN', data)
            this.$store.commit('common/UPDATE_LOGIN', true)
            // 获取场景列表
            setTimeout(() => {
                this.getSceneList()
            }, 100)
        },
        getSceneList() {
            this.$get(USER_OWN_BUILDINGS).then(res => {
                const arr = []
                res.forEach(item => {
                    let sceneObj = {
                        id: item.id,
                        name: item.name,
                        comment: item.comment,
                        appName: item.appName,
                        remainDay: item.remainDay,
                        mapKey: item.mapKey
                    }

                    arr.push(sceneObj)
                })
                localStorage.setItem('sceneList', JSON.stringify(arr))
                this.$router.push({ path: '/page' })
            })
        }
    },
    computed: {
        submitStatus() {
            return this.account !== '' && this.psd !== ''
        }
    }
}
</script>
<style lang="scss" scoped>
@import './index';
</style>
