<template>
  <div class="page-view">
    <div class="page-header">
      <span class="header-text">巡检路线</span>
      <div class="btn-group">
        <Button type="primary" @click="createWay">创建巡检路线</Button>
      </div>
    </div>
    <div class="inspect-way-view">
      <div class="view-title">
        <div class="title-left">
          <div class="left-item">
            <span class="left-title">路线名称</span>
            <Input v-model="routeName" placeholder="请输入" style="width: 300px" />
          </div>
          <Button type="primary" ghost @click="searchRoute">查询</Button>
        </div>
      </div>
      <Table stripe ref="selection" :columns="tableTitle" :data="tableList">
        <template slot-scope="{ row, index }" slot="action">
          <span class="iconfont iconchakan" @click="showWay(row)"></span>
          <span class="iconfont iconxiugai" @click="editWay(row)"></span>
          <span class="iconfont iconfuzhi1" @click="copyWay(row)"></span>
          <span class="iconfont iconsahnchufuben" @click="delItem(row)"></span>
        </template>
      </Table>
      <div class="paging-box">
        <Page :current="pageNo"
              :page-size="pageSize"
              :total="total"
              @on-change="changePage"
              @on-page-size-change="changeSize"
              show-total show-elevator show-sizer />
      </div>
    </div>
    <Modal
        v-model="modalStatus"
        title="设置路线名称">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100">
        <FormItem label="路线名称">
          <FormItem prop="name">
            <Input v-model="formValidate.name" placeholder="请输入" />
          </FormItem>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" @click="handleReset()">取消</Button>
        <Button type="primary" @click="handleSubmit('formValidate')">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {ROUTE_LIST_PAGE, ROUTE_LINE_COPY, ROUTE_LINE_EDIT} from '../../../config/api'
  import {dateConversion, conversionFrom} from '../../../utils/helper'
  export default {
    data() {
      return {
        //设备名称
        routeName: '',
        //表格标题
        tableTitle: [
          // {
          //   type: 'selection',
          //   width: 60,
          //   align: 'center'
          // },
          {
            title: '序号',
            key: 'index',
            width: 80,
          },
          {
            title: '巡检路线名称',
            key: 'name'
          },
          {
            title: '创建时间',
            key: 'createTime'
          },
          {
            title: '创建人',
            key: 'createUserNickName'
          },
          {
            title: '操作',
            slot: 'action',
            width: 150,
            align: 'center'
          },
        ],
        tableList: [],
        //分页数据
        pageNo: 1,
        pageSize: 10,
        total: 0,
        //复制的巡检路线id
        copyRouteId: '',
        //复制路线弹框状态
        modalStatus: false,
        //验证表单
        formValidate: {
          name: '',
        },
        //验证规则
        ruleValidate: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' }
          ],
        }
      }
    },
    created() {
      this.requestWayList()
    },
    methods: {
      //请求路线列表
      requestWayList() {
        let data = {
          current: this.pageNo,
          size: this.pageSize,
          search: this.roleName
        };
        this.$get(`${ROUTE_LIST_PAGE}/${this.currentScene}`, data).then(res => {
          this.total = res.total;
          let tableList = [];
          res.records.forEach((arr, index) => {
            arr.index = index + 1;
            arr.createTime = dateConversion(arr.createTime);
            tableList.push({...arr})
          });
          this.tableList = res.records;
        }).catch(err => {
          //异常情况
          this.$httpErr(err, ROUTE_LIST_PAGE)
        })
      },
      //查询设备列表
      searchRoute() {
        this.pageNo = 1;
        this.requestWayList()
      },
      //创建路线
      createWay() {
        this.$router.push(`way-edit/0`)
      },
      //删除某条数据
      delItem(data) {
        this.$Modal.confirm({
          title: '提示',
          content: '<p>确定删除此条数据</p>',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            this.delRoute(data.id)
          }
        });
      },
      //删除路线
      delRoute(id) {
        let url = `${ROUTE_LINE_EDIT}/${this.currentScene}/${id}`;
        this.$delete(url).then(res => {
          this.$Message.success({
            background: true,
            content: '删除巡检路线成功'
          });
          this.requestWayList()
        }).catch(err => {
          //异常情况
          this.$httpErr(err, ROUTE_LINE_EDIT)
        })
      },
      //查看路线
      showWay(row) {
        this.$router.push({path:`way-show`, query: {id: row.id, name: row.name}})
      },
      //编辑路线
      editWay(row) {
        this.$router.push({path:`way-edit/${row.id}`, query: {name: row.name}})
      },
      //复制路线
      copyWay(row) {
        this.copyRouteId = row.id;
        this.modalStatus = true;
      },
      //提交表单
      handleSubmit (name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            //验证成功
            this.modalStatus = false;
            this.copyRoute()
          }
        })
      },
      //辅助路线请求
      copyRoute() {
        let url = `${ROUTE_LINE_COPY}/${this.currentScene}/${this.copyRouteId}`;
        this.$post(url, conversionFrom(this.formValidate)).then(res => {
          this.$Message.success({
            background: true,
            content: '辅助巡检路线成功'
          });
          this.pageNo = 1;
          this.requestWayList()
        }).catch(err => {
          //异常情况
          this.$httpErr(err, ROUTE_LINE_COPY)
        })
      },
      //关闭弹框
      handleReset() {
        this.modalStatus = false;
      },
      //切换页码
      changePage(val) {
        this.pageNo = val;
        this.requestWayList()
      },
      //切换每页条数
      changeSize(val) {
        this.pageNo = 1;
        this.pageSize = val;
        this.requestWayList()
      }
    },
    computed: {
      ...mapState({
        currentScene: state => state.common.currentScene
      }),
    },
    watch: {
      'modalStatus': function(val) {
        if (!val) {
          this.formValidate.name = '';
          this.$refs.formValidate.resetFields();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
