const baseUrl = 'https://xj.huixuniot.com/api'
export {
	baseUrl
}

/**
 * 登录权限相关api
 * **/
//登录(post)
const USER_LOGIN_URL = "/login";
//本用户拥有场景查询(get)
const USER_OWN_BUILDINGS = "/own/buildings";
//本用户在指定场景的显示菜单查询(get)
const USER_OWN_MENU = "/pf/menus/show/nopage";
//本用户的用户信息查询(get)
const USER_OWN_INFO = "/own/user-info";

export {
	USER_LOGIN_URL,
	USER_OWN_BUILDINGS,
	USER_OWN_MENU,
	USER_OWN_INFO
}

/**
 * 巡检系统首页接口
 * **/
//巡检情况历史统计查询(get)
const HISTORY_INSPECT_STATISTICS = "/ipt/subtasks/amount";
//异常数量历史统计查询(get)
const ABNORMAL_DEVICE_STATISTICS = "/ipt/device-exceptions/amount";
//巡检子任务历史统计查询(get)
const INSPECT_TASK_STATISTICS = "/ipt/subtasks";
// 报修状态统计
const STATISTICS_STATUS = "/plat/repair/statistics/status";
// 报修设备统计
const STATISTICS_DEVICE = "/plat/repair/statistics/device"

export {
	HISTORY_INSPECT_STATISTICS,
	ABNORMAL_DEVICE_STATISTICS,
	INSPECT_TASK_STATISTICS,
	STATISTICS_STATUS,
	STATISTICS_DEVICE
}

/**
 * 设备管理
 * **/
//设备查询(分页)(get)
const DEVICE_LIST_PAGE = "/ipt/devices";
//设备查询(不分页)(get)
const DEVICE_LIST_NOPAGE = "/ipt/devices/nopage";
//设备新增(post)/修改(put)/删除(delete)
const DEVICE_EDIT = "/ipt/devices";
//批量导入设备(post)
const DEVICE_BATCH_IMPORT = "/ipt/devices/batch-import";
//巡检记录查询(get)
const DEVICE_INSPECT_RECORD = "/ipt/subreport/device";
//设备是否关联巡检点
const DEVICE_RELATION_POINT = "/ipt/devices/has-related-point"
// 设备报修详情
const DEVICE_REPAIR_DETAIL = "/plat/repair/device"

export {
	DEVICE_LIST_PAGE,
	DEVICE_LIST_NOPAGE,
	DEVICE_EDIT,
	DEVICE_BATCH_IMPORT,
	DEVICE_INSPECT_RECORD,
	DEVICE_RELATION_POINT,
	DEVICE_REPAIR_DETAIL
}

/**
 * 设备类别管理
 * **/
//设备类别查询(分页)(get)
const DEVICE_TYPE_PAGE = "/ipt/device-types";
//设备类别查询(不分页)(get)
const DEVICE_TYPE_NOPAGE = "/ipt/device-types/nopage";
//设备类别新增(post)/修改(put)/删除(delete)
const DEVICE_TYPE_EDIT = "/ipt/device-types";
//查询设备类型的平台默认icon(GET)
const DEVICE_TYPE_ICON = "/ipt/device-types/default/icons";

export {
	DEVICE_TYPE_PAGE,
	DEVICE_TYPE_NOPAGE,
	DEVICE_TYPE_EDIT,
	DEVICE_TYPE_ICON,
}

/**
 * 巡检点管理
 * **/
//巡检点列表查询(分页)(get)
const LOCALE_LIST_PAGE = "/ipt/points";
//巡检点列表查询(不分页)(get)
const LOCALE_LIST_NOPAGE = "/ipt/points/nopage";
//巡检点新增(post)/修改(put)/删除(delete)
const LOCALE_POINT_EDIT = "/ipt/points";
//巡检点已关联设备查询(get)
const LOCALE_RELATION_DEVICE = "/ipt/points/point-devices";
//巡检点已关联设备修改或新增(post)
const LOCALE_RELATION_DEVICE_EDIT = "/ipt/points/point-devices";
//巡检点是否关联巡检路线
const LOCALE_RELATION_ROUTES = "/ipt/points/has-related-route";

export {
	LOCALE_LIST_PAGE,
	LOCALE_LIST_NOPAGE,
	LOCALE_POINT_EDIT,
	LOCALE_RELATION_DEVICE,
	LOCALE_RELATION_DEVICE_EDIT,
	LOCALE_RELATION_ROUTES
}

/**
 * 巡检路线管理
 * **/
//巡检路线列表查询(分页)(get)
const ROUTE_LIST_PAGE = "/ipt/routes";
//巡检路线列表查询(分页)(get)
const ROUTE_LIST_NOPAGE = "/ipt/routes/nopage";
//巡检路线新增(post)/修改(put)/删除(delete)
const ROUTE_LINE_EDIT = "/ipt/routes";
//巡检路线新增(复制路线)(post)
const ROUTE_LINE_COPY = "/ipt/routes/copy";
//巡检路线已关联巡检点查询(不分页)(get)
const ROUTE_RELATION_POINT = "/ipt/routes/route-points";

export {
	ROUTE_LIST_PAGE,
	ROUTE_LIST_NOPAGE,
	ROUTE_LINE_EDIT,
	ROUTE_LINE_COPY,
	ROUTE_RELATION_POINT,
}

/**
 * 巡检任务、子任务管理
 * **/
//巡检任务列表查询(分页)(get)
const TASK_LIST_PAGE = "/ipt/tasks";
//巡检任务查询(get)新增(post)/修改(put)/删除(delete)
const TASK_ITEM_EDIT = "/ipt/tasks";
//巡检子任务列表查询(不分页)(get)
const TASK_CHILD_LIST_NOPAGE = "/ipt/subtasks/nopage";

export {
	TASK_LIST_PAGE,
	TASK_ITEM_EDIT,
	TASK_CHILD_LIST_NOPAGE,
}

/**
 * 巡检报告管理(对应巡检子任务)
 * **/
//巡检报告查询列表(分页)(get)
const REPORT_LIST_PAGE = "/ipt/subreport";
//巡检报告查询详情(get)
const REPORT_ITEM_DETAIL = "/ipt/subreport";
//巡检报告文件导出(get)
const REPORT_ITEM_EXPORT = "/ipt/subreport/export";
//巡检报告中的巡检设备异常列表查询(分页)(get)
const REPORT_ABNORMAL_DEVICE = "/ipt/subreport/device-exceptions";

export {
	REPORT_LIST_PAGE,
	REPORT_ITEM_DETAIL,
	REPORT_ITEM_EXPORT,
	REPORT_ABNORMAL_DEVICE,
}


/**
 * 消息通知
 */
// 消息列表
const MSG_LIST = "/plat/msg"
// 发布消息
const RELEASE_MSG = "/plat/msg"
// 发布消息人员列表
const RELEASE_MSG_USER = "/base/users/nopage"

export {
	MSG_LIST,
	RELEASE_MSG,
	RELEASE_MSG_USER
}

/**
 * 知识库
 */
// 知识库列表 查询列表 get 单条带id 新增post 修改put
const KNOWLEDGE_LIST = "/knowledge"
// 知识库上传doc文件
const KNOWLEDGE_UPLOAD = "/knowledge/upload"

export {
	KNOWLEDGE_LIST,
	KNOWLEDGE_UPLOAD
}


/**
 * 报修管理
 */
// 报修管理列表
const REPAIR_LIST_PAGE = "/plat/repair/dispatch"
// 报修管理列表——待派单
const REPAIR_LIST_UNRECEIVE = "/plat/repair/dispatch/undispatch"
// 报修管理列表——进行中
const REPAIR_LIST_RECEIVED = "/plat/repair/dispatch/doing"
// // 报修管理列表——待确认
const REPAIR_LIST_UNCONFIRM = "/plat/repair/dispatch/unconfirm"
// // 报修管理列表——已完成
const REPAIR_LIST_DONE = "/plat/repair/dispatch/done"
// 报修详情
const REPAIR_DETAIL = "/repair"
// 处理报修
const REPAIR_DISPATCH = "/repair/dispatch"
// 获取人员列表
const REPAIR_USER = "/base/users/maintain/nopage"
// 导出报修列表
const EXPORT_REPAIR_LIST = "/plat/repair/export"

export {
	REPAIR_LIST_PAGE,
	REPAIR_DETAIL,
	REPAIR_LIST_UNRECEIVE,
	REPAIR_LIST_RECEIVED,
	REPAIR_LIST_UNCONFIRM,
	REPAIR_LIST_DONE,
	REPAIR_DISPATCH,
	REPAIR_USER,
	EXPORT_REPAIR_LIST
}




/**
 * 异常管理
 * **/
//异常设备列表(get)
const ABNORMAL_DEVICE_DETAIL = "/ipt/device-exceptions";
//异常设备处理(put)
const ABNORMAL_DEVICE_HANDLE = "/ipt/device-exceptions/handle";

export {
	ABNORMAL_DEVICE_DETAIL,
	ABNORMAL_DEVICE_HANDLE
}

/**
 * 人员管理
 * **/
//人员查询列表(分页)(get)
const MEMBER_LIST_PAGE = "/base/users";
//人员查询列表(不分页)(get)
const MEMBER_LIST_NOPAGE = "/base/users/nopage";
//人员新增新增(post)/修改(put)/删除(delete)
const MEMBER_ITEM_EDIT = "/base/users";
//人员分配场景保存/修改(post)
const MEMBER_SCENE_EDIT = "/base/users/user-buildings";
//账号(手机号)是否已存在查询(get)
const MEMBER_EXISTENCE_CHECK = "/base/username-check";
//人员是否是某个人物的执行人
const MEMBER_RELATION_TASK = "/base/users/has-related-task";

export {
	MEMBER_LIST_PAGE,
	MEMBER_LIST_NOPAGE,
	MEMBER_ITEM_EDIT,
	MEMBER_SCENE_EDIT,
	MEMBER_EXISTENCE_CHECK,
	MEMBER_RELATION_TASK
}


/**
 * 系统设置
 * **/
//系统设置查询(get)/保存(post)
const SYSTEM_SETTING = "/base/sys-setting";
// 查询已关注公众号成员
const BIND_USERS = "/wxmanage/bind/users";
// 解绑已关注公众号成员
const UNBIND_USERS = '/wxmanage/unbind'

export {
	SYSTEM_SETTING,
	BIND_USERS,
	UNBIND_USERS
}


/**
 * 角色管理
 * **/
//角色查询列表(分页)(get)
const ROLE_LIST_PAGE = "/pf/roles";
//角色查询列表(不分页)(get)
const ROLE_LIST_NOPAGE = "/pf/roles/nopage";
//角色新增(post)/修改(put)/删除(delete)
const ROLE_ITEM_EDIT = "/pf/roles";

export {
	ROLE_LIST_PAGE,
	ROLE_LIST_NOPAGE,
	ROLE_ITEM_EDIT,
}

/**
 * 菜单管理
 * **/
//菜单列表查询(控制前端显示)(不分页)(get)
const MENU_LIST_NOPAGE = "/pf/menus/show/nopage";
//菜单列表查询(管理页面)(不分页)(get)
const MENU_MANAGER_LIST_NOPAGE = "/pf/menus/nopage";
//菜单新增(post)/修改(put)/删除(delete)
const MENU_ITEM_EDIT = "/pf/menus";
//菜单是否关联角色
const MENU_RELATION_ROLE = "/pf/menus/has-related-role";

export {
	MENU_LIST_NOPAGE,
	MENU_MANAGER_LIST_NOPAGE,
	MENU_ITEM_EDIT,
	MENU_RELATION_ROLE
}

/**
 * 人员查看权限规则管理
 * **/
//人员查看权限查询规则列表(不分页)(get)
const RIGHT_RULE_LIST = "/pf/user-show-rules/nopage";
//人员查看权限规则新增(post)/修改(put)/删除(delete)
const RIGHT_RULE_EDIT = "/pf/user-show-rules";
//人员是否已经添加过规则(get)
const MEMBER_RULE_CHECK = "/pf/user-show-rules/user-check";

export {
	RIGHT_RULE_LIST,
	RIGHT_RULE_EDIT,
	MEMBER_RULE_CHECK,
}

/**
 * 接口数据管理
 * **/
//接口列表查询(get)
const API_LIST_NOPAGE = "/pf/apis/nopage";
//场景基础信息查询(get)
const SCENE_CONFIG_INFO = "/common/building-info";
//数据同步(post)
const DATA_SYNC = "/pf/ds/data-sync";

export {
	API_LIST_NOPAGE,
	SCENE_CONFIG_INFO,
	DATA_SYNC,
}


/**
 * 场景有效期管理
 */
// 查询get/put修改场景有效期
const API_EXPIRES = "/building/expires"
// 续期
const PUT_EXPIRE = "/building/expire"

export {
	API_EXPIRES,
	PUT_EXPIRE
}
