<template>
    <div class="page-view">
        <div class="page-header">
            <span class="header-text">巡检点管理</span>
            <div class="btn-group">
                <Button type="primary" @click="deployLocale">部署巡检点</Button>
            </div>
        </div>
        <div class="inspect-locale-view">
            <div class="view-title">
                <div class="title-left">
                    <div class="left-item">
                        <span class="left-title">巡检点名称/编号</span>
                        <Input
                            v-model="localName"
                            placeholder="请输入"
                            style="width: 300px"
                        />
                    </div>
                    <div class="left-item">
                        <span class="left-title">楼层</span>
                        <Select
                            v-model="floorId"
                            clearable
                            style="width: 300px"
                        >
                            <Option
                                v-for="item in mapFloor"
                                :value="item.value"
                                :key="item.value"
                                >{{ item.label }}</Option
                            >
                        </Select>
                    </div>
                    <Button type="primary" ghost @click="searchLocal"
                        >查询</Button
                    >
                </div>
            </div>
            <Table
                stripe
                ref="selection"
                :columns="tableTitle"
                :data="tableList"
            >
                <template slot-scope="{ row, index }" slot="relation">
                    <span class="icon-text" @click="relateDevice(row)"
                        >关联设备详情</span
                    >
					<span>（{{row.deviceNum}}）</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <span
                        class="iconfont iconxiugai"
                        @click="editLocale(row)"
                    ></span>
                    <span
                        class="iconfont iconsahnchufuben"
                        @click="delItem(row)"
                    ></span>
                </template>
            </Table>
            <div class="paging-box">
                <Page
                    :current="pageNo"
                    :page-size="pageSize"
                    :total="total"
                    @on-change="changePage"
                    @on-page-size-change="changeSize"
                    show-total
                    show-elevator
                    show-sizer
                />
            </div>
        </div>
        <local-edit-modal
            :localId="localId"
            :localData="localData"
            type="edit"
            @localEvent="localEvent"
            ref="local"
        ></local-edit-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    LOCALE_LIST_PAGE,
    LOCALE_POINT_EDIT,
    LOCALE_RELATION_ROUTES,
} from "../../../config/api";
import LocalEditModal from "../../../components/LocalEditModal";
export default {
    data() {
        return {
            //设备名称
            localName: "",
            //巡检点id
            floorId: "",
            //列表数据
            tableTitle: [
                {
                    title: "序号",
                    key: "index",
                    width: 70,
                },
                {
                    title: "巡检点名称",
                    key: "name",
                },
                {
                    title: "巡检点编号",
                    key: "number",
                },
                {
                    title: "楼层",
                    key: "floorName",
                    width: 100,
                },
                {
                    title: "坐标",
                    key: "coordinate",
                    width: 200,
                },
                {
                    title: "位置",
                    key: "location",
                },
                {
                    title: "关联设备",
                    key: "relationDevice",
                    slot: "relation",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 100,
                    align: "center",
                },
            ],
            tableList: [],
            //分页数据
            pageNo: 1,
            pageSize: 10,
            total: 0,
            //巡检点Id
            localId: null,
            //巡检点信息
            localData: {},
        };
    },
    components: {
        LocalEditModal,
    },
    created() {
        this.requestLocalList();
    },
    methods: {
        //请求路线列表
        requestLocalList() {
            let data = {
                current: this.pageNo,
                size: this.pageSize,
                search: this.localName,
                "floor-id": this.floorId,
            };
            this.$get(`${LOCALE_LIST_PAGE}/${this.currentScene}`, data)
                .then((res) => {
                    this.total = res.total;
                    let tableList = [];
                    res.records.forEach((arr, index) => {
                        arr.index = index + 1;
                        arr.coordinate = "";
                        if (arr.floorId !== null) {
                            arr.coordinate = `(${arr.x.toFixed(
                                1
                            )},${arr.y.toFixed(1)})`;
                        }
                        tableList.push(arr);
                    });
                    this.tableList = tableList;
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, LOCALE_LIST_PAGE);
                });
        },
        //查询巡检点
        searchLocal() {
            this.pageNo = 1;
            this.requestLocalList();
        },
        //部署巡检点
        deployLocale() {
            this.$router.push("locale-deploy");
        },
        //删除某条数据
        delItem(data) {
            this.$Modal.confirm({
                title: "提示",
                content: "<p>确定删除此条数据</p>",
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                    this.delConfirm(data.id);
                },
            });
        },
        //删除前确认是否关联巡检路线
        delConfirm(id) {
            this.$get(`${LOCALE_RELATION_ROUTES}/${this.currentScene}/${id}`)
                .then((res) => {
                    if (!res) {
                        this.delLocal(id);
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "巡检点已关联巡检路线，无法删除",
                        });
                    }
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, LOCALE_RELATION_ROUTES);
                });
        },
        //删除巡检点请求
        delLocal(id) {
            this.$delete(`${LOCALE_POINT_EDIT}/${this.currentScene}/${id}`)
                .then((res) => {
                    this.$Message.success({
                        background: true,
                        content: "删除巡检点成功",
                    });
                    this.requestLocalList();
                })
                .catch((err) => {
                    //异常情况
                    this.$httpErr(err, LOCALE_POINT_EDIT);
                });
        },
        //编辑巡检点
        editLocale(data) {
            this.localId = data.id;
            this.localData = data;
            this.$refs.local.modalStatus = true;
        },
        //部署巡检点事件
        localEvent(content) {
            if (content.type === "update") {
                this.$Message.success({
                    background: true,
                    content: "编辑巡检点成功",
                });
                this.requestLocalList();
            }
        },
        //关联设备详情
        relateDevice(data) {
            this.$router.push({
                path: `locale-relatedevice`,
                query: { id: data.id, name: data.name },
            });
        },
        //切换页码
        changePage(val) {
            this.pageNo = val;
            this.requestLocalList();
        },
        //切换每页条数
        changeSize(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.requestLocalList();
        },
    },
    computed: {
        ...mapGetters(["currentScene", "mapFloor"]),
    },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
