
/**
 * 公用信息状态信息管理
 */
const state = {
  //登录状态
  login: false,
  //页面loading
  showLoading: false,
  //用户场景列表
  scene: [],
  //当前场景
  currentScene: '',
  //系统icon
  systemIcon: '',
  //系统名称
  systemName: '',
  //侧边栏列表
  menuList: [],
};

const getters = {

};


const mutations = {
  //更新登录状态
  UPDATE_LOGIN(state, status) {
    state.login = status;
  },
  //更新loading状态
  UPDATE_LOADING(state, status) {
    state.showLoading = status;
  },
  //更新用户场景列表
  UPDATE_SCENE (state, list) {
    state.scene = list;
  },
  //更新用户场景列表
  UPDATE_CURRENTSCENE (state, scene) {
    state.currentScene = scene;
  },
  //更新系统icon
  UPDATE_SYSTEM_ICON(state, icon) {
    state.systemIcon = icon;
  },
  //更新系统名称
  UPDATE_SYSTEM_NAME(state, name) {
    state.systemName = name;
  },
  UPDATE_MENU_LIST(state, list) {
    state.menuList = list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
