<template>
  <div class="manager-page">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    created() {
      //TODO 同设备管理模块index.vue种处理方式
    }
  }
</script>

<style lang="scss">
  .manager-page{
    width: 100%;
    height: 100%;
    padding: 12px;
    box-sizing: border-box;
  }
</style>
