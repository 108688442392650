import Vue from 'vue'
import Vuex from 'vuex'
import common from './common';
import map from './map';
import user from './user';
import getters from './getters';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common,
    map,
    user
  },
  getters
})
