
/**
 * 蜂鸟地图信息状态信息管理
 */
const state = {
  //蜂鸟地图id
  fmapID: '',
  //用于蜂鸟SDK的appName
  appName: '',
  //用于蜂鸟SDK的key
  key: '',
  //蜂鸟地图服务资源
  mapServerUrl: '',
  //蜂鸟地图主题
  mapThemeStyle: '',
  //蜂鸟地图主题URL
  mapThemeUrl: '',
  //地图楼层
  mapFloor: []
};

const getters = {

};


const mutations = {
  //更新蜂鸟地图ID
  UPDATE_FMAPID(state, id) {
    state.fmapID = id;
  },
  //更新蜂鸟地图name
  UPDATE_APPNAME(state, name) {
    state.appName = name;
  },
  //更新蜂鸟地图key
  UPDATE_KEY(state, key) {
    state.key = key;
  },
  //更新蜂鸟地图主题样式
  UPDATE_THEME_STYLE(state, style) {
    state.mapThemeStyle = style;
  },
  //更新蜂鸟地图主题样式URL
  UPDATE_THEME_URL(state, url) {
    state.mapThemeUrl = (process.env.NODE_ENV === 'development') ? url :  `${url}`;
  },
  //更新蜂鸟地图数据URL
  UPDATE_SERVER_URL(state, url) {
    state.mapServerUrl = (process.env.NODE_ENV === 'development') ? url :  `${url}`;
  },
  //更新蜂鸟地图楼层
  UPDATE_MAP_FLOOR(state, list) {
    state.mapFloor = list;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
