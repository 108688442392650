<template>
  <Modal
      v-model="modalStatus"
      :title="title">
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100">
      <FormItem label="楼层" prop="floorId">
        <Select v-model="formValidate.floorId" placeholder="请选择" disabled>
          <Option :value="item.value" v-for="item in mapFloor"
                  :key="item.value">{{ item.label }}</Option>
        </Select>
      </FormItem>
      <FormItem label="巡检点坐标">
        <Row>
          <Col span="11">
            <FormItem>
              <Input v-model="formValidate.x" placeholder="请输入" disabled />
            </FormItem>
          </Col>
          <Col span="2" style="text-align: center; color: #999999;">-</Col>
          <Col span="11">
            <FormItem>
              <Input v-model="formValidate.y" placeholder="请输入" disabled />
            </FormItem>
          </Col>
        </Row>
      </FormItem>
      <FormItem label="巡检点名称" prop="name">
        <Input v-model="formValidate.name" placeholder="请输入" />
      </FormItem>
      <FormItem label="巡检点编号" prop="number">
        <Input v-model="formValidate.number" placeholder="请输入"  />
      </FormItem>
      <FormItem label="巡检点位置" prop="location">
        <Input v-model="formValidate.location" type="textarea"
               :autosize="{minRows: 2,maxRows: 5}" placeholder="请输入" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button type="text" @click="handleReset()">取消</Button>
      <Button type="primary" :loading="requestStatus" @click="handleSubmit('formValidate')">确认</Button>
    </div>
  </Modal>
</template>

<script>
  import {mapGetters} from 'vuex'
  import { LOCALE_POINT_EDIT } from '../../config/api'
  import {conversionFrom} from '../../utils/helper'
  export default {
    data() {
      return {
        //标题
        title: '',
        //弹框状态
        modalStatus: false,
        //表单数据
        formValidate: {
          floorId: '',
          x: '',
          y: '',
          name: '',
          number: '',
          location: '',
        },
        //表单验证规则
        ruleValidate: {
          floorId: [
            { required: true, type:  'number', message: '请选择楼层', trigger: 'change' }
          ],
          x: [
            { required: true, message: '请输入X坐标', trigger: 'blur' },
          ],
          y: [
            { required: true, message: '请输入Y坐标', trigger: 'blur' },
          ],
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' }
          ],
          number: [
            { required: true, message: '请输入编号', trigger: 'blur' }
          ],
          location: [
            { required: true, message: '请输入位置', trigger: 'blur' }
          ],
        },
        //请求状态
        requestStatus: false,
        //展示状态
        deployStatus: true
      }
    },
    props: ['localId', 'localData', 'type'],
    methods: {
      //初始化表单数据
      initForm() {
        if (this.localId === null) {
          // 新增设备时, 切换楼层number => id
          this.mapFloor.forEach(arr => {
            if (arr.number === this.localData.groupID) {
              this.formValidate.floorId = arr.value;
            }
          });
        } else {
          this.formValidate.floorId = this.localData.floorId;
        }
        for (let val in this.formValidate) {
          if (this.localData[val] !== undefined) {
            this.formValidate[val] = this.localData[val];
          }
        }
      },
      //重置表单数据
      resetFormData(name) {
        this.$refs[name].resetFields();
        for (let val in this.formValidate) {
          this.formValidate[val] = '';
        }
      },
      //表单提交
      handleSubmit (name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            //验证成功
            this.submitLocal()
          }
        })
      },
      //取消提交
      handleReset() {
        this.modalStatus = false;
      },
      //提交部署设备信息
      submitLocal() {
        this.requestStatus = true;
        let data = {...this.formValidate};
        let httpType = this.localId !== null ? '$put' : '$post';
        let url = this.localId !== null ? `${LOCALE_POINT_EDIT}/${this.currentScene}/${this.localId}` :
          `${LOCALE_POINT_EDIT}/${this.currentScene}`;
        this[httpType](url, conversionFrom(data)).then(res => {
          this.requestStatus = false;
          this.modalStatus = false;
          let type = this.localId !== null ? 'update' : 'new';
          let content = {
            type: type,
            data: data
          };
          this.$emit('localEvent', content)
        }).catch(err => {
          this.requestStatus = false;
          //异常情况
          this.$httpErr(err, LOCALE_POINT_EDIT)
        })
      },
    },
    computed: {
      ...mapGetters([
        'currentScene',
        'mapFloor',
      ]),
    },
    watch: {
      modalStatus: function(val) {
        if (val) {
          this.title = (this.localId !== null) ? '编辑巡检点': '新增巡检点';
          this.deployStatus = (this.localId !== null) && (this.type === 'deploy');
          this.initForm()
        } else {
          this.resetFormData('formValidate')
          this.$emit('localEvent', {type: 'close'})
        }
      }
    }
  }
</script>

